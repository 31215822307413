@import "classes";

.patient-list-modal {

  .sidebar-header {
    .btn-link {
      line-height: 16px;
    }
  }

  .form-horizontal {
    padding: 0 15px;

    .col-sm-padding;

    .control-label {
      font-weight: normal;
      color: #888;
    }

    .form-group {
      margin-bottom: 5px;
    }
  }
}

.patient-list {
  display: flex;
  flex-direction: column;
  background-size: 100% auto;
  height: 100%;

}

.patient-list-title {
  border-bottom: 1px solid #bbb;
  padding: 10px 15px;
  background: white;
  text-align: center;

  .logo {
    height: 34px;
    position: absolute;
    left: 50%;
    margin-left: -54px;
  }
}

.patient-list-body {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
  overflow-y: scroll;
  color: #888;
  font-size: 16px;
  .hidden-scrollbar();

  .patient-list-grid-item {
    flex-basis: 19%;
    height: 220px;
    margin-top: 1%;
    background: rgba(255, 255, 255, .8);
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
    line-height: 25px;
    overflow: hidden;
    cursor: pointer;

    .member-image {
      width: 100px;
      height: 100px;
      margin: 15px auto;

    }
  }
  .patient-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1%;
    background: rgba(255, 255, 255, .8);
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 10px;
    line-height: 25px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s linear;

    &:hover {
      box-shadow: 0 0 5px 5px rgba(0,0,0,0.25);
    }

    .name {
      flex: 3;
    }
    .age {
      flex: 1;
    }
    .number {
      flex: 1;
    }

    .member-image {
      width: 50px !important;
      height: 50px !important;
      margin-right: 200px;

    }
  }
}

.patient-list-back {
  line-height: 34px;
}

.patient-list-open-search {
  line-height: 34px;
  cursor: pointer;
  margin-left: 10px;
}
