@import "classes";
@import "variables";

.clinical-note-item-view-note {
  pre {
    margin: 0;
    padding: 0;
    border: none;
    background-color: unset;
    white-space: unset;
  }
}
.clinical-note-item {
  //margin-left: -150%;
  background: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  //width: 100vw !important;
  //transform: translateX(-100vw);
  .template {
    p {
      margin: 0;
    }
    pre {
      margin: 0;
      padding: 0;
      border: none;
      background-color: unset;
      white-space: unset;
    }
  }

  &.clinical-note-item-slime {
    margin-left: 0;

    .clinical-note-item-edit-body {
      .input-lg {
        height: 44px;
      }

      .clinical-note-create-date {
        position: static;
      }

      .dok32-ckeditor {
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;

        .cke {
          flex: 1 1 0%;
          display: flex;
          flex-direction: column;

          .cke_inner {
            flex: 1 1 0%;
            display: flex;
            flex-direction: column;

            .cke_contents {
              flex: 1 1 0%;
              display: flex;
              flex-direction: column;
              max-height: 100% !important;

              iframe {
                flex: 1 1 0%;
              }
            }
          }
        }
      }
    }

  }

  .clinical-note-item-view-title {
    text-align: center;
    padding: 10px 15px;
    border-bottom: 1px solid #bbb;

    .btn {
      background-position-x: 0;
    }
    .name {
      height: 30px;
      line-height: 30px;
      font-size: 18px;

      .form-control {
        width: 200px;
        display: inline-block;
      }
    }
    .chart-number {
      float: right;
      line-height: 30px;
      font-weight: bold;
    }

    .logo {
      height: 30px;
    }
  }

  .clinical-note-item-view-body {
    flex: 1;
    display: flex;
    padding: 10px 15px;
    flex-direction: column;

    .clinical-note-item-view-details {
      flex-shrink: 0;
      line-height: 25px;
      margin-bottom: 10px;
    }

    .clinical-note-item-view-note {
      box-shadow: 5px 5px 10px 0 rgba(184, 184, 184, 0.7);
      background: rgb(255, 254, 227);
      padding: 15px;
      flex: 1;
      overflow-y: scroll;
      .hidden-scrollbar()
    }

    .clinical-note-item-view-note > div * {
      overflow: auto;
      word-break: break-word;
    }

    embed {
      height: 100%;
    }
  }

  .clinical-note-item-view-footer {
    padding: 10px 0;
  }

  .clinical-note-item-edit-body {
    display: flex;
    align-items: stretch;
    flex: 1;
    position: relative;

    .template, .questions {
      margin: 15px;
      border: 1px solid #bbb;
      padding: 10px;
      flex: 1;
    }
    .template {
      margin-bottom: 81px;
      .hidden-scrollbar();
      overflow-y: scroll;

      .template-item {
        display: block;
        cursor: pointer;
      }
    }
    .clinical-note-item-datepicker {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 400px;
      div:first-child {
        display: flex;
        width: 100%;
      }
    }
    .questions {
      display: flex;
      flex-direction: column;
      .name {
        line-height: 30px;
      }
      .question {
        line-height: 30px;
        height: 60px;
      }
      .body {
        flex: 1;
        flex-direction: column;
        margin-bottom: 10px;
        display: flex;
        position: relative;
        overflow-y: scroll;
        .hidden-scrollbar();

        > * {
          flex: 1;
        }

        > .flex-none {
          flex: none;
        }

        .date-picker {
          text-align: center;
        }

        .chart-clinical-note-teeth-carousel {
          display: flex;
          position: absolute;
          width: 480px;
          left: 50%;
          margin-left: -215px;
          align-items: center;
          top: 10px;

          .chart-clinical-note-teeth-carousel-prev, .chart-clinical-note-teeth-carousel-next {
            cursor: pointer;
          }
          .chart-clinical-note-teeth-carousel-screen {

            overflow: hidden;
            .chart-clinical-note-teeth-carousel-wrapper {
              display: flex;
              transition: 0.5s all;

              .chart-clinical-note-tooth {
                height: 170px;
                display: flex;
                flex-direction: column;
                padding: 3px;
                align-items: center;
                margin: 5px;
                border: 2px dashed transparent;
                cursor: pointer;

                &.active {
                  border-color: black;
                  background: #ccc;
                }

                .img {
                  flex: 1;
                  width: 60px;
                  text-align: center;

                  img {
                    zoom: 50%;
                  }
                }
              }
            }
          }
        }

      }
      .btns {
        .btn-wrap {
          float: left;
        }
        .btn-wrap + .btn-wrap {
          padding-left: 10px;
        }
      }
    }

    .clinical-note-create-date {
      position: absolute;
      bottom: 20px;
      left: 25px;
      color: @brand-primary;

      .form-control {
        color: @brand-primary;
        box-shadow: none;
        border: 0;
        font-weight: bold;
      }
    }
  }
}

.clinical-note-textarea {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: stretch;
  flex: 1;

  .clinical-note-textarea-name {
    input {
      text-align: center;
      border-radius: 0;
      border-width: 0;
      border-bottom-width: 1px;
      box-shadow: none;
      color: #000;
    }
  }

  .clinical-note-textarea-body {
    flex: 1;
    display: flex;
    flex-direction: column;

    label {
      margin: 10px 0;
    }

    textarea {
      flex: 1;
      color: #000;
    }
  }

  .clinical-note-textarea-footer {
    padding: 20px 0 10px;
  }
}

.chart-side-bar .chart-clinical-note .time-picker-main-form {
  top: -140px;
}

.full-screen {
  .dok32-ckeditor {
    .cke_contents {
      max-height: 380px!important;
    }
  }
}

.bottom-bar {
   height: 330px;

  .dok32-ckeditor {
    .cke_contents {
      max-height: 80px!important;
    }
  }
}
