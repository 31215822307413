@import "classes";

.chart-side-prescription-list-item {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 5px;
  flex-shrink: 0;

  &.estimate {
    position: relative;

    & > .delete {
      position: absolute;
      top: 5px;
      right: 15px;
      width: 17px;
      height: 17px;
      background-position: 50%;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }

  .item-title {
    font-size: 16px;
  }
}

.chart-prescription-edit-title {
  button {
    font-size: 15px;
    font-weight: bold;
    line-height: 16px;
  }
}

.chart-prescription-edit {
  overflow-y: scroll;
  .hidden-scrollbar();

  form {
    hr {
      border-color: #d9d9d9;
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  .rc-collapse > .rc-collapse-item {
    & > .rc-collapse-content {
      overflow: visible;

      & > .rc-collapse-content-box {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    & > .rc-collapse-header {
      padding-left: 30px;
      text-indent: 0;
      min-height: 40px;
      .arrow {
        margin-right: 0;
        margin-left: -15px;
        float: left;
      }

      button {
        margin: 2px;
      }
    }
  }
}

.diagnosis-table {
  display: flex;
  flex-direction: column;
  .diagnosis-table-header {
    display: flex;
    flex: 0 0 0%;
    min-height: 40px;
    margin-right: 5px;
    border-top: 1px solid #bbb;
    border-left: 1px solid #bbb;
  }
  .diagnosis-table-body {
    border-left: 1px solid #bbb;
    display: flex;
    flex: 1;
    overflow-y: scroll;
    flex-direction: column;
    .hidden-scrollbar();
  }

  .diagnosis-table-row {
    display: flex;
    flex: 1 0 0%;
    min-height: 40px;

    .diagnosis-table-col-2 {
      cursor: pointer;
    }
  }

  .diagnosis-table-col-1 {
    flex: 0 0 100px;
    padding: 0 10px;
    line-height: 40px;
    height: 40px;
    border-bottom: 1px solid #bbb;
    border-right: 1px solid #bbb;
    text-align: center;

    label {
      display: block;
      cursor: pointer;
    }
  }
  .diagnosis-table-col-2 {
    flex: 0 0 100px;
    padding: 0 10px;
    line-height: 40px;
    height: 40px;
    border-bottom: 1px solid #bbb;
    border-right: 1px solid #bbb;
    text-align: center;
  }
  .diagnosis-table-col-3 {
    flex: 0 0 110px;
    padding: 0 10px;
    line-height: 40px;
    height: 40px;
    border-bottom: 1px solid #bbb;
    border-right: 1px solid #bbb;
    text-align: center;
  }
  .diagnosis-table-col-4 {
    flex: 1;
    padding: 0 10px;
    line-height: 40px;
    height: 40px;
    border-bottom: 1px solid #bbb;
    border-right: 1px solid #bbb;
  }
}
