@import "variables";

@height-header: 50px;

.patient-insurance-form-sidebar {
  width: calc(100% - 330px) !important;
  box-shadow: unset !important;
  overflow: hidden;
}
.insurance-details-btn {
  &:focus {
    outline: none !important;
    border-color: #bec844 !important;
    background-color: #bec844 !important;
  }
}

.additional__date__container {
  display: inline-flex;
  position: relative;
  flex-direction: row-reverse;

  &::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 35px;
    transform: rotate(90deg);
    border-width: 10px;
    border-style: solid;
    border-color: transparent #ccc transparent transparent;
  }
  &::after {
    content: '';
    position: absolute;
    top: -16px;
    left: 37px;
    transform: rotate(90deg);
    border-width: 8px;
    border-style: solid;
    border-color: transparent #f0f0f0 transparent transparent;
  }

  &__days {
    position: relative;
    border-left: 1px solid #aeaeae;
    width: 50px;

    .day__title {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: #f0f0f0;
      border-bottom: 1px solid #aeaeae;
      border-top-right-radius: 2px;
    }

    .additional__day {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s linear;

      &:first-child {
        border-top: 1px solid #ccc;
      }

      &:hover {
        cursor: pointer;
        background-color: #f0f0f0;
      }
    }

    &::after {
      position: absolute;
      content: "";
      display: block;
      width: 100%;
      height: 27px;
      bottom: 0;
      border-top: 1px solid #aeaeae;
      background-color: #f0f0f0;
      border-bottom-right-radius: 2px;
    }
  }

  .additional__date__container_date_picker {
    .react-datepicker__navigation--next {
      right: 65px;
    }
  }
}
.consultation-notes-add-modal {
  display: flex !important;
  align-items: center;

  .field-consultation-note {
    margin-top: 6px;
    resize: none;
  }
}

.appointment-related-button-container {
  display: flex;

  .appointment-related-button {
    &.btn {
      flex: 1 1 0%;
      border-radius: 0;
    }

    &.left-button {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    &.right-button {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}

.appointment-related-modal-header {
  padding-bottom: 0;
}

.appointment-related-button-container {
  display: flex;

  .appointment-related-button {
    flex: 1 1 0%;
    height: 40px;
    display: flex;
    cursor: pointer;
    align-items: center;
    border: 1px solid #ccc;
    justify-content: center;
    outline: none;

    &:focus {
      outline: none;
    }

    &.left-button {
      border-right: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &.right-button {
      border-left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.appointment-planned-modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 6px;
  display: flex;
  z-index: 1500;
  position: fixed;

  .modal-footer {
    display: flex;
  }

  & .modal-dialog {
    width: 1000px;
  }

  & > .container {
    z-index: 10;
    margin: auto;
    outline: none;
    display: flex;
    max-height: 100%;
    position: relative;
    flex-direction: column;
    background-color: #fff;
    padding: 10px;

    & > .modal-container-header {
      display: flex;
      flex-shrink: 0;
      height: 70px;

      & > .modal-header-content {
        display: flex;
        flex: 1 1 0%;
        align-items: center;
      }

      & > .modal-header-close {
        display: flex;
        align-items: center;

        & > button {
          border: none;
          font-size: 18px;
          font-weight: bold;
          background-color: white;
        }
      }
    }

    & > .modal-container-content {
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;

      & > .table {
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;

        & > thead {
          display: flex;
          flex-shrink: 0;

          & > tr {
            display: flex;
            flex: 1 1 0%;
            height: 50px;

            & > th {
              display: flex;
              align-items: center;
              justify-content: center;

              &.description {
                flex: 1 1 0%;
              }

              &:not(:first-child) {
                width: 120px;
              }
            }
          }
        }

        & > tbody {
          flex: 1 1 0%;
          display: flex;
          overflow-y: auto;
          .hidden-scrollbar();
          flex-direction: column;

          & > tr {
            display: flex;
            flex: 1 0 0%;

            & > td {
              display: flex;
              align-items: center;
              justify-content: center;

              &.description {
                flex: 1 1 0%;
              }

              &:not(:first-child) {
                width: 120px;
              }
            }
          }
        }
      }
    }
  }
}

.appointment-planned-backdrop-modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
}

.new-patient-screen {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .new-screen-body {
    display: flex;
    flex: 1;

    & > .new-screen-left {
      flex: 0 0 400px;

      .popover-box {
        .custom-popover {
          left: 395px;
          top: 83px;
        }
      }

      .Select-value-label,
      .Select-placeholder {
        font-size: 15px;
        color: #555;
      }
      .Select.is-disabled > .Select-control {
        background: rgba(0, 0, 0, 0.04);
      }
      &.appointment-form {
        display: flex;
        width: 100%;
      }
      .row {
        margin-bottom: 5px;
        position: relative;

        select {
          padding-top: 11px;
          padding-bottom: 11px;
        }
        select,
        .form-control {
          font-size: 15px;
          &[disabled] {
            background: rgba(0, 0, 0, 0.04);
          }
        }
        .react-datepicker__input-container {
          display: block;
        }
        &.focus-row {
          .form-control,
          select {
            border: 2px solid #b9fa94;
            outline: none;
            .set-box-shadow(none);
          }
          select {
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }

        .has-error .form-control {
          border: 2px solid #a94442;
          outline: none;
          .set-box-shadow(none);
        }
      }
      .switch-item {
        .label {
          float: left;
          margin-top: 5px;
          margin-right: 5px;
        }
        .rc-switch {
          float: right;
        }
      }
      select.no-caret {
        /*for firefox*/
        -moz-appearance: none;
        /*for chrome*/
        -webkit-appearance: none;
      }

      select.no-caret::-ms-expand {
        display: none;
      }

      .form-control {
        padding: 10px;
        &:focus {
          border: 2px solid #b9fa94;
          outline: none;
          .set-box-shadow(none);
        }
      }
      .btn {
        font-size: 15px;
        white-space: normal;
        &.full-width {
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }
      }
      label {
        display: block;
        .label {
          color: #555;
          font-size: 14px;
          text-align: left;
          padding: 0;
        }
        input.form-control {
          color: #555;
          font-size: 17px;
        }
      }

      .select-dropdown {
        color: #555;

        .Select-control {
          height: 100%;
        }
      }
    }

    & > .new-screen-right {
      flex: 1;

      & textarea {
        font-size: 14px;
      }

      & .margin-top-8 {
        margin-top: 8px;
      }

      & .margin-top-10 {
        margin-top: 10px;
      }

      & .client-concern {
        margin: 12px;
        display: flex;
        flex-direction: column;

        & > span {
          color: #000;
          font-size: 14px;
          text-align: inherit;
        }

        & > textarea {
          font-size: 14px;
        }
      }
    }
  }
}

.consultation-notes-form {
  margin: 6px;

  & > input {
    text-align: center;
  }

  & > textarea {
    margin: 6px 0;
    resize: none;
  }
}

.full-width {
  width: 100%;
}

.consultation-note-item {
  margin: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  height: 70px;
  cursor: pointer;
  display: flex;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  background-color: #fff;

  &:hover {
    border-color: #ddd;
  }

  & .consultation-data {
    flex: 1;
    flex-direction: column;
    display: flex;
    font-size: 12px;
    padding: 5px;

    & .consultation-data-name {
      flex: 1;
      font-weight: bold;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & .consultation-control {
    flex: 0 0 40px;
    display: flex;
    flex-direction: column;

    & .control-delete {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 6px;

      & > .delete {
        width: 17px;
        height: 17px;
        background-position: 50%;
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }

    & .control-favourite {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 6px;

      & > .favourite {
        width: 17px;
        height: 17px;
        background-position: 50%;
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
  }
}

.reminders-filter-title {
  margin-left: 4px;
  font-size: 12px;
}

.reminders-filter-select {
  margin-bottom: 10px;

  .calendar-doctor-dropdown-title {
    text-align: left !important;
    width: 330px;
  }

  & > button > span {
    float: right;
  }

  .caret {
    margin-top: 8px;
  }
}

.calendar-doctor-dropdown-title {
  &.reminders-filter {
    text-align: center;
  }
}

.repeat-appointment-repeat-modal-dialog {
  .modal-dialog {
    width: 600px;
  }

  .repeat-appointment-repeat-modal-main {
    background-color: #eee;

    & > h4 {
      margin-top: 0;
    }

    .modal-buttons {
      position: relative;
      height: 40px;

      & > button {
        font-size: 20px;
        position: absolute;
        text-decoration: none;
      }

      .repeat-appointment-repeat-modal-save {
        right: 0;
      }

      .repeat-appointment-repeat-modal-cancel {
        left: 0;
      }
    }

    .repeat-appointment-repeat-modal-close {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 18px;
      text-decoration: none;
    }

    .repeat-appointment-repeat-modal-body {
      margin: 0 20px;
      padding: 15px;
      border: 1px solid #ddd;
      background-color: #fff;

      .repeat-appointment-occurrences {
        height: 35px;
        line-height: 35px;
        font-size: 18px;
      }

      .repeat-appointment-datepicker {
        & > div {
          width: 100%;
        }
      }
    }
  }
}

.repeat-appointment-main-block {
  text-align: center;

  .repeat-appointment-after-form {
    .col-sm-4 {
      padding-right: 5px;
    }

    .col-sm-8 {
      padding-left: 5px;
    }
  }

  .repeat-appointment-item-after {
    color: #aaa;
    background-color: #f9f9f9;
    height: 34px;
    border: 1px solid #ccc;
    border-radius: 3px;
    position: relative;
    cursor: not-allowed;

    .Select-arrow-zone {
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      display: inline-block;
      right: 5px;
      cursor: not-allowed;

      .Select-arrow {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0;
      }
    }
  }

  & button:not(.react-datepicker__navigation) {
    width: 100%;
    margin: 10px 0;
  }

  & .repeat-appointment-list {
    border-bottom: 1px solid #999;
    padding-bottom: 10px;
    margin-bottom: 10px;

    & > div:not(:last-child) {
      margin-bottom: 5px;
    }

    & .col-sm-3 {
      padding: 0 5px 0 0;
    }

    & .col-sm-5 {
      padding: 0 0 0 5px;
    }

    & .btn-repeat-repeat {
      padding: 0;
      height: 32px;
      margin: 0;
      background-size: 50%;

      &.btn-repeat-repeat-selected {
        background-size: 50%;
      }
    }

    & .btn-repeat-delete {
      padding: 0;
      height: 32px;
      width: 40px;
      margin: 0;
      background-size: 95%;
    }
  }
}

.delete-appointment-modal {
  .modal-dialog {
    width: 300px;

    .modal-title {
      text-align: center;
      height: 30px;
      line-height: 30px;
    }

    .modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;

      & > button {
        background-color: #fff;
        color: blue;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: 0;
        border-top: 1px solid #ccc;
        height: 50px;
        font-size: 16px;

        &:first-child {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}

.repeat-appointment-sidebar {
  .modal-body {
    overflow-y: scroll;
    overflow-x: hidden;
    .hidden-scrollbar();
  }
}

.modal-header {
  &.repeat-appointment-header {
    text-align: center;
  }

  .btn-chk {
    padding: 3px;
  }

  .repeat-appointment-header-title {
    display: inline-block;
    margin-left: -20px;
    margin-top: 5px;
    font-size: 17px;
    color: #fff;
  }
}

.repeat-appointment-line-title {
  text-align: center;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;

  & > .repeat-appointment-text {
    position: relative;
    font-size: 20px;
    padding: 0 10px;
  }

  & > .repeat-appointment-text:before {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #999;
    right: 100%;
  }

  & > .repeat-appointment-text:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #999;
    left: 100%;
  }
}

.repeat-appointment-follow-type-select-block {
  text-align: center;
  margin: 10px 0;
  cursor: pointer;

  &.select-appointment-sidebar {
    text-align: left;
    cursor: default;

    & > div {
      display: inline-block;
    }
  }

  & > div > label.repeat-modal-label {
    &.select-appointment-sidebar-label {
      display: inline-block;
      cursor: pointer;
    }
  }

  & > label.repeat-modal-label {
    display: block;
    text-align: left;

    &.select-appointment-sidebar-label {
      display: inline-block;
      cursor: pointer;
    }
  }

  & > label {
    cursor: pointer;
  }

  .repeat-appointment-follow-type-select {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #bec844;
    float: left;
  }
}

.appointment-form-page {
  position: relative;
  min-height: 100%;
  background: #ffffff;
  display: flex;
  padding-top: @height-header;
  max-height: 100%;
  overflow: auto;
  z-index: 1;

  .patient-image {
    .set-border-radius(50%);
    width: 120px;
    height: 120px;
  }
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  .profile {
    .clinic-note {
      position: absolute;
      cursor: pointer;

      &.consent-form {
        top: 126px;
      }

      &.consultation-notes {
        top: 208px;
      }
    }
    .docs {
      top: 248px;
      width: 37.5px;
      height: 37.5px;
      border-radius: 50%;
      border: 2px solid #bec844;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .patient-note {
      top: 45px;
    }
    .footer {
      display: block;
    }

    .price-select {
      height: 22px;
      padding: 0 10px;
      line-height: 1.5;
      border-radius: 3px;
      margin-right: 5px;
      vertical-align: middle;
      display: inline-block;
      width: auto;

      &:focus {
        border-width: 1px;
      }
    }
  }
  form {
    .Select-value-label,
    .Select-placeholder {
      font-size: 15px;
      color: #555;
    }
    .Select.is-disabled > .Select-control {
      background: rgba(0, 0, 0, 0.04);
    }
    &.appointment-form {
      display: flex;
      width: 100%;
    }
    .row {
      margin-bottom: 5px;
      position: relative;

      select {
        padding-top: 11px;
        padding-bottom: 11px;
      }
      select,
      .form-control {
        font-size: 15px;
        &[disabled] {
          background: rgba(0, 0, 0, 0.04);
        }
      }
      .react-datepicker__input-container {
        display: block;
      }
      &.focus-row {
        .form-control,
        select {
          border: 2px solid #b9fa94;
          outline: none;
          .set-box-shadow(none);
        }
        select {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }

      .has-error .form-control {
        border: 2px solid #a94442;
        outline: none;
        .set-box-shadow(none);
      }
    }
    .switch-item {
      .label {
        float: left;
        margin-top: 5px;
        margin-right: 5px;
      }
      .rc-switch {
        float: right;
      }
    }
    select.no-caret {
      /*for firefox*/
      -moz-appearance: none;
      /*for chrome*/
      -webkit-appearance: none;
    }

    select.no-caret::-ms-expand {
      display: none;
    }

    .form-control {
      padding: 10px;
      &:focus {
        border: 2px solid #b9fa94;
        outline: none;
        .set-box-shadow(none);
      }
    }
    .btn {
      font-size: 15px;
      white-space: normal;
      &.full-width {
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }
    }
    label {
      display: block;
      .label {
        color: #555;
        font-size: 14px;
        text-align: left;
        padding: 0;
      }
      input.form-control {
        color: #555;
        font-size: 17px;
      }
    }

    .select-dropdown {
      color: #555;

      .Select-control {
        height: 100%;
        border: 2px solid #b9fa94;

        .Select-value {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .left-side {
    min-width: 330px;
    max-width: 330px;
  }
  .left-side,
  .right-side {
    padding-top: 20px;
    form {
      .submit-btns {
        padding: 5px 0;
        display: block;
        .btn {
          float: right;
        }
      }
    }

    .select-input-lg {
      .Select-control {
        padding: 3px;
        height: 41px;
      }
    }
  }

  .left-side {
    display: flex;
    float: left;
    background: #ffffff;
    position: relative;

    .profile-container-fluid {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
    &.grey-bg {
      background: #f2f2f2;
    }
    .popover-box {
      .custom-popover {
        left: 364px;
        top: 72px;
      }
    }
    .patient-form {
      overflow-y: scroll;
      .hidden-scrollbar();
      .custom-select-box {
        .form-control {
          padding: 10px 5px;
        }
      }
    }
    .search-patient {
      .phone-code-box {
        input {
          text-align: center;
        }
      }
      .submit-btns {
        padding: 5px 0;
        .btn {
          float: right;
          margin-left: 10px;
        }
      }
    }
  }
  .right-side {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .creator-row {
    padding: 10px 0 35px;
  }
  .note-row {
    textarea {
      height: 100px;
    }
  }
  [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.appointment-header {
  z-index: 50;
  position: absolute;
  width: 100%;

  .logo-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;

    .logo-link {
      pointer-events: initial;
    }
  }

  .earlier-container {
    float: right;
    height: 100%;
  }
}

.appointment-header-back {
  margin: 7.5px 0;
}

.related-this-table {
  flex: 1;

  .col-1 {
    flex: 0 0 100px;
  }
  .col-2 {
    flex: 0 0 120px;
  }
  .col-3 {
    flex: 1;
  }
  .col-4 {
    flex: 0 0 50px;
  }
  .col-5 {
    flex: 0 0 75px;
  }
  .col-6 {
    flex: 0 0 75px;
  }
  .col-7 {
    flex: 0 0 50px;
  }
}

.chart-buttons {
  .btn + .btn {
    margin-left: 20px;
  }
}

.chart-btn-darker {
  border: 3px solid #757c21;
}

.repeat-appointment-sidebar-panel-group {
  a:focus,
  a:hover {
    text-decoration: none;
  }

  &.panel-group .panel {
    margin-top: 5px;
  }
}

.bring-earlier-table {
  & th {
    height: 40px;
    padding: 0 12px;
    text-align: center;

    &:not(:last-child) {
      border-right: 1px solid #cccccc;
    }
  }

  & tr {
    padding: 0 12px;
    border-bottom: 1px solid #cccccc;

    & > td {
      padding: 8px 12px !important;

      &:not(:last-child) {
        border-right: 1px solid #cccccc;
      }

      &:nth-child(1) {
        width: 360px;

        & > div {
          align-items: unset !important;
        }
      }

      &:nth-child(2) {
        width: 250px;
        vertical-align: middle;

        & > div {
          align-items: unset !important;
        }
      }

      &:nth-child(3) {
        width: 250px;
        text-align: center;
        vertical-align: middle;
      }

      &:nth-child(5){
        width: 200px;
        text-align: center;
        vertical-align: middle;
      }
      &:nth-child(6) {
        width: 120px;
        text-align: center;
        vertical-align: middle;
      }

      & > div {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .move-button {
    border: @brand-primary;

    &:hover,
    &:active,
    &:focus {
      color: #ffffff;
      border: @brand-primary;
      background-color: @brand-primary;
    }
  }
}
.select-recipient-type {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 50%;
  border: 2px #bec844 solid;
  float: left;
}

.react-datepicker__header__dropdown {
  select {
    padding: 0 !important;
  }
}
.appointment-length {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  &__buttons {
    transition: all 0.3s ease-in-out;
    position: absolute;
    display: flex;
  }
  &__plus {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    position: absolute;
    right: 0;
  }
  &__minus {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    position: absolute;
    left: 0;
  }
}
.appointment-additional-doctors-modal {
  width: 70vw;
}
.control-lg {
  &.invalid-form-field {
    .select-picker__control {
      border-color: red;
    }
  }
}

.is-invalid {
  border: 1px solid #ff4d4f;
}

.appointment-logs-modal {
  width: 70vw;
}
