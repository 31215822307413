@import "variables";

.medical-fill-in-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  &__header {
    padding: 1rem;
    background: @btn-primary-bg;
  }
  &__body {
    height: 100%;
    display: flex;
    align-items: stretch;
    &__left-side {
      position: relative;
      padding: 1rem;
      flex: 2;
      height: 100%;
      border-right: 1px solid #cccccc;
      -webkit-box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
      box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
      transition: all 0.5s linear;
      .menu-toggler {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: -20px;
        z-index: 10;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        background: #bec844;
        cursor: pointer;
      }
    }
    &__right-side {
      padding: 0 1rem 1rem;
      flex: 6;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      transition: all 0.5s linear;
    }
  }
  .medical-fill-in-form-question {
    &__title {
      font-size: 18px;
      font-weight: bold;
      background: white;
      margin-top: 1rem;
      padding-bottom: 1rem;
    }
    &__body {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-x: auto;
      img {
        width: 100%;
      }
      table {
        width: auto !important;
        height: auto !important;
      }
      .pick-list-button {
        white-space: normal;
      }
    }
    textarea {
      padding: 1rem;
    }
    &__question {
      flex: 1;
      width: 50%;
      line-break: anywhere;
    }
    &__answer {
      flex: 1;
      width: 50%;
      line-break: anywhere;
    }
    &__actions {
      margin-top: 1rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .medical-fill-in-form-entry-buttons {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    button {
      margin-bottom: 1rem;
      span {
        font-size: 1.5rem;
      }
    }
  }
  .medical-fill-in-form-list-item {
    -webkit-box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2) inset;
    -moz-box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2) inset;
    box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2) inset;
  }
  .medical-fill-in-form-answer {
    cursor: pointer;
    color: #1aa2fe;
    &>:hover:not(.pick-list-button) {
      text-decoration: underline;
    }
  }
  @keyframes medical-overlay {
    from {opacity: 0}
    to {opacity: 1}
  }
  #medical-overlay {
    animation: medical-overlay 400ms linear 0s;
  }

}
.medical-fill-in-form-treatment {
  height: 100%;
  padding-top: 1rem;
  &__header {
    height: 4%;
  }
  &__footer {
    height: 4%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__list {
    height: 46%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    &__table {
      height: 100%;
      border-radius: 4px;
      border: 1px #999 solid;
      display: flex;
      flex: 1 1;
      flex-direction: column;
      &__header {
        display: flex;
        flex: 0 0 40px;
        border-bottom: 1px solid #999;
        & > div {
          padding: 0 10px;
          height: 40px;
          overflow: hidden;
          line-height: 40px;
        }
      }
      &__body {
        min-height: 10px;
        display: flex;
        flex: 1 1;
        overflow-y: auto;
        flex-direction: column;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.25);
          outline: 1px solid rgba(0, 0, 0, 0.25);
        }
        &__row {
          display: flex;
          flex: 0 0 40px;
          border-bottom: 1px solid #999;
          & > div {
            padding: 0 10px;
            height: 40px;
            overflow: hidden;
            line-height: 40px;
          }
        }
      }
      .item-date-posted {
        flex: 0 0 100px;
      }
      .item-treatment-code {
        flex: 0 0 160px;
        text-align: center;
      }
      .item-discount {
        flex: 0 0 240px;
        text-align: center;
      }
      .item-tooth {
        flex: 0 0 60px;
        text-align: center;
      }
      .item-session {
        flex: 0 0 60px;
        text-align: center;
      }
      .item-description {
        flex: 1;
      }
      .item-fee {
        flex: 0 0 70px;
      }
      .item-principal-diagnosis {
        flex: 0 0 80px;
      }
      .item-approval {
        flex: 0 0 130px;
      }
      .item-expiry-date {
        flex: 0 0 100px;
      }
      .item-posted-by {
        flex: 0 0 150px;
      }
    }
  }
}
.medical-fill-in-form__body.menu-visible {
  .medical-fill-in-form__body__left-side {
    flex: 0;
    box-shadow: none;
    padding: 0;
    transition: all 0.5s linear;
  }
  .medical-fill-in-form__body__right-side {
    flex: 1;
    transition: all 0.5s linear;
  }
}
.medical-forms {
  margin: 0 !important;
  input[type="checkbox"], input[type="radio"] {
    width: 20px;
    height: 20px;
  }
  label {
    font-size: 10px;
  }
}

.medical-fill-in-form-vitals {
  padding-top: 2rem;

  .medical-fill-in-form-vitals__list {
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;

    &-title {
      display: inline-block;
      text-transform: uppercase;
      padding: 1px 10px;
      background: #c0c0c0;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .medical-fill-in-form-vitals__table {
      height: 100%;
      display: flex;
      border: 1px solid #C1C1C1;
      border-radius: 4px;
      overflow-y: auto;

      .vitals_table_item {
        width: 100%;
        min-height: 45px;
        position: relative;
        border-right: 1px solid #C1C1C1;
      }

      .vitals_table_left {
        min-width: 200px;
        max-width: 350px;

        &_item {
          margin-bottom: 0;
          padding: 10px;
        }
      }

      .vitals_table_right {
        display: flex;
        flex: 1 1;
        overflow-y: auto;

        .table-head_right-top {
          &_item {
            text-align: center;
            margin-bottom: 0;
            padding: 8px 10px;
          }
        }

        &_item {
          margin-bottom: 0;
          padding: 10px;
          text-align: center;
        }

        &_item:nth-child(odd) {
          background-color: #f5f5f5;
        }
      }
    }
  }
}

.missed-cancelled-table-modal {
  .modal-dialog {
    width: 250px;
  }
}

.bg-light {
  background-color: #f5f5f5;
}

.comment-indicator {
  width: 10px;
  height: 10px;
  position: absolute;
  background-color: #0073ea;
  left: 10px;
  top: 10px;
  line-height: 9px;
  font-size: 10px;
  font-weight: 400;
  border-radius: 10px;
  text-align: center;
  padding: 2px 4px;
}

.med-form_popover {
  height: 20px;
  position: relative;
  display: inline-block;
}

.popover-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0 !important;
  cursor: pointer;
}

.med-form_popover_popover-content {
  position: absolute;
  width: 200px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  border-radius: 5px;
  padding: 9px 14px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%) rotate(180deg);

    border-width: 10px;
    border-style: solid;
    border-color: transparent #ccc transparent transparent;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -16px;
    transform: translateY(-50%) rotate(180deg);

    border-width: 8px;
    border-style: solid;
    border-color: transparent #fff transparent transparent;
  }
}

.med-form_popover_popover-content.right {
  top: 50%;
  right: 100%;
  margin-right: 7px;
  transform: translateY(-50%);
}

.med-form_popover_popover-content.left {
  top: 0;
  right: 100%;
  margin-right: 10px;
}

.edit-icon {
  width: 35px;
  height: 35px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
