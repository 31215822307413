@height-header: 50px;

.edit-insurance-plan-dialog {
  & > .modal-dialog {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 100px;

    & > .modal-content {
      height: 100%;
    }
  }

  .insurance-plan-select {
    width: 100%;
  }
}

.insurance-photo-sidebar {
  & > .modal-dialog {
    display: flex;
    flex-direction: column;
    margin: 0;
    height: 100%;
    width: 100%;

    & > .modal-content {
      flex: 1;
      border-radius: 0;
      background-color: #000;
      color: #fff;
      display: flex;
      flex-direction: column;

      & > .modal-header {
        text-align: center;

        & > button {
          color: #fff;
        }

        & > div {
          display: inline-block;
          margin-left: -34px;
          margin-top: 5px;
        }
      }

      & > .modal-body {
        flex: 1;
        display: flex;

        & > .image {
          &.contain {
            background-size: contain;
          }

          flex: 1;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      & > .modal-footer {
        padding: 25px;

        & > .col-sm-6 {
          position: relative;

          & > div {
            height: 17px;
            width: 14px;
            position: absolute;
            top: -8px;
            cursor: pointer;

            &.button-prev {
              background-position: center;
              background-repeat: no-repeat;
              right: 70px;
            }

            &.button-next {
              background-position: center;
              background-repeat: no-repeat;
              left: 70px;
            }
          }
        }
      }
    }
  }
}

.insurance-form-page {
  position: relative;
  background-color: #777b7e;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  display: flex;
  min-height: 100%;
  max-height: 100%;
  padding-top: 50px;
  .set-transition();

  @media (max-height: 800px) {
    overflow-y: auto;
  }
  .insurance-wrap {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    overflow-y: auto;
    position: relative;
    @media (max-height: 800px) {
      height: 120%;
    }
  }

  .item-value {
    font-size: 1.4em;
    font-weight: bold;
    color: #ffffff;
    display: block;
    line-height: 44px;
  }

  .insurance-forms {
    .sidebar-mode {
      width: 80%;
      margin: 0 auto;
    }

    .form-image {
      height: 100px;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 80%;
      width: 100px;
      float: left;
      cursor: pointer;
    }

    .insurance-form-item {
      height: 100%;
      padding: 25px 0;

      .form-title {
        margin-bottom: 30px;
        font-size: 24px;
        text-transform: uppercase;
        color: #ffffff;
        text-align: center;
      }
    }

    .col-sm-5 {
      height: 75px;
    }

    .button-wrap {
      padding: 15px 0;

      .btn {
        width: 28%;

        & + .btn {
          margin-left: 8px;
        }
      }

      .btn-photo {
        display: flex;
        align-items: center;
        background: none;
        max-width: 12%;
        height: 44px;
        float: right;
      }
    }
  }

  .with-search-btn {
    .form-control {
      width: 65%;
      float: left;
    }

    .btn {
      float: right;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      width: 30%;
    }
  }

  label {
    display: block;

    .label {
      color: #f0f0f0 !important;
      font-size: 14px;
      text-align: left;
      padding: 0;
      display: block;
    }

    .react-datepicker__input-container {
      display: block;
    }

    input.form-control,
    .custom-select-box .select-dropdown {
      color: #333333;
      font-size: 17px;
    }

    &.col-field {
      float: left;
      width: 48%;
      padding: 0 !important;

      &:nth-child(2n) {
        float: right;
      }
    }

    .form-control {
      &[disabled] {
        background: #eeeeee !important;
      }
    }
  }

  .insurance-header {
    position: fixed;
    width: 100%;
  }

  .sidebar-mode_header {
    position: sticky;
    top: 0;
    margin-top: 0;
  }
}

.sidebar-mode_wrapper {
  padding-top: 0;
}

@imageSize: 150px;

.choose-photo-modal {
  font-size: 15px;

  .modal-dialog {
    width: 520px;

    .modal-body {
      text-align: center;
      font-size: 1.2em;
      font-weight: bold;
      padding: 15px;
      text-align: center;

      .insurance-list-photo {
        padding: 15px 0;
        margin: 30px 0;

        .clearbox {
          clear: both;
        }

        .insurance-image {
          float: left;
          width: @imageSize;
          height: @imageSize;
          margin: 0 6px;
          position: relative;

          .image-block {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 150px;
            border-radius: 0;
            background-color: #ddd;
            background-size: 80%;
            background-position: 50%;
            background-repeat: no-repeat;
          }

          .dropzone {
            width: @imageSize;
            height: @imageSize;
          }

          .delete-button {
            display: flex;
            width: 20px;
            height: 20px;
            position: absolute;
            right: 0;
            top: 0;
            background: rgba(255, 127, 80, 0.5) no-repeat 50%;
            background-size: 60%;
            cursor: pointer;
          }

          .delete-button:hover {
            background-color: rgb(255, 127, 80);
          }
        }
      }

      .btn {
        display: block;
        border: none;
        padding: 10px;
        margin: 10px auto 0;
        width: 60%;
      }
    }
  }
}
