@import "classes";

.chart-plan-for-table {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #ddd;

  & .check-all-button {
    padding: 0;
    border: none;
    outline: none;
    background: none;

    &:focus {
      outline: none;
    }
  }

  .chart-plan-for-table-header {
    flex: 0 0 60px;
    padding-right: 5px;
  }

  .chart-plan-for-table-body {
    .slim-scrollbar();
    overflow-y: scroll;

    .chart-plan-for-table-row {
      cursor: pointer;
    }
  }

  .chart-plan-for-table-row {
    flex-shrink: 0;
    display: flex;
    border-bottom: 1px solid #ddd;
    height: 60px;
    align-items: center;

    &.table-row-group {
      height: auto;
      align-items: initial;
      flex-direction: column;
    }

    & > .group-title {
      background-color: #049dd8;
      color: #ffffff;
      user-select: none;

      & > .col-3 {
        display: flex;
        align-items: center;
      }
    }

    &:not(:first-child) > .group-title {
      border-top: 1px solid #999;
    }

    .col-discount {
      flex: 0 0 220px;
      padding: 0 5px;
      display: flex;

      & > .Select {
        flex: 1 1 0%;
        margin-right: 8px;
      }

      & > input {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 0px 8px;
        width: 80px;
      }
    }

    .col-1 {
      flex: 0 0 100px;
      padding: 0 5px;
    }
    .col-2 {
      flex: 0 0 160px;
      text-align: center;
    }
    .col-3 {
      flex: 1;
      align-self: stretch;
      overflow: hidden;

      &.title {
        align-self: center;
      }
    }
    .col-4 {
      flex: 0 0 60px;
    }
    .col-5 {
      flex: 0 0 100px;
    }
    .col-6 {
      flex: 0 0 100px;
    }
    .col-7 {
      flex: 0 0 100px;
    }
    .col-8 {
      flex: 0 0 50px;
    }
  }

}

.chart-plan-for-bottom-btn {
  flex: 0 0 34px;
  margin-top: 10px;
}

.chart-plan-for-mode {
  margin-bottom: 10px;
}

.chart-plan-for-blank-search {
  height: 48px;
}