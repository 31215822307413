.lab-order-info-row {
  margin: 10px 10px 0;
}

.chart-lab-order-form {
  padding: 30px 10px 0;

  label {
    margin-top: 5px;
  }

  textarea.form-control {
    height: 200px;
  }

  .react-datepicker__input-container {
    display: block;
  }

  .form-group .react-datepicker__input-container input {
    width: 100%;
  }
}

.lab-order-attachment-wrapper {
  white-space: nowrap;
  overflow-x: auto;

  .dropzone {
    display: inline-block;
    vertical-align: top;
  }
}

.lab-order-attachment-item {
  display: inline-block;
  position: relative;
  margin-left: 10px;

  img {
    height: 150px;
  }

  .lab-order-attachment-delete {
    position: absolute;
    height: 50px;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}

.chart-lab-order-button {
  margin-top: 150px;
}

.order-modal {
  height: 100%;
  overflow: hidden;
  &__body {
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
