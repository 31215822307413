.register-device-login {
  position: relative;
  min-height: 100%;
  background-size: 100% auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  form {
    width: 470px;
    height: 474px;
    padding: 30px 20px 60px;

    .title {
      text-transform: uppercase;
      text-align: center;
      color: #7d7d7d;
      padding: 20px 0 12px;
      font-size: 20px;
    }

    .sub-title {
      text-align: center;
      color: #7d7d7d;
      padding: 0 0 20px;
      font-size: 16px;
      margin-bottom: 35px;
      border-bottom: 1px solid #cbcbcb;
    }

    .inputs, .sign-in-button {
      padding: 0 60px;
    }

    .inputs {
      .input-group {
        .input-group-addon {
          border-radius: 5px 0 0 0;
        }
        .form-control {
          border-radius: 0 5px 0 0;
        }
      }

      .input-group + .input-group {
        .input-group-addon {
          border-radius: 0 0 0 5px;
          border-top: 0;
        }
        .form-control {
          border-radius: 0 0 5px 0;
          border-top: 0;
        }
      }
    }

    .sign-in-button {
      margin-top: 65px;
    }
  }
}

