@import 'variables';

.top-menu {
  z-index: 1000;
  min-height: 20px;
  height: @topClockHeight;
  margin-top: -@topClockHeight;
  position: relative;

  background-color: #eeeeee;
  cursor: pointer;

  & > .top-menu-dots-container {
    position: relative;

    & > .top-menu-dots {
      left: 0;
      top: 8px;
      right: 0;
      width: 20px;
      display: flex;
      margin: 0 auto;
      position: absolute;
      justify-content: space-between;

      & > .top-menu-dot {
        background-color: #ffffff;
        border-radius: 50%;
        width: 4px;
        height: 4px;
      }
    }
  }

  .clock {
    cursor: pointer;
    text-align: center;
    color: black;
    font-size: 12px;
    line-height: @topClockHeight;
    margin: 0;

    .diem {
      padding-left: 5px;
    }
  }
}

.app {
  padding-top: @topClockHeight;
}

.black-menu {
  display: flex;
  padding: 0 10px;
  background: #000;
  justify-content: space-between;

  .item {
    text-align: center;
    color: white;
    height: 40px;
    padding: 4px 10px 0;
    cursor: pointer;

    &:hover {
      background: gray;
      text-decoration: none;
    }

    > .icon {
      height: 20px;
      vertical-align: top;
    }
    > .text {
      font-size: 10px;
      line-height: 15px;
    }
  }

  .dropdown-menu {
    background: black;

    li {
      a, .dropdown-item, span {
        display: block;
        padding: 0 10px;
        color: white;
        cursor: pointer;

        &:hover {
          text-decoration: none;
          background: grey;
        }

        > .text {
          display: block;
          padding: 3px 0;
        }
      }

      & + li {
        a, span {
          > .text {
            border-top: 1px solid rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }
}

#top-menu {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  z-index: 2;
  transition: top 270ms ease-in-out;

  .member {
    float: left;
    color: white;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    height: 40px;
    cursor: pointer;
    padding: 5px 45px 0 10px;
    &:hover {
      background: gray;
    }

    > .name {
      padding-top: 3px;
    }

    > .member-image {
      float: right;
      margin-right: -35px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}

.ip-address {
  .list-group-item:first-child {
    margin-top: 1rem;
  }
}
