@patient-dark-green: #2f8081;
@patient-light-green: #30aa83;
@patient-red: #d11b1c;
@patient-light-blue: #5680d3;
@patient-dark-blue: #2f5882;
@patient-orange: #ffa319;
@patient-raspberry: #a64b72;
@patient-blue: #13b3b5;

@import "classes";
@import "variables";

.patient-view-flex {
  .outlet-icon {
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    padding: 2px;
  }
  .profile {
    flex: 7;
    margin: 2px 0;
    display: flex;
    background: fade(@patient-dark-green, 90%);
    color: #fff;

    .action {
      flex: 1;
      background: rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      padding: 10px;

      .member-image {
        width: 135px;
        height: 135px;
        margin: 0 auto;
      }

      .btn-box {
        flex: 1;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }

      .btn {
        width: 150px;
        font-size: 18px;
        line-height: 20px;
      }
    }
    .info {
      flex: 1;
      padding: 10px;
      cursor: pointer;
    }
  }

  .contacts {
    flex: 4;
    background: fade(@patient-light-green, 90%);
    margin: 2px 0;
    cursor: pointer;
  }

  .info,
  .contacts,
  .alerts,
  .forms,
  .notes,
  .insurance,
  .insurance-notes,
  .appointments,
  .patient-reminders,
  .clinician,
  .consultation,
  .working-hours {
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 10px;
    position: relative;

    .body {
      flex: 1 1 100%;
      line-height: 30px;
      font-size: 16px;
    }
    .footer {
      display: flex;
      flex: 0 0 auto;
      justify-content: space-between;
      .text {
        text-transform: uppercase;
        line-height: 25px;
        font-weight: bold;
        font-size: 17px;
      }
    }
  }

  .alerts {
    flex: 3;
    background: fade(@patient-red, 90%);
    margin: 2px 0;
    cursor: pointer;
    max-height: 200px;
  }

  .working-hours {
    flex: 3;
    background: fade(@patient-dark-green, 90%);
    margin: 2px 0;
    cursor: pointer;

    .body {
      & > div {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .alerts,
  .notes,
  .insurance-notes,
  .clinician,
  .appointments,
  .patient-reminders,
  .consultation {
    .body {
      overflow: auto;

      .hidden-scrollbar();
    }
  }

  .appointments {
    flex: 1;
    background: fade(@patient-dark-blue, 90%);
    cursor: pointer;
    margin: 2px 0;

    .item {
      display: flex;
      justify-content: space-between;
    }
  }

  .patient-reminders {
    background: fade(@patient-light-blue, 90%);
    flex: 1;
    margin: 2px 0;
    cursor: pointer;

    .body {
      .title {
      }
      .item {
        line-height: normal;
      }
    }
  }

  .forms {
    background: fade(@patient-light-blue, 90%);
    flex: 1;
    margin: 2px 0;
  }

  .consultation {
    background: fade(@patient-blue, 90%);
    width: 100%;
    margin: 2px 0;
    cursor: pointer;

    .item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .notes {
    background: fade(@patient-dark-green, 90%);
    flex: 1;
    margin: 2px 0;
    cursor: pointer;
  }

  .insurance {
    background: fade(@patient-orange, 90%);
    flex: 1;
    margin: 2px 0;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .insurance-notes {
    background: fade(@patient-raspberry, 90%);
    cursor: pointer;
    flex: 1;
    margin: 2px 0;
  }

  .clinician {
    background: fade(@patient-raspberry, 90%);
    flex: 1;
    cursor: pointer;
    margin: 2px 0;
  }

  .form-row {
    cursor: pointer;
  }
}

.patient-view {
  padding: 0 !important;
  height: 100%;
  background-size: 100% auto;

  .main-header {
    text-align: center;
    .btn {
      position: absolute;
    }
    img {
      height: 40px;
    }
  }
}

.patient-view-row-1 {
  flex: 1;
  display: flex;
}

.patient-view-col {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.patient-view-row-2 {
  flex: 2;
  display: flex;

  .cube {
    flex: 9;
    display: flex;
    flex-direction: column;

    .cube-row {
      display: flex;
      flex: 1;
    }
  }
}

.patient-select-appointment-sidebar {
  .modal-dialog {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    display: flex;
    width: 40%;
    height: 100%;

    .modal-content {
      border-radius: 0;
      width: 100%;
      display: flex;
      flex-direction: column;

      .modal-body {
        display: flex;
        flex-direction: column;
        flex: 1;

        .patient-appointment-list {
          flex: 1;
          overflow-x: hidden;
          overflow-y: scroll;
          .hidden-scrollbar();
        }

        .patient-select-appointment-sidebar-choose-date {
          margin-right: 5px;

          & > div {
            width: 250px;
          }
        }

        .patient-select-appointment-sidebar-list-item {
          color: #fff;
          display: flex;
          height: 50px;
          margin-bottom: 5px;
          cursor: pointer;
          user-select: none;

          .list-item-info {
            background-color: #2196f3;
            flex: 1;
            position: relative;

            & > span {
              position: absolute;
              left: 10px;

              &.item-info-type {
                bottom: 0;
              }
            }
          }

          .list-item-status {
            flex: 0 0 150px;
            padding: 5px;

            & > span {
              display: block;
              text-align: center;
            }
          }
        }
      }

      .modal-header {
        height: 70px;
        background-color: #bec844;
        padding-top: 20px;

        & > button {
          color: #fff;
          text-decoration: none;

          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.patient-view-choose-chart-action {
  .modal-body {
    padding: 0;

    & > button {
      display: block;
      border: 0;
      width: 100%;
      height: 50px;
      color: #00f;
      border-bottom: 1px solid #e5e5e5;
      text-decoration: none;

      &:hover {
        background-color: #e5e5e5;
      }
    }

    & > button:last-child {
      border-bottom: 0;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}

.patient-appointments-list {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  .complete-switcher-container {
    margin: 0 15px;
  }

  .btn-user-name {
    color: #bec844;
    font-weight: 400;
    border-radius: 0;
    margin: 6px 12px;
  }

  .appointments-list-table {
    margin: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .appointments-list-header {
      width: 100%;
      border: 1px #bec844 solid;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #bec844;
      min-height: 40px;
      max-height: 40px;
      color: #fff;
      display: flex;
      flex-direction: row;

      & > div {
        width: 20%;
        padding: 10px 20px;
        line-height: 20px;

        &.double {
          width: 40%;
        }
      }
    }

    .appointments-list-body {
      flex: 1;
      width: 100%;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 1px #ccc solid;
      border-top: none;
      .hidden-scrollbar();
      overflow-y: auto;

      .appointments-list-row {
        display: flex;
        height: 60px;
        border: none;
        border-bottom: 1px solid #ccc;
        flex-direction: row;

        & > div {
          &.note-status {
            text-transform: uppercase;
          }

          &.log-buttons {
            text-align: center;
          }

          & > button {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 40%;
            max-width: 60px;
            width: 30%;
            height: 34px;

            &.disabled {
              background-color: #999;
              border-color: #999;
            }

            &.button-notes {
              margin-right: 5px;
            }

            &.button-doctors {
              margin-right: 5px;
            }
          }
        }

        & > div {
          width: 20%;
          padding: 10px 20px;
          line-height: 40px;

          &.double {
            width: 40%;
            .exclamation {
              display: inline-block;
              width: 40px;
            }
          }
        }
      }
    }
  }
}

.appointment-log-modal {
  .modal-dialog {
    width: 350px;
  }
}

.patient-view-note-modal {
  .add-note {
    padding: 5px;
    margin-top: 10px;
    flex-shrink: 0;

    textarea {
      margin: 10px 0;
    }
  }
  .patient-note-item {
    margin: 6px !important;
    display: flex;
    flex: 1;
    border: 1px solid #ccc;
    flex-shrink: 0;
    flex-direction: column;
    padding: 6px;
    border-radius: 4px;

    & > .note-item-body {
      white-space: inherit;
    }
  }
  .note-item {
    margin: 10px 0;
    display: flex;
    flex-shrink: 0;

    .note-item-box {
      flex: 1;
      margin-right: 5px;
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .note-item-title {
      text-align: center;
      display: block;
    }
    .note-item-body {
      padding: 5px 0;
      word-wrap: break-word;
      white-space: pre;
    }
    .note-item-footer {
      display: flex;
      justify-content: space-between;
    }

    .note-item-actions {
      display: flex;
      flex-direction: column;
      .edit {
        padding: 5px;
        background: @brand-primary;
        border-radius: 4px 4px 0 0;
      }
      .delete {
        padding: 5px;
        background: @brand-danger;
        border-radius: 0 0 4px 4px;
      }
    }
  }
}

.note-item-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  .slim-scrollbar();
  padding-left: 5px;
}

.clinician-owned-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 10px;
  font-size: 16px;
}

.clinician-all-item {
  display: flex;
  align-items: center;
  border-top: 1px solid #ddd;
  padding: 5px 10px;
  cursor: pointer;

  .member-image {
    flex-shrink: 0;
    flex-basis: 75px;
    width: 75px;
    height: 75px;
  }

  .name {
    flex: 1;
    margin: 0 10px;
    font-size: 16px;
  }

  .arrow {
    flex-shrink: 0;
    color: #007aff;
  }

  &:first-child {
    border: none;
  }
}

.patient-view-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.patient-alerts-page {
  height: 100%;
  display: flex;
  align-items: center;

  .patient-alerts-page-alerts {
    background: fade(@patient-dark-blue, 90%);
    margin-left: 4%;
    margin-right: 2%;
  }

  .patient-alerts-page-medications {
    background: fade(@patient-raspberry, 90%);
  }

  .patient-alerts-page-alerts,
  .patient-alerts-page-medications {
    position: relative;
    color: #fff;
    flex-basis: 45%;
    height: 45%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 30px;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.patient-consent-form-edit {
  position: relative;
  color: #fff;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(43, 127, 129, 0.9);
  }

  .line-title {
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;

    .text {
      font-size: 20px;
      padding: 0 10px;
    }
  }
}

.patient-consent-form-edit-body {
  margin: 15px;
  padding: 10px;
  position: relative;
  background: #fff;
  color: #333;
}

.doc-fields-modal-list {
  .list-group-item {
    display: flex;
    align-items: center;
    .member-image {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    .text {
      flex: 1;
    }
  }
}

.doc-fields-body {
  overflow-y: scroll;
  .hidden-scrollbar();
  padding-bottom: 20px;
}
@keyframes sm-custom-spinner {
  to {
    transform: rotate(360deg);
  }
}

.sm-custom-spinner {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}

.sm-custom-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: sm-custom-spinner 0.6s linear infinite;
}
.requested-by-patient {
  color: #bec945;
  font-weight: bold;
  border: 2px solid #bec945;
  border-radius: 50%;
  padding: 4px;
  font-size: 18px;
}

.icon-btn-class {
  background-color: unset !important;
}

.family-members-dialog {
  transition: .1s ease-in-out !important;
}

.w-55 {
  width: 55% !important;
}

.family-member-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  background: #d9534f;
  border-radius: 4px;
}

.family-members-body {
  border-top: 1px solid #ccc;
  overflow-y: auto;

  .family-members-table {
    width: 100%;
    .label {
      color: #888;
      padding: 0;
    }
    tr {
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #e6e6e6;
      }

      td {
        padding: 5px 10px;
      }
      + tr {
        border-top: 1px solid #cccccc;
      }
    }
  }
}

.patient-family-members-add-form {
  height: 55%;
}
