@brand-primary: rgb(190, 200, 68);
@brand-success: rgb(184, 172, 151);
@brand-danger: #d9534f;
@btn-primary-bg: @brand-primary;
@btn-success-bg: rgba(190, 200, 68, 1);
@btn-success-color: rgba(255, 255, 255, 1);
@topClockHeight: 20px;
@font-family-base: "CANDARA", Helvetica, Arial, sans-serif;

.text-primary {
  color: @brand-primary;
}

.bg-primary {
  background-color: @brand-primary;
}

.bg-danger {
  background-color: @brand-danger;
}

.bg-white {
  background-color: #ffffff;
}

.text-white {
  color: #ffffff
}

.text-black {
  color: #000000
}

.cursor-pointer {
  cursor: pointer !important;
}
