.slim-scrollbar() {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    outline: 1px solid rgba(0, 0, 0, 0.25);
  }
}

.dropdown-button-wrapper>.dropdown-button-list::-webkit-scrollbar {
  width: 14px;
  height: auto;
}

.hidden-scrollbar() {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    outline: 1px solid rgba(0, 0, 0, 0.25);
  }
}

.link-default() {
  color: inherit;

  &:hover, &:focus, &:visited, &:active {
    text-decoration: none;
  }
}

.fill-image {
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  display: inline-block;
  line-height: 0;
  img {
    opacity: 0;
  }
}

img.zoom-2x {
  zoom: 50%;
}

.fill-image.zoom-2x {
  -webkit-mask-size: contain;
  img {
    zoom: 50%;
  }
}

img.zoom-2-5x {
  zoom: 40%;
}

.fill-image.zoom-2-5x {
  -webkit-mask-size: contain;
  img {
    zoom: 40%;
  }
}

.col-sm-padding {
  .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12,
  .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5,
  .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.input-number-arrows-none {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
