@import "classes";

.medication-embed-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div {
    padding: 12px;
    text-align: center;
  }

  & > embed {
    flex: 1 1 0%;
    display: flex;
  }
}

.medication-drug-modal-table {
  flex: 1;
  //height: 400px;
  margin: 5px;
  overflow: hidden;

  .default-table-body {

    overflow-y: auto;
    overflow-x: visible;
    .slim-scrollbar();
  }

  .col-1 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100/900 * 100%;
  }
  .col-2 {
    flex-shrink: 1;
    flex-grow: 1;

    span {
      line-height: normal;
      display: inline-block;
    }
  }
  .col-3 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 130/900 * 100%;
  }
  .col-4 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 200/900 * 100%;
  }
  .col-5 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 70/900 * 100%;
  }
  .col-6 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100/900 * 100%;
  }
  .col-7 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 60/900 * 100%;
  }
  .col-8 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 40/900 * 100%;
  }
}

.medication-history-modal-table {
  margin: 5px;

  .default-table-body {
    height: 350px;
    overflow-y: auto;
    overflow-x: visible;
    .slim-scrollbar();
  }

  .col-0 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 40px;
  }
  .col-1 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 150px;
  }
  .col-2 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 110px;
    input.form-control {
      width: 90px;
      display: inline-block;
    }
  }
  .col-3 {
    flex-shrink: 1;
    flex-grow: 1;

    span {
      line-height: normal;
      display: inline-block;
    }
    textarea {
      height: 34px !important;
      width: 100% !important;
      overflow-y: auto;
      overflow-x: hidden;
      resize: none;
      display: inline-block;
      margin-top: 3px;
    }
  }
}

.medication-drug-form {
  line-height: 40px;

  .control-label {
    margin-right: 5px;
  }
  .form-control {
    width: 100%;
    margin-right: 5px;
  }

  .row {
    margin: 2px -15px;
  }
}

.medication-list-table {
  margin: 5px;
  min-width: 1980px;

  .default-table-body {
    max-height: 800px;
    overflow-y: auto;
    overflow-x: visible;
    display: block;
  }

  .col-0 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 40px;
  }

  .col-1 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 200px;

    span {
      line-height: normal;
      display: inline-block;
    }
  }
  .col-2 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 70px;
    input.form-control {
      width: 50px;
      display: inline-block;
    }
  }
  .col-3 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 190px;

    select {
      width: 170px;
      display: inline-block;
    }

    &.col-amount {
      flex-basis: 100px;

      select {
        width: 80px;
      }
    }

    &.col-strength {
      flex-basis: 130px;

      select {
        width: 110px;
      }
    }
  }

  .col-4 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 160px;
    select {
      width: 140px;
      display: inline-block;
    }
  }
  .col-5 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 180px;
    select {
      width: 160px;
      display: inline-block;
    }
  }
  .col-6 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 70px;
    input.form-control {
      width: 50px;
      display: inline-block;
    }
  }
  .col-7 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 190px;
    select {
      width: 170px;
      display: inline-block;
    }
  }
  .col-8 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 70px;
    input.form-control {
      width: 50px;
      display: inline-block;
    }
  }
  .col-9 {
    flex-shrink: 1;
    flex-grow: 1;

    textarea {
      height: 34px !important;
      width: 100% !important;
      overflow-y: auto;
      overflow-x: hidden;
      resize: none;
      display: inline-block;
      margin-top: 3px;
    }
  }
  .col-10 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 110px;
  }
  .col-11 {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100px;
  }
}

.medication-pdf-modal {
  margin: 0;
  width: 100%;

  .modal-content {
    border: none;
  }

  .clinical-note-item {
    margin: 0;
    position: relative;
    height: 100vh;
  }

  .chart-side-body {
    flex: 1;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
  }
}
