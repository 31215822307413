// Mixins
// --------------------------

.set-border-radius(@value: 3px) {
  -webkit-border-radius: @value;
  -moz-border-radius: @value;
  border-radius: @value;
}

.set-box-shadow(@value: 0 1px 4px #a9a9a9) {
  -webkit-box-shadow: @value;
  -moz-box-shadow: @value;
  box-shadow: @value;
}

.set-display-ib {
  display: inline-block;
  vertical-align: middle;
}

.set-transition(@value: all 0.3s ease) {
  -webkit-transition: @value;
  -moz-transition: @value;
  -ms-transition: @value;
  -o-transition: @value;
  transition: @value;
}

.appearance (@value: none) {
  -webkit-appearance: @value;
  -moz-appearance: @value;
  -ms-appearance: @value;
  -o-appearance: @value;
  appearance: @value;
}
