.choose-clinic {
  position: relative;
  min-height: 100%;
  background-size: 100% auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .choose-clinic-title {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 44px;
    text-align: center;
    box-shadow: 0 2px 2px -2px;
    color: #7d7d7d;
    background: #fff;
  }

  form {
    width: 470px;
    height: 474px;
    padding: 30px 20px 60px;

    .title {
      text-transform: uppercase;
      text-align: center;
      color: #7d7d7d;
      padding: 20px 45px;
      font-size: 20px;
      margin-bottom: 45px;
      border-bottom: 1px solid #cbcbcb;
    }

    .inputs {
      padding: 0 20px;
    }

    .button {
      margin-top: 50px;
    }

    label {
      color: #7d7d7d;
    }
  }
}
