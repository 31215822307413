@import "classes";

.default-page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.default-page-title {
  border-bottom: 1px solid #bbb;
  padding: 10px 15px;
  background: #fff;
  flex-shrink: 0;
  position: relative;

  .title {
    line-height: 34px;
    font-size: 16px;
  }
}

.default-page-body {
  overflow-y: scroll;
  overflow-x: hidden;
  .hidden-scrollbar();
  flex: 1;
}

.default-table {
  display: flex;
  flex-direction: column;
  border: 1px solid #bbb;
  border-radius: 4px;

  .default-table-header {
    display: flex;
    flex: 0 0 40px;
    border-bottom: 1px solid #bbb;
  }

  .default-table-body {
    display: flex;
    flex: 1;
    overflow-y: scroll;
    flex-direction: column;
    margin-right: -5px;

    .hidden-scrollbar();

    .default-table-col {
      cursor: pointer;
    }
  }

  .default-table-row {
    display: flex;
    flex: 0 0 40px;
    border-bottom: 1px solid #bbb;
  }
  
  .this-table-row {
    display: flex;
    // flex: 0 0 40px;
    border-bottom: 1px solid #bbb;
  }

  .default-table-col {
    padding: 0 10px;
    height: 60px;
    overflow: hidden;
    line-height: 40px;

    .middler {
      line-height: 20px;
      vertical-align: middle;
      display: inline-block;
    }
  }
}
