@import "classes";
@import "variables";

@calendar-time-width: 124px;
@calendar-column-width: 200px;
@calendar-gray-bg: #cdcdcd;
@calendar-dark-gray-bg: #32363b;
@calendar-gray-text: rgba(0, 0, 0, 0.5);
@calendar-title-height: 50px;
@calendar-header-height: 50px;
@calendar-dropdown-width: 250px;
@calendar-header-padding: (@calendar-header-height - 34px)/2;

@calendar-bg: #f9dbd9;
@calendar-patient-bg: #d1e7f5;

.hide-appointment {
  display: none !important;
}

.calendar__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .dnd-appointment-item {
    width: 400px !important;
    height: 80px;
    float: unset;
    margin-top: 5px;
  }

  .appointment-dnd-calendar {
    width: 100%;
    margin-top: 10px;

    .dnd-calendar-header {
      display: flex;
      align-items: center;
      justify-content: center;

      .current-day {
        border: 1px solid #575c63;
        vertical-align: center;
        padding: 1px 10px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: bold;
        margin: 0 1px;
        background-color: #575c63;
        color: #fff;
      }
      .header-arrow {
        position: relative;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 3px;
      }
      .left, .right {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #575c63;
        border-radius: 5px;
        background-color: #575c63;
        cursor: pointer;

        &::before {
          position: absolute;
          left: 9px;
          display: inline-block;
          content: "";
          width: 8px;
          height: 8px;
          border: solid #fff;
          border-width: 0 1px 1px 0;
          transform: rotate(135deg);
        }
      }

      .right {
        &::before {
          left: unset;
          right: 9px;
          transform: rotate(-45deg);
        }
      }
    }
    .dnd-calendar-body {
      .calendar__week__names {
        height: 20px;
        font-weight: 600;
        text-align: center;
        align-items: center;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 1px 1px;
        border-bottom: 1px solid #ccc;
        margin-top: 10px;
      }

      .dnd-calendar-grid {
        margin-top: 12px;
        padding: 0 10px;
        font-weight: 400;
        text-align: center;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: 1fr;
        gap: 1px 1px;

        .calendar-cell {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          border-radius: 10px;
          height: 55px;
          padding-left: 8px;
          padding-top: 5px;
          border: 1px solid #ddd;
          transition: all 0.2s ease;
          cursor: pointer;

          &:hover {
            background-color: #b6b6b6;
            color: #fff;
          }
        }
        .calendar__additional__day {
          padding: 5.5px;
          font-weight: 300;
          cursor: pointer;
          background-color: #f0f0f0;
          color: #909090;
          pointer-events: none;
        }

        .calendar__today__item {
          background-color: #ffdc2826;
          color: #FF0000FF;
        }

        .calendar__selected__item {
          background-color: #b6b6b6;
          color: #fff;
        }
      }
    }
  }
  .copied-appointments {
    width: 100%;
    padding: 10px;
    flex: 1 1;
    overflow-y: auto;

    .appointment-item {
      width: 100%;
      height: 70px;
      border-radius: 2px;
      overflow-y: hidden;
      margin-bottom: 10px;
      transition: width 0.3s ease;

      .appointment-info {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        color: #fff;

        .name {
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          vertical-align: top;
          text-overflow: ellipsis;
          width: 75%;
        }
      }

      &:active {
        cursor: grab;
        width: 400px;
      }
    }
  }
}

.dnd-calendar-backdrop {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 65px;
    top: -10px;
    left: 0;
    background-color: #ffffffd1;
    z-index: 10;
  }

  .copy-past-panel {
    width: 180px;
    border: 2px dashed #BEC843 !important;
    transition: transform 0.2s, width 0.2s, height 0.2s;
  }
}

.copy-past-panel {
  min-width: 110px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 12px;
  margin-right: 5px;
  color: black;
  border: 1px solid #cccccc !important;
  height: 34px;
  border-radius: 4px;
  z-index: 10;
  transition: transform 0.2s, width 0.2s, height 0.2s;

  .appointments-btn {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

.appointment-dnd-sidebar {
  width: 35% !important;
}

.appointment-dnd-modal-body {
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.calendar-form {
  transition: transform 0.2s, width 0.2s, height 0.2s;
}

.appointment-note-modal-text {
  height: auto;
}

.test-simple {
  &:hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.react-datepicker__week {
  & .react-datepicker__day {
    &.react-datepicker__day--today:not(.react-datepicker__day--selected) {
      color: #ff0000;
    }
  }
}
.notification-chip{
  position: absolute;
  background:  #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
  width:18px;
  height: 18px;
  border-radius: 50%;
  color:#fff;
  top:-5px;
  right: -10px;
}


.whatsapp-notification-chip {
  position: absolute;
  background:  #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
  width:18px;
  height: 18px;
  border-radius: 50%;
  color:#fff;
  top:-4px;
  right: -8px;
}

.calendar-top-form {
  .calendar-doctor-dropdown-title {
    width: 165px;
  }

  .whatsapp-image {
    zoom: 98%;
    vertical-align: 3px;
  }

  .calendar-select-date {
    width: 100px !important;
  }

  .dropdown-toggle {
    width: 200px !important;
  }

  .no-response-zoom {
    zoom: 85%;
    vertical-align: 3px;
  }

  .react-datepicker__input-container {
    vertical-align: 3px;
  }
}

.open-slot-form {
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .Select-menu-outer {
    width: 250px;
    font-weight: normal;
  }
}

.checked-in-time {
  margin-top: -5px;
}

.checked-in-title {
  margin-bottom: 5px;
}

.calendar-week-buttons {
  color: #bec844;
  font-weight: bold;

  & > .btn-link {
    text-decoration: none;
  }
}

.calendar-page {
  height: 100%;
  position: relative;
  padding-top: @calendar-header-height;
  background-size: 100% auto;
}

.calendar-print-details-modal {
  .modal-buttons {
    width: 100%;
    text-align: center;

    button {
      width: 100px;

      &:first-child {
        margin-right: 5px;
      }
    }
  }
}

.calendar-print-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  embed {
    width: 100%;
    height: 100%;
  }

}

.find-open-slot-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  width: 55%;
  height: 100%;
  transition: .2s ease-in-out;

  .modal-content {
    height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;

    .modal-body {
      padding: 0;
      flex: 1;
      margin: 0;
      display: flex;
      flex-direction: column;
      .modal-header {
        .btn-primary,
        .btn-default {
          border-color: #fff;
        }

        .btn-default,
        select {
          color: @brand-primary;
          background: #fff;
        }
      }

      & .list-group {
        overflow-y: auto;
        display: block;
        flex: 1;
        .hidden-scrollbar();
        overflow-x: hidden;
        margin-bottom: 0;
      }

      .find-open-slot-sidebar-list {
        .find-open-slot-sidebar-list-item {
          height: 60px;
          position: relative;
          border-bottom: 1px #999 solid;
          cursor: pointer;

          & > span, div {
            position: absolute;
          }

          .data-arrow {
            height: 50px;
            top: 5px;
            right: 15px;
            font-size: 35px;
          }

          .data-date {
            top: 5px;
            left: 5px;
            font-weight: bold;
          }

          .data-time {
            top: 5px;
            right: 40px;
            font-weight: bold;
          }

          .data-name {
            bottom: 5px;
            left: 5px;
          }

          .data-day {
            bottom: 5px;
            right: 40px;
          }
        }
      }
    }

    .open-slot-form-days {
      margin-top: 5px;

      & > div {
        float: left;
      }

      label, input {
        float: left;
        margin-right: 5px;
      }
    }

    .react-datepicker__input-container {
      width: 100%;

      .form-control {
        width: 100%;
      }
    }

    .control-label {
      display: block;
      margin-top: 5px;
    }

    .modal-header {
      background-color: #bec844;

      button {
        color: #fff;
      }

      .title {
        text-align: center;
        color: #fff;
        line-height: 34px;
        text-transform: uppercase;
        font-size: 16px;
      }
    }

    .find-appointments-clinic-filter {
      width: 100%;
      margin-top: 8px;
    }

    .find-appointments-cell {
      color: #fff;
      width: 100%;
      display: flex;
      border-bottom: 1px solid white;
      overflow: hidden;
      cursor: pointer;
      margin: 6px 0;

      &:hover {
        text-decoration: none;
      }

      .left {
        display: flex;
        width: 100%;
        //justify-content: space-between;
        background: #2472D2;
        position: relative;
        span {
          padding: 10px 5px;
        }
        span:not(:last-child) {
          border-right: 1px solid white;
        }
        span:nth-child(1) {
          width: 75px;
        }
        span:nth-child(2) {
          width: 135px;
        }
        span:nth-child(3) {
          width: 90px;
        }
        span:nth-child(4) {
          width: 30%;
        }
        span:nth-child(5) {
          width: 20%;
        }

        .top {
          position: relative;
          .name {
            width: 179px;
            padding-left: 5px;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            vertical-align: top;
          }
          .number {
            position: absolute;
            right: 0;
            top: 0;
            padding-left: 5px;
            padding-right: 5px;
            background: #2472D2;
          }
        }

        .bottom {
          padding-left: 5px;
        }
      }
      .right {
        width: 170px;
        vertical-align: top;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #4FD224;
        .top {
          font-size: 12px;
        }
        .bottom {
          text-align: center;
        }
      }
    }

    .cell {
      color: #fff;
      width: 100%;
      display: flex;
      border-bottom: 1px solid white;
      overflow: hidden;
      cursor: pointer;
      margin-top: 10px;

      &:hover {
        text-decoration: none;
      }

      .left {
        flex: 1;
        display: inline-flex;
        flex-direction: column;
        vertical-align: top;
        justify-content: space-between;
        background: #2472D2;
        position: relative;

        .top {
          position: relative;
          .name {
            width: 179px;
            padding-left: 5px;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            vertical-align: top;
          }
          .number {
            position: absolute;
            right: 0;
            top: 0;
            padding-left: 5px;
            padding-right: 5px;
            background: #2472D2;
          }
        }

        .bottom {
          padding-left: 5px;
        }
      }
      .right {
        width: 120px;
        vertical-align: top;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #4FD224;
        .top {
          font-size: 12px;
        }
      }
    }
  }
}

.calendar-header {
  height: @calendar-header-height;
  background: white;
  width: 100%;
  box-shadow: 0 0 1px;
  text-align: left !important;
}

.calendar {
  height: 100%;
  position: relative;
  overflow: scroll;
}

.calendar.html2canvas {
  height: auto;
  width: fit-content;
  .calendar-time, .calendar-title {
    overflow: visible;
  }

  .calendar-body {
    overflow: visible;
  }

  .calendar-title-item {
    background: #cdcdcd;
  }
  .calendar-time-item {
    background: #cdcdcd;
  }

  .calendar-body-start-bg, .calendar-body-patient-bg, .calendar-body-end-bg {
    opacity: 1;
  }
}

.calendar-title {
  height: 50px;
  margin-top: -@calendar-title-height;
  background: #32363b;
  overflow: hidden;
  position: sticky;
  top: 0;
  margin-left: 124px;
  z-index: 20000000;
}

.calendar-title-wrap {
  white-space: nowrap;
  height: 50px;
}

.calendar-title-item {
  &.comfort {
    height: 50px;
    width: 400px;
  }

  &.compact {
    width: 200px;
  }

  vertical-align: top;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: @calendar-title-height;
  padding: 10px;
  color: #ffffff;
  border-right: 1px solid white;
  font-size: 12px;

  .point, .doctor {
    vertical-align: top;
    display: inline-block;
  }
}

.calendar-time {
  width: @calendar-time-width;
  position: sticky;
  left: 0;
  z-index: 10000000;
  float: left;
  margin-top: 50px;
  background: #32363b;
  color: #ffffff;
  text-align: center;
  overflow: hidden;
}

.calendar-time-item {

  &.comfort {
    line-height: 38px;
    height: 40px;
  }

  &.compact {
    line-height: 18px;
    height: 20px;
  }

  border-bottom: 1px solid white;
  border-top: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-body {
  height: 100%;
  position: relative;
  margin: @calendar-title-height 0 0 @calendar-time-width;
}

.calendar-body-wrap {
  position: relative;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.calendar-body-start-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: @calendar-bg;
  opacity: 0.9;
}

.calendar-body-patient-bg {
  position: absolute;
  left: 0;
  right: 0;
  background: @calendar-patient-bg;
  opacity: 0.9;
}

.calendar-body-end-bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: @calendar-bg;
  opacity: 0.9;
}

.calendar-body-line-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.calendar-column {
  &.comfort {
    width: 400px;
  }

  &.compact {
    width: 200px;
  }

  position: relative;
  border-right: 1px solid white;
  color: #fff;
  float: left;
  min-height: 40px;
  height: 100%;

  .cell {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    border-bottom: 1px solid white;
    color: inherit;
    cursor: pointer;

    &.cell-single-doctor {
      &:hover {
        z-index: 100;
      }
    }

    &.small-cell {
      font-size: 12px;
      line-height: 13px;
      .left {
        justify-content: center;
        .number {
          display: none;
        }
        .bottom {
          display: none;
        }
      }
      .right {
        .top {
          display: none;
        }
      }
    }
    &:hover {
      text-decoration: none;
      left: 0 !important;
      width: 100% !important;
      box-shadow: 0 0 15px 10px rgba(255,255,255,0.75);
      z-index: 10;
      transition: all .3s ease;

      .right {
        width: 120px;
      }
      .left {
        flex: 1;
        display: inline-flex;
      }
    }

    .left {
      flex: 1;
      display: inline-flex;
      flex-direction: column;
      vertical-align: top;
      justify-content: space-between;
      background: #2472D2;
      position: relative;

      & > .note-modal-link {
        text-decoration: underline;
        position: absolute;
        bottom: 0;
        right: 5px;
      }

      & > .note-modal-link:hover {
        font-weight: bold;
      }

      .top {
        position: relative;
        flex-shrink: 0;
        min-height: 20px;
        .name {
          padding-left: 5px;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          vertical-align: top;
          text-overflow: ellipsis;
          width: 100%;
        }
        .number {
          &.comfort {
            right: 0;
          }

          position: absolute;
          top: 0;
          padding-left: 5px;
          padding-right: 5px;
          background: #2472D2;
        }
      }

      .center {
        overflow: hidden;
        padding: 0 5px;
        font-style: italic;
      }

      .bottom {
        padding-left: 5px;
        flex-shrink: 0;
      }
    }
    .right {
      width: 120px;
      vertical-align: top;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #4FD224;

      .top {
        font-size: 12px;
        white-space: nowrap;
        &.name {
          align-self: flex-start;
        }
      }
    }
  }
  .multiple {
    .right {
      width: 50%;
      overflow: hidden;
      border-left: solid 1px;
    }
    .left {
      flex: 50;
      overflow: hidden;
      border-left: solid 1px;

    }
  }
  .time-slot {
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
    background: #cdcdcd;
    padding: 5px;

    &.reserved {
      background: #DDD9C3;
      cursor: auto;
      color: #333;
    }
  }
}

.calendar-doctor-dropdown-title {
  width: @calendar-dropdown-width - 35px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-toggle .calendar-doctor-dropdown-title {
  float: left;
}

.calendar-red-line {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background: red;
}

.context-menu-item {
  padding: 1rem;
  color: white;
  cursor: pointer;
  &:hover {
    background: gray;
  }
}
