@import "classes";

.chart-break-to-visit-body {
  .input-number-arrows-none;
  .hidden-scrollbar();
  overflow-y: auto;
  flex: 1;

  .payment {
    padding: 10px 10px 10px 110px;

    label {
      float: left;
      margin-left: -100px;
    }
  }

  .parts-count {
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;

    .form-control {
      width: 40px;
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px;
    }
  }

  .info {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    line-height: 23px;
  }

  .parts-item {
    margin: 10px;

    .meta {
      display: flex;
      justify-content: space-between;

      .fee {
        padding: 10px 10px 10px 50px;

        label {
          float: left;
          margin-left: -50px;
        }
      }

      .description {
        padding-top: 16px;
      }
    }
  }
}
