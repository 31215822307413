@import "variables";

.doctor-production-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 30px;

  .default-table {
    margin: 0 5px;
    flex: 1;

    .default-table-header {
      background: darken(@brand-primary, 20%);
      color: #fff;

      .default-table-col {
        line-height: normal;
        display: flex;
        align-items: center;
      }
    }

    .default-table-col {
      &.col-1 {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 8%;
      }
      &.col-2 {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 8%;
      }
      &.col-3 {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 8%;
      }
      &.col-4 {
        flex-shrink: 0;
        flex-grow: 1;
        flex-basis: auto;
      }
      &.col-5 {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 13%;
      }
      &.col-6 {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 15%;
      }
      &.col-7 {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 4%;
      }
      &.col-8 {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 6%;
      }
      &.col-9 {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 5%;
      }
      &.col-10 {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 4%;
      }
      &.col-11 {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 5%;
      }
      &.col-12 {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 5%;
      }
      &.col-13 {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 5%;
      }
    }
  }
}

.doctor-production-form {
  padding: 10px;
  background: @brand-primary;
  color: #000;
  margin-bottom: 30px;
  flex-shrink: 0;

  .btn.btn-back-doctor-production {
    color: #000;
    font-weight: bold;
    padding-top: 2px;
    font-size: 15px;
  }

  .form-group .react-datepicker__input-container input {
    width: 87px;
  }

  .Select {
    display: inline-block;
    vertical-align: inherit;
    width: 150px;
  }

  select.form-control {
    width: 150px;
  }

  .btn {
    color: darken(@brand-primary, 20%);
  }
}

