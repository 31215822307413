.header-logo {
  height: 34px;
  position: absolute;
  left: 50%;
  top: 5px;
  margin-left: -54px;
}

.waiting-list-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .requested-list-title {
    display: flex;
    height: 25px;

    & > div {
      background-color: #e6e6e6;
      flex: 1;
      padding: 3px;

      &:first-child {
        margin-right: 1px;
      }

      &:last-child {
        margin-left: 1px;
      }
    }
  }

  .requested-list-body {
    display: flex;
    flex: 1;

    & > div {
      flex: 1;
      overflow-y: scroll;
      overflow-x: hidden;
      .hidden-scrollbar();

      .requested-spinner {
        height: 40px;
        width: 100%;
        position: relative;
      }

      &:first-child {
        margin-right: 1px;
      }

      &:last-child {
        margin-left: 1px;
      }
    }

    .waiting-info {
      display: flex;
      padding: 10px 0;

      .waiting-info-find-openslot {
        text-align: right;
        padding: 10px;
      }

      .waiting-info-data {
        display: flex;
        flex: 1;

        & > .waiting-info-title {
          padding: 0 10px;
          flex: 0 0 110px;
          display: flex;
          justify-content: flex-end;
        }

        & > .waiting-info-value {
          flex: 150 1 auto;
          padding: 0 10px;
        }
      }

      .waiting-info-data-container {
        display: flex;
        flex-direction: row;

        .waiting-info-data-wraper {
          display: flex;
          flex: 1;
          flex-direction: column;
        }

        .waiting-info-delete {
          flex: 0 0 60px;
          background-position: top center;
          background-size: 50%;
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }
    }

    .requested-list-cell {
      flex: 1;
      height: 80px;
      display: flex;
      cursor: pointer;
      flex: 1;
      border-bottom: 1px solid #ddd;

      .member-image {
        height: 80px;
        width: 80px;
        background-size: 95%;
      }

      .requested-list-cell-data {
        padding: 5px 10px;
        display: flex;
        flex: 1;
        line-height: 14px;
        flex-direction: column;
        color: #fff;
        justify-content: space-between;

        & > span {
          height: 14px;
        }
      }

      &.waiting {
        background-color: #9966CB;
      }

      &.scheduled {
        background-color: #2ca72c;
      }
    }
  }

  .main-header {
    .missed-cancelled-list-date > div {
      float: right;
      padding-right: 10px;
      padding-top: 5px;
      width: 230px;
    }
  }
  .header {
    background-color: #e6e6e6;
    color: #555;
    height: 25px;
    line-height: 25px;
  }
  .missed-cancelled-lists-group {
    flex: 1;
    display: flex;
  }
  .waiting-list-header-wrapper {
    overflow-x: hidden;
    .hidden-scrollbar();
  }

  .waiting-list-header {
    display: flex;
    margin-top: 1px;
    .waiting-list-header-form {
      .dropdown-button-wrapper {
        width: 100%;
      }
      .dropdown {
        width: 100%;
        .dropdown-toggle {
          width: 100%;
        }
        .dropdown-menu {
          width: inherit;
        }
      }
    }
    &.header {
      width: 150%;
    }

    & > div {
      padding: 0 5px;
      flex: 33%;
      margin-left: -2px;
      &:first-child {
        flex: 34%;
      }
    }
    .form-inline {
      padding-right: 40px;
      height: 50px;
      flex: 1;
      text-align: right;

      .form-group {
        margin-right: 8px;
        label {
          margin-right: 3px;
          color: #bec844;
        }
      }
    }
  }
  .recall-list-header > div {
    margin-left: -1px;
  }
  .missed-list-header > div {
    margin-left: 0;
  }
  .waiting-list-body {
    flex: 1;
    overflow-y: scroll;
    overflow-x: scroll;
    .hidden-scrollbar();
    margin-bottom: 0;
    .waiting-list-row {
      width: 150%;
      clear: both;
      a {
        color: #fff;
        text-decoration: none;
        display: block;
        width: 34%;
        height: 71px;
        &.link-waiting {
          margin-left: 34%;
          width: 33%;
        }
        &.link-referral {
          width: 33%;
          margin-left: 67%;
        }
      }
      .waiting-list-cell-header {
        clear: both;
        & > div {
          padding: 0 5px;
          width: 33%;
          float: left;
          border-right: 1px solid #fff;
        }
        & > div:first-child {
          width: 34%;
        }
        & > div:last-child {
          border-right: none;
        }
      }
      .waiting-list-cell {
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        & :hover {
          cursor: pointer;
        }

        &.missed-cancelled-cell {
          position: relative;

          a {
            width: auto;
          }

          & > .missed-cancelled-cell-buttons {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 70px;
            right: 0;

            & > div {
              width: 50%;
              background-color: #000;
              background-position: center 15px;
              float: left;
              height: 70px;
              line-height: 96px;
              text-align: center;
              color: #fff;
              background-repeat: no-repeat;
              background-size: 23px;
            }

            & > .button-left {
              border-right: 1px solid transparent;
            }
          }
        }
        .waiting-list-cell-body {
          height: 70px;
          margin: 1px 0 0 1px;
          position: relative;
          width: 100%;
          display: inline-block;
          border-right: 1px solid #fff;
          &.waiting-list-requested {
            background-color: #ff6666;
          }
          &.waiting-list-referral {
            background-color: #9966CB;
          }
          &.waiting-list-waiting {
            background-color: #9966CB;
          }

          .member-container {
            height: 100%;
            background-color: #fff;
            width: 70px;
            .member-image {
              width: 70px;
              height: 100%;
              float: left;
              background-position: 50%;
              background-size: 100%;
              border-radius: 0;
              background-repeat: no-repeat;
              background-color: #fff;
            }
          }
          .waiting-list-name {
            margin-left: 10px;
            position: absolute;
            top: 0;
            left: 70px;
          }
          .waiting-list-doctor {
            position: absolute;
            bottom: 0;
            right: 0;
            margin-right: 10px;
          }
        }
        .waiting-list-cell-note {
          position: absolute;
          left: 70px;
          margin-left: 10px;
          bottom: 0;
          text-decoration: underline;
        }
      }
    }
    .recall-list-row {
      //width: 50%;
      a {
        width: 100%;
      }
      .recall-list-cell-header {
        & > div {
          width: 100%;
        }
      }
    }
  }
}

.small-waiting-list-appt-type {
  display: inline-block;

  button {
    margin-top: 0;
    width: 200px;
    margin-right: 10px;
  }

  .reminders-filter-select .calendar-doctor-dropdown-title {
    width: 100%;
  }
}

.missed-cancelled-table {
  tbody {
    display: block;
    height: 50%;
    overflow: auto;
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar
    {
      width: 7px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: #000000;
    }
  }

  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.missed-cancelled-table-cell {
  background-color: #ff6666;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ea5959 !important;
  }
}

/* ----------- Non-Retina Screens ----------- */

@media screen
and (min-device-width: 1200px)
and (max-device-width: 1600px)
and (-webkit-min-device-pixel-ratio: 1) {
  .waiting-list-page {
    .requested-list-body {
      zoom: 93%;
    }
  }
}

/* ----------- Retina Screens ----------- */
@media screen
and (min-device-width: 1200px)
and (max-device-width: 1600px)
and (-webkit-min-device-pixel-ratio: 2)
and (min-resolution: 192dpi) {
  .waiting-list-page {
    .requested-list-body {
      zoom: 93%;
    }
  }
}
