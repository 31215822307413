.follow-up-header {
  display: flex;
  padding-right: 5px;

  .follow-up-cell {
    border-left: 1px solid white;

    &:first-child {
      border-left: none;
    }
  }
}

.follow-up-cell {
  text-align: center;
  display: flex;
  align-items: center;

  & > div {
    flex: 1;
  }
}

.follow-up-cell-1 {
  flex: 0 0 70px;
}

.follow-up-cell-5 {
  flex: 0 0 100px;
  justify-content: center;
}

.follow-up-cell-2 {
  flex: 1;
}

.follow-up-cell-3 {
  flex: 0 0 20%;
}

.follow-up-cell-6 {
  flex: 0 0 10%;
}

.follow-up-cell-4 {
  flex: 0 0 450px;

  button + button {
    margin-left: 8px;
  }
}

.follow-up-cell-body {
  display: inline-flex !important;
  vertical-align: bottom;
}

.follow-up-row {
  width: 100% !important;
  background: rgb(238, 117, 107);
  color: #fff;

  .follow-up-cell-2 {
    text-align: left;
    padding-left: 20px;
  }

  &.follow-up-row-done {
    background: rgb(76, 217, 100);
    color: #000;
  }
}

.follow-up-side-bar {
  margin: 0;
  width: 40%;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background: #fff;
  display: flex;

  .modal-body {
    height: 100%;
    padding: 0;
  }

  .modal-content {
    box-shadow: none;
    border: none;
    border-radius: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .sidebar-header {
    background: @brand-primary;
    padding-top: 20px;
    padding-bottom: 10px;

    .title {
      text-align: center;
      color: #fff;
      line-height: 30px;
      margin-right: 75px;
      font-size: 18px;
    }
  }
}

.follow-up-side-bar-form {
  padding: 20px 40px;
  height: 100%;

  form {
    height: 100%;
  }
}

.follow-up-side-bar-form-buttons {
  display: flex;
  justify-content: space-around;

  .btn-block {
    flex: 1;
  }

  .btn-block + .btn-block {
    margin-top: 0;
    margin-left: 10px;
  }
}
.follow-type-select {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #bec844;
  float: left;
}

.follow-up-initial-note {
  border: 1px solid #ccc;
  margin-bottom: 1rem;
}

.follow-up-note-wrap {
  height: 85%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .follow-up-note-list {
    height: 70%;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 0.5rem;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000;
    }

    .follow-up-note {
      position: relative;
      padding: 0.5rem;
      margin-bottom: 1rem;
      background: #e8e8e8;
      border-radius: 5px;
      margin-right: 2rem;

      .corner {
        left: 0;
        bottom: -10px;
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: 14px solid #e8e8e8;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
      }

      .follow-up-note-info {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        margin-top: 0.5rem;
        color: #b1aeae;
      }
    }

    .follow-up-note.mine {
      background: #e8e8e8;
      margin-left: 2rem;
      margin-right: 0;

      .corner {
        right: 0;
        left: auto;
        border-left-color: transparent !important;
        border-right-color: #e8e8e8 !important;
      }

      .follow-up-note-info {
        flex-direction: row-reverse;
      }
    }
  }

  .follow-up-note-msg {
    height: 30%;
    display: flex;
    flex-direction: column;
    //align-items: flex-end;
    position: relative;

    textarea {
      width: 100%;
      height: 100%;
      resize: none;
      border: 1px solid #ccc;
      border-top: none;
      padding: 0.5rem;
    }

    //button {
    //  position: absolute;
    //  bottom: 0;
    //  right: 0;
    //}
  }
}
