@import "variables";
@import "classes";

@chartInfoHeight: 70px;
@chartInfoMarginBottom: 10px;

@surfaceHeight: 75px;
@toothImageHeight: 120px;
@numericHeight: 30px;
@toothTotalHeight: @surfaceHeight + @toothImageHeight + @numericHeight;

@chartCentralSeparatorHeight: 1px;

.custom-input-field {
  padding: 10px 5px !important;
}

.treatment-complete-modal {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  column-gap: 20px;

  .fluid-cell {
    max-width: 170px;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;

    .sort-picker {
      .popover-box {
        .custom-popover {
          left: -275px;
          margin: 0;

          & > .arrow {
            left: unset;
            right: -11px;
            transform: rotate(180deg);
          }

          & > .list {
            padding: 0;
            margin: 0;
            list-style-type: none !important;
          }
        }
      }
    }
  }
}

.docs-view-component {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  position: absolute;
  background-color: black;

  .docs-view-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #cccccc;

    .back-button {
      color: #ffffff;
    }
  }

  .docs-view-container {
    position: relative;

    .docs-view-embed {
      height: 100%;
    }

    .docs-view-footer {
      height: 35px;

      & > span {
        color: #ffffff;
      }
    }

    .docs-view-image {
      flex: 1;
      margin: 12px;
      background-size: contain;
      background-position: 50%;
      background-repeat: no-repeat;
    }

    .docs-view-button {
      top: 0;
      bottom: 0;
      cursor: pointer;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.3);
      transition: background-color 270ms ease-in-out;

      color: #ffffff;
      font-size: 35px;

      &.right-button {
        right: 0;
        width: 60px;
      }

      &.left-button {
        left: 0;
        width: 60px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }
}

.clinical-note-item-delete {
  width: 17px;
  height: 17px;
  background-size: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}

.chart-new-clinical-note-item {
  height: 70px;
  flex-shrink: 0;
  padding: 6px 12px !important;

  .clinical-note-item-copy {
    width: 15px;
    height: 15px;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .clinical-note-item-audit {
    width: 15px;
    height: 15px;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .clinical-note-item-delete {
    width: 17px;
    height: 17px;
    background-size: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  .clinical-note-item-subtitle {
    font-size: 12px;
  }
}

.chart-treatments-sidebar {
  & .chart-sidebar-search {
    padding: 10px;
    display: flex;
    flex-shrink: 0;
    background-color: #bbbbbb;
    position: relative;

    & .chart-sidebar-search-dropdown-wrapper{
      position: absolute;
      top: 41px;
      background: #f5f5f5;
      border-radius: 6px;
      width: 97%;
      overflow: hidden;
      z-index: 10000000;

      & > div{
        padding: 6px 12px;
        cursor: pointer;
        transition: 0.3s all;

        &:hover{
          background: #1aa2fe;
          color: #fffee0;
        }
      }


    }
    & > .sidebar-search-image-container {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    & > .sidebar-search-input {
      flex: 1;
      border: none;
      height: 28px;
      padding: 0 12px 0 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  & .chart-treatments-sidebar-back-button {
    padding: 0;
    border: none;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
  }

  & .chart-treatments-sidebar-title {
    padding: 10px;
    color: #777777;
    flex-shrink: 0;
    font-weight: bold;
    text-transform: uppercase;
  }

  & .sidebar-changed-content {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;

    & .list-group-item-category {
      color: #777;
      display: flex;
      padding: 10px;
      cursor: pointer;
      border-bottom: 1px solid #ddd;

      .name {
        flex: 1 1 0%;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    & .list-group-item {
      &.list-group-item-grid {
        flex-basis: 15.66%;
        margin-right: 1%;
        margin-bottom: 1%;
        border-radius: 4px;
        padding: 0 5px;
        overflow: hidden;
        position: relative;
        z-index: 1000000;

        .favourite-icon {
          padding: 15px;
          display: flex;
          justify-content: center;

          img {
            width: 100%;
          }
        }
        .code {
          height: 20px;
          margin: -5px 0 0;
          text-align: center;
          white-space: nowrap;
        }
        .drag-handle {
          position: absolute;
          right: 0;
          top: 0;
          height: 20px;
          line-height: 20px;
          width: 20px;
          text-align: center;
          background-color: #bbb;
        }
      }
    }

    & .list-group {
      padding-top: 6px;

      &.list-group-grid {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1% 0 0;
        align-content: flex-start;
        align-items: flex-start;
      }
    }

    & .sidebar-changed-content-actions {
      & > .btn {
        height: 40px;
        max-width: 45%;
      }
    }
  }

  & .chart-treatments-sidebar-groups {
    display: flex;
    padding: 0 15px;
    background-color: #bec844;

    & > .btn-group {
      flex: 1 1 0%;
      height: 36px;
      display: flex;
      margin-bottom: 15px;
      border-color: #ffffff;

      & > .btn {
        flex: 1 1 0%;
        font-size: 17px;
        border-color: #ffffff;

        &.btn-default {
          color: #bec844;
        }
      }
    }

    &.view-group {
      padding: 0;

      & > .btn-group {
        margin: 0;
      }
    }
  }
}

.fill-in-forms-list-group-container {
  max-height: 603px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  & > div {
    flex: 1 1 0%;
  }

  & > textarea {
  }
}

.teeth-row-root {
  margin: 12px 0;

  .values-root {
    margin-top: 12px;

    .titles-container {
      min-width: 40px;
      max-width: 40px;

      .titles {
        font-size: 20px;
      }
    }

    .values-container-first {
      position: relative;
      min-width: 1280px;
      max-width: 1280px;

      .values-container-second {
        border-top: 1px solid;
        border-left: 1px solid;
      }
    }
  }

  .line-title-container {
    min-width: 40px;
    max-width: 40px;
    position: relative;
    //height: 180px;


     .row-line-title-main {
       margin-top: 50px;
      //top: 30px;
      //left: -13px;
      color: #8181e4;
      font-size: 20px;
      //position: absolute;
      text-transform: uppercase;
      transform: rotate(-90deg);
    }
    .row-line-title{
      font-size: 10px;
      text-align: right;
    }
  }

  .row-background-container {
    position: relative;
    min-width: 1280px;
    max-width: 1280px;

    .row-background {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      display: block;
      padding-top: 30px;
      position: absolute;
    }

    .charts-container {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding-top: 28px;
      position: absolute;
    }
  }

  .perio-exam-values {
    width: 90px;
    font-size: 16px;
    margin-top: 10px;


    .value-container {
      padding: 6px;
      border-right: 1px solid;
      border-bottom: 1px solid;
      &:nth-child(1){
        border-top: 1px solid;
      }
      .exam-value {
        //padding: 0 1px;
        font-weight: bold;
      }
    }
  }
}

.perio-exam-chart-info {
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 8px !important;
  border-bottom: 1px solid #ccc;

  .back-button-container {
    left: 0;
    top: 24px;
    position: absolute;
  }
}

.perio-exam-header {
  height: 74px;

  & .app-layout-logo {
    background-color: #1aa2fe;
    margin-left: -104px !important;

    & .perio-exam-chart-info {
      margin-bottom: 0;
      border-bottom: none;
      padding: 4px 4px 0;

      & > a {
        color: white;

        &:hover {
          text-decoration: none !important;
        }
      }
    }
  }
}

.perio-exam {
  height: 100%;
  overflow: auto;

  .perio-exam-body {
    .data-list-container {
      width: 40%;
      padding: 0;
      border-right: 1px solid #ccc;

      & > div {
        flex-direction: column;
      }

      .perio-exam-data-list-item {
        height: 48px;
        padding: 12px;
        cursor: pointer;
        align-items: center;
        border-bottom: 1px solid #ccc;

        &:hover {
          background-color: #eeeeee;
        }

        .perio-exam-date {
          color: #969292;
          font-weight: bold;
        }
      }
    }
  }
}

.fill-in-form-answer {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  & > img.canvas-image {
    max-width: 100%;
  }
}

.select-treatment-to-complete-note-modal {
  .modal-content {
    background-color: #ccc;

    .modal-footer {
      padding: 0;
      display: flex;

      & > button {
        height: 40px;
        flex: 1;
        border-radius: 0;
        color: #00f;
        background-color: #ccc;
        margin: 0;

        &:first-child {
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-radius: 0;
          color: #ff2727;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  & .select-treatment-textarea {
    width: 100%;
    height: 160px;
    resize: none;
  }
}

.select-treatment-to-complete-modal {
  display: flex;
  flex-direction: column;

  .select-treatment-to-complete-modal-c-title {
    font-weight: bold;
    width: 25%;
    @media (max-width: 1400px) {
      width: 16%;
    }
  }
  & .modal-dialog {
    position: absolute;
    top: 100px;
    left: 10px;
    right: 10px;
    width: auto;
    bottom: 20px;
    display: flex;
    margin: 0;
  }

  & .modal-content {
    display: flex;
    flex: 1;
    flex-direction: column;

    & > .modal-body {
      padding-top: 0;
      flex: 1;
      display: flex;
      flex-direction: column;

      .select-treatment-to-complete-finish-mode {
        display: flex;
        flex-direction: column;
        flex: 1;

        & > .btn-group {
          margin-bottom: 10px;
        }
      }
    }
  }
}
.select-treatment-list-table {
  max-height: 75% !important;
  border-radius: 4px;
  border: 1px #999 solid;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  .hidden-scrollbar();

  & .select-treatment-list-row {
    cursor: pointer;
  }

  & > .select-treatment-list-header,
  & .select-treatment-list-row {
    display: flex;
    flex: 0 0 40px;
    border-bottom: 1px solid #999;

    & > .item-date-completed {
      flex: 0 0 120px;
    }

    & > .item-date-posted {
      flex: 0 0 100px;
    }

    & > .item-treatment-code {
      flex: 0 0 160px;
      text-align: center;
    }

    & > .item-code {
      flex: 0 0 60px;
    }

    & > .item-area {
      flex: 0 0 100px;
    }

    & > .item-tooth {
      flex: 0 0 60px;
      text-align: center;
    }

    & > .item-session {
      flex: 0 0 60px;
      text-align: center;
    }

    & > .item-description {
      flex: 1;
    }

    & > .item-fee {
      flex: 0 0 70px;
    }

    & > .item-principal-diagnosis {
      flex: 0 0 80px;
    }

    & > .item-posted-by {
      flex: 0 0 150px;
    }

    & > .item-approval {
      flex: 0 0 130px;
    }

    & > .item-expiry-date {
      flex: 0 0 100px;
    }

    & > .item-checkbox {
      flex: 0 0 50px;

      & > input[type="checkbox"] {
        margin-top: 14px;
      }
    }

    & > div {
      padding: 0 10px;
      height: 40px;
      overflow: hidden;
      line-height: 40px;
    }
  }

  & .select-treatment-list-row:last-child {
    border: none;
  }

  & .select-treatment-list-group-row {
    flex-shrink: 0;

    & > .group-title {
      background-color: #049dd8;
      color: #ffffff;
      user-select: none;
    }

    &:not(:first-child) > .group-title {
      border-top: 1px solid #999;
    }
  }

  & > .select-treatment-list-body {
    min-height: 10px;
    display: flex;
    flex: 1;
    //overflow-y: scroll;
    .hidden-scrollbar();
    flex-direction: column;
    //margin-right: -5px;
  }
}
.chart-balance-modal {
  .modal-dialog {
    width: 550px;
  }

  .modal-footer {
    text-align: center;
  }
}

.tooth {
  position: relative;
  .tooth-under {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
  }
  .tooth-above {
    position: relative;
  }
}

.teeth-view {
  position: relative;

  .up {
    .numeric {
      margin-top: 6px;
    }
  }

  .down {
    .numeric {
      margin-bottom: 6px;
    }
  }

  .up,
  .down {
    display: flex;

    .tooth {
      flex: 1;
      text-align: center;
      position: relative;
      //height: @toothTotalHeight;
      cursor: pointer;
      display: flex;
      border: 1px dashed transparent;
      &.active {
        border-color: inherit;
        background: #ddd;
      }
      .body,
      .condition {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
      }
      .body {
        .tooth-image {
          display: flex;
          justify-content: center;
          .tooth-under {
            position: absolute;
            text-align: center;
            left: 0;
            right: 0;
          }
          .tooth-above {
            position: relative;

            & > div {
              height: 100%;

              & > img {
                height: 100%;
              }
            }
          }
        }
      }
      .supernumerary {
        position: absolute;
        left: 0;
      }
      .tooth-image {
        position: relative;
        margin: 6px 0;

        &.condition-container {
          .fill-image,
          .tooth-image-condition,
          .tooth-image-condition-center {
            height: 100%;

            & > img {
              height: 100%;
            }
          }
        }
      }
      .surface-image {
        display: flex;
        margin: 6px 0;
        align-items: center;
        justify-content: center;
        position: relative;

        &.condition-container {
          .fill-image,
          .tooth-image-condition,
          .tooth-image-condition-center {
            height: 100%;

            & > img {
              height: 100%;
            }
          }
        }
      }
      .numeric {
        min-height: @numericHeight;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        color: #47454c;
      }
      .condition {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .condition-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        line-height: 0;
        &.tooth-image-condition {
          display: flex;
          justify-content: center;
        }
        &.tooth-image-condition-bottom {
          display: flex;
          justify-content: center;
        }
        &.tooth-image-condition-center {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .turn {
        margin-top: 10px;
      }
      .banding {
        position: relative;
        width: 100%;
        padding-top: 100%;
        .banding-wrap {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .circle {
          width: 100%;
          height: 100%;
          max-height: 65px;
          max-width: 65px;
          border: 2px solid;
          border-radius: 50%;
        }
      }
    }
  }
  .up {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      background: url(../images/chart/condition_center_arrow.png) 0 0 no-repeat;
      width: 65px;
      height: 65px;
      left: 50%;
      margin-left: -32px;
      bottom: -33px;
    }
    .tooth {
      .body,
      .condition {
        flex-direction: column;
      }

      .body {
        .tooth-image {
          align-items: flex-end;
          .tooth-under {
            bottom: 0;
          }
        }
      }

      .supernumerary {
        top: 0;
      }

      .condition-wrap {
        &.tooth-image-condition {
          align-items: flex-end;
        }
        &.tooth-image-condition-bottom {
          align-items: flex-start;
        }
      }
    }
  }
  .down {
    margin-top: 1px;
    .tooth {
      .body,
      .condition {
        flex-direction: column-reverse;
      }

      .body {
        .tooth-image {
          align-items: flex-start;
          .tooth-under {
            top: 0;
          }
        }
      }

      .supernumerary {
        bottom: 0;
      }

      .condition-wrap {
        &.tooth-image-condition {
          align-items: flex-start;
        }
        &.tooth-image-condition-bottom {
          align-items: flex-end;
        }
      }
    }
  }

  &.sidebar-teeth-view {
    &:before {
      left: 0;
      top: 130px;
    }

    & .tooth {
      display: flex;
      height: 160px;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;

      & > .tooth-image {
        display: flex;
        align-items: flex-end;
      }

      &.reverse {
        flex-direction: column-reverse;

        & > .tooth-image {
          align-items: flex-start;
        }
      }
    }
  }
}

.chart-page {
  .chart-info {
    display: flex;
    position: relative;
    min-height: 70px;
  .save-button{
    position: absolute;
    right: 320px;
  }
    .save-date{
      //position: absolute;
      //right: 10px;
      height: 45px;
      margin-bottom: 4px;
    }
  .chart-eligible-info {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-top: 1rem;
    &__eligible {
      color: #1bb34b;
      margin: 0;
    }
    &__not-eligible {
      color: #ff0000;
      margin: 0;
    }
  }
    .patients-details {
      height: @chartInfoHeight;
      display: flex;
      padding-right: 16px;
      width: auto;
      align-items: center;
      background: #1aa2fe 230px 40px no-repeat;
      color: #fff;
      cursor: pointer;

      &:hover,
      &:active {
        text-decoration: none;
      }

      .member-image {
        height: 50px;
        width: 50px;
        margin: 0 10px;
      }

      .name {
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .links {
      flex: 1;
      display: flex;
      align-items: flex-start;
      padding: 0 10px;
      .link {
        margin: 15px 5px 0;
        cursor: pointer;
        position: relative;
        .link-default();

        img {
          width: 37px;
        }
      }

      .divider {
        flex: 1;
      }

      .notifications-count {
        position: absolute;
        bottom: -2px;
        right: -6px;
        color: white;
        line-height: 16px;
        width: 16px;
        text-align: center;
        border-radius: 50%;
        font-size: 10px;
        background: #bfb800;
      }
    }

    .appointment-info {
      width: 165px;
      height: @chartInfoHeight;
      background: #009966;
      color: #fff;

      .time {
        line-height: 35px;
        text-align: right;
        padding-right: 10px;
        background: #096;
      }

      .clock {
        background: #096 130px 0 no-repeat;
        padding-right: 45px;
        text-align: right;
        font-size: 20px;
        line-height: 25px;
        padding-bottom: 10px;
      }
    }
  }

  .transaction-view {
    flex: 1 1 0%;
    @leftWidth: 20%;
    @leftBgColor: #ffffff;
    @btnHeight: 30px;
    height: 333px;
    min-height: 333px;
    display: flex;
    background: #e4e4e4;
    overflow: hidden;

    .toggle-btn {
      cursor: pointer;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 0;
      background: #b9fb94 no-repeat center;
      -webkit-background-size: 16px;
      background-size: 16px;
    }
    &.short-view {
      //top: auto;
      //height: 30%; /* top: 100%  TODO if you want to hide transactionBottom box, do change 70% t0 100% */
      // todo I think it must be fixed size
      top: @topClockHeight+ @chartInfoHeight+ @chartInfoMarginBottom+2* @toothTotalHeight+
        @chartCentralSeparatorHeight + 10px;
      .right-side {
        .transaction-header,
        .transaction-footer {
          display: none;
        }
      }
      .transaction-header,
      .transaction-footer {
        display: none;
      }
      .transaction-table {
        top: 0;
        bottom: 0;
      }
    }
    .list {
      &,
      li {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
    }
    .left-side {
      width: 200px;
      min-width: 200px;
      max-width: 200px;
      overflow-y: auto;
      background: #ffffff;
      .status-list {
        li {
          padding: 10px;
          color: #ffffff;
          margin-bottom: 1px;
          background: #bec945 no-repeat 80% center;
          -webkit-background-size: 28px;
          background-size: 28px;

          &.trx,
          &.products {
            background-image: none;
            &.selected {
              background: #959f25;
            }
          }
          &.selected {
            background: #869030 no-repeat 80% center;
            -webkit-background-size: 28px;
            background-size: 28px;
          }
        }
      }
    }
    .right-side {
      @top: 70px;
      @bottom: 65px;
      display: flex;
      padding: 10px 10px 0;
      flex-direction: column;
      width: auto;
      .transaction-header {
        padding-bottom: 10px;
        display: flex;
        justify-content: flex-end;

        .Select--multi {
          min-width: 140px;
        }

        .approval-filter {
          width: 140px;
        }

        .clinic-filter {
          width: 160px;
        }

        .tooth-filter {
          width: 140px;
        }
        .date-range-filter {
          width: 220px;
        }
        .filter-by-code {
          width: 120px;
        }
      }
      .transaction-table-wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        .transaction-table {
          @tableHeaderHeight: 50px;
          overflow: auto;
          position: relative;
          width: 100%;
          .table {
            font-weight: bold;
            width: auto;
            font-size: 12px;
            th,
            td {
              white-space: nowrap;
              padding: 10px;
              .custom-select-box {
                select.select-dropdown,
                input.selected-mask {
                  padding: 0;
                  background: none;
                  border: none;
                  font-size: 12px;
                  height: auto;
                  .set-box-shadow(none);
                  .set-border-radius(0);
                }
              }
            }
            thead {
              th {
                border-color: transparent;
              }
            }
            tbody {
              max-height: 100px;
              tr {
                background-color: #ffffff;
                cursor: pointer;

                &.status-planned,
                & > .status-planned {
                  background-color: #f9bfc2;
                  &.not-approved {
                    background-color: #ff5757;
                  }
                  &.type-diagnosis {
                    background-color: #fffee0;
                  }
                }
                &.status-cancelled,
                & > .status-cancelled {
                  background-color: #f9bfc2;
                  &.type-diagnosis {
                    background-color: #fffee0;
                  }
                }
                &.status-completed,
                & > .status-completed {
                  &.type-treatment {
                    background-color: #b9fb94;
                  }
                  background-color: #d9ebfd;
                }

                td {
                  span {
                    display: block;
                    max-width: 200px;
                    overflow-x: hidden;
                    overflow-y: visible;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
          .loader-box {
            position: relative;
          }
        }
      }
      .transaction-footer {
        width: 100%;
        padding-top: 10px;
        .btn {
          background: #bec844;
        }
      }
    }
  }
}

.chart-side-bar {
  margin: 0;
  width: 40%;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background: #fff;
  display: flex;
  box-shadow: -7px 0px 10px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: -7px 0px 10px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: -7px 0px 10px 0px rgba(0,0,0,0.75);

  &.bottom-bar {
    width: 100%;
    top: auto;
    bottom: 0;
    height: 300px;

    .clinical-note-textarea-name {
      input {
        height: 24px;
      }
    }

    .clinical-note-textarea-footer {
      display: flex;
      padding: 5px 0 0;
      .clinical-note-create-date {
        flex: 1;
      }
      button {
        width: auto;
      }
    }
  }

  &.full-screen {
    width: 100%;
  }

  .modal-content {
    box-shadow: none;
    border: none;
    border-radius: 0;
    flex: 1;
    display: flex;
  }

  .chart-series,
  .chart-clinical-note {
    flex: 1;
    display: flex;
    flex-direction: column;

    width: 100%;

    .checkbox-item {
      height: auto !important;
      margin: 0 !important;
    }
  }

  .chart-clinical-note-title {
    color: #fff;
    font-size: 16px;
    line-height: 30px;
    text-transform: uppercase;
  }

  .chart-clinical-note-select {
    padding: 12px 6px;
  }

  .chart-clinical-note-doctor-select {
    margin: 12px;

    & > .picker-container {
      flex: 1 1 0%;

      &:nth-child(1) {
        margin-right: 3px;
      }

      &:nth-child(2) {
        margin-left: 3px;
      }
    }

    .empty-container {
      width: 36px;
    }

    &.date-container {
      height: 40px;
      margin-top: 0;
      margin-bottom: 2px;

      & > div {
        flex: 1 1 0%;

        &.from-date {
          margin-right: 3px;
        }

        &.to-date {
          margin-left: 3px;
        }

        & > .react-datepicker__input-container {
          flex: 1 1 0%;
        }

        &.refresh-button {
          flex: none;
          cursor: pointer;
          margin-top: 2px;
          margin-left: 6px;
          min-width: 28.8px;

          &.disabled {
            cursor: auto;
          }
        }
      }
    }
  }

  .chart-clinical-note-item {
    text-align: left;
    line-height: 14px;
    flex-shrink: 0;

    .meta {
      font-size: 12px;
      font-weight: bold;
      color: #333;
    }

    padding-right: 40px;

    .favourite {
      position: relative;
      margin-right: -40px;
      float: right;
      padding: 0 10px;
      height: 56px;
      display: flex;
      align-items: center;
      padding-top: 15px;

      & > .delete {
        position: absolute;
        top: 0;
        right: 10px;
        width: 17px;
        height: 17px;
        background-position: 50%;
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
    &:hover {
      background: #fff;
    }
  }

  .chart-clinical-note-category-item {
    border: 0;
    color: #888;
    border-top: 1px solid #ccc;
    border-radius: 0;
    text-align: left;
    line-height: 30px;
    background-size: 15px 20px;
    white-space: normal;
    &:last-child {
      border-bottom: 1px solid #ccc;
    }

    & + .chart-clinical-note-category-item {
      margin-top: -1px;
    }
  }

  .chart-clinical-note-category-title {
    border: 0;
    color: #888;
    border-bottom: 1px solid;
    border-radius: 0;
    line-height: 30px;
    text-transform: uppercase;
    padding-left: 12px;
  }

  .chart-side-header {
    background: @brand-primary;
    padding-top: 20px;
    padding-bottom: 10px;
    flex-shrink: 0;

    .btn-primary,
    .btn-default {
      border-color: #fff;
    }

    .btn-link {
      color: #fff;
    }

    .btn-default,
    select {
      color: @brand-primary;
      background: #fff;
    }

    select {
      border-color: #fff;
      box-shadow: none;
    }

    .chart-side-header-type {
      margin-top: 15px;
      display: flex;
      button {
        flex: 1;
        font-size: 17px;
        line-height: 1.1;
      }
    }
  }

  .chart-side-body {
    flex: 1;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;

    .title {
      text-transform: uppercase;
      color: #777;
      font-weight: bold;
      padding: 10px;
    }
    .list-group-item-category-title {
      border-bottom: 1px solid #ddd;
      padding-left: 15px;
    }

    .list-group-item-grid-category-title {
      padding-left: 15px;
    }

    .body {
      flex: 1 0 0;
      display: flex;
      flex-direction: column;

      & > .list-group-item {
        margin: 6px;
        width: auto;
        display: flex;
        flex-shrink: 0;

        & > .separator {
          flex: 1;
          margin: -10px 0;
          line-height: 50px;

          & > .description {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        & > .favourite {
          flex: 0 0 50px;
          text-align: center;
          height: 50px;
          line-height: 50px;
          margin: -10px -15px -10px 0;
        }
      }

      .list-group {
        margin-bottom: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        padding-left: 5px;
        .hidden-scrollbar();

        .list-group-item-category {
          border-bottom: 1px solid #ddd;
          display: flex;
          cursor: pointer;
          color: #777;
          padding: 10px;

          .name {
            flex: 1;
            text-transform: uppercase;
            font-weight: bold;
          }
        }
      }
    }

    & > .group-buttons {
      display: flex;

      & > button {
        margin: 5px;
        line-height: 1.75;
      }
    }
    .all-codes {
      padding: 10px 10px 15px;
      button {
        font-size: 17px;
        line-height: 1.75;
      }

      &.all-codes-group {
        & > button {
          &:first-child {
            margin-right: 2%;
          }
        }
      }
    }

    &.item-details {
      padding: 10px;
      .calculation-box {
        background: #efefef;
        border: 1px solid #cecece;
        padding: 10px;
        font-size: 14px;
        color: #808080;
        .set-border-radius(3px);
        .item-info-box {
          position: relative;
          .title {
            font-size: 14px;
            font-weight: bold;
            margin: 0 0 5px;
            padding: 0;
          }
          .ellipsis-mode {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .description {
            margin-bottom: 0;
            padding-bottom: 15px;
          }
          .full-btn {
            font-size: 11px;
            display: block;
            padding: 2px 0;
            text-align: right;
            position: absolute;
            bottom: 0;
            right: 0;
            color: #bec844;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .label {
          margin-right: 10px;
        }
        .quantity-wrap {
          display: inline-block;
          margin: 1px 0 0;
          .input-box {
            margin-right: 10px;
          }
        }
        .unit-wrap {
          display: inline-block;
          margin: 1px 0 0;
        }
        .input-box {
          display: inline-block;
          margin-left: 30px;
          .btn-direction {
            width: 40px;
            display: inline-block;
            background-color: transparent;
            border: 1px solid #999999;
            .set-border-radius(50%);
            &.up {
              -webkit-background-size: 16px;
              background-size: 16px;
            }
            &.down {
              -webkit-background-size: 16px;
              background-size: 16px;
            }
          }
          .number-field {
            display: inline-block;
            width: 60px;
            text-align: center;
            margin: 0 10px;
          }
        }
        .price-box {
          .dropdown {
            & > .dropdown-menu {
              padding: 8px;
            }
            .dropdown-toggle {
              text-align: left;
              display: block;
              min-height: 32px;
              .caret {
                float: right;
                margin-top: 8px;
              }
            }
            .dropdown-menu {
              width: 100%;
              .price {
                float: right;
                margin-right: 15px;
              }
            }
          }
        }
      }
      .shape-wrap {
        padding: 25px 0;
        color: #7d7d7d;
        text-align: center;
        .title {
          text-transform: none;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          margin: 0 0 15px;
          padding: 0;
        }
        .slider-wrap {
          min-width: 0;

          &.surface-slider {
            display: inline-block;
            margin: 0 auto;

            .slick-slide {
              & > div {
                & > div {
                  display: flex !important;
                  flex-wrap: wrap;
                }
              }
            }
          }

          .tooth {
            padding: 5px;
            &.selected {
              background: rgba(0, 0, 0, 0.1);
              border: 1px dashed rgba(0, 0, 0, 0.4);
            }
          }
        }

        .tooth-image {
          text-align: center;
          min-height: 120px;
          img {
            display: inline-block;
            max-width: 100%;
          }
        }
        .numeric {
          text-align: center;
        }
      }
      .diagnosis-wrap {
        .panel-default {
          overflow-y: auto;

          .hidden-scrollbar();
        }

        .list-group-item {
          padding: 0;
          &.selected {
            -webkit-background-size: 16px;
            background-size: 16px;
            .select-btn {
              padding-left: 30px;
            }
          }
          .select-btn {
            float: left;
            min-height: 40px;
            width: 80%;
            padding: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            border: none;
            background: transparent;
          }
          .edit-btn {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 20%;
            border: 0;
            .set-border-radius(0);
            -webkit-background-size: 32px;
            background-size: 32px;
          }
        }
      }
    }
  }
}

.chart-clinical-note-wrapper {
  overflow-y: auto;
  .slim-scrollbar();
  padding-left: 5px;
}

.chart-side-body-wrapper {
  .slim-scrollbar();
  overflow-y: auto;
}

.list-group-item-code {
  display: flex;
  padding: 0;
  margin-bottom: 10px;
  border-radius: 4px;
  position: relative;
  z-index: 1000000;
  cursor: pointer;
  height: 62px;

  .code {
    flex-basis: 80px;
    flex-shrink: 0;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
  }
  .drag-handle {
    border-left: 1px solid #ddd;
    flex-basis: 15px;
    flex-shrink: 0;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
  .separator {
    flex-grow: 1;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    display: flex;
    align-items: center;
    height: 60px;
  }
  .description {
    padding: 0 10px;
    overflow: hidden;
    max-height: 60px;
  }
  .favourite {
    padding: 0 10px;
    flex-basis: 40px;
    flex-shrink: 0;
    min-height: 60px;
    display: flex;
    align-items: center;
  }
}

.chart-side-body-search {
  padding: 10px;
  background: #bbb;
  //margin-bottom: 10px;
  flex-shrink: 0;

  .input-group-addon {
    background: white;
    padding: 3px 6px;
  }
  .form-control {
    border-left: 0;
    height: 28px;
    padding: 3px 12px;
  }
}

.list-group {
  &.list-group-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1% 0 0;
  }
}

.list-group-item.list-group-item-grid {
  flex-basis: 15.66%;
  margin-right: 1%;
  margin-bottom: 1%;
  border-radius: 4px;
  padding: 0 5px;
  overflow: hidden;
  position: relative;
  z-index: 1000000;

  .favourite-icon {
    img {
      width: 100%;
    }
  }
  .code {
    height: 20px;
    margin: -5px 0 0;
    text-align: center;
    white-space: nowrap;
  }
  .drag-handle {
    position: absolute;
    right: 0;
    top: 0;
    height: 20px;
    line-height: 20px;
    width: 20px;
    text-align: center;
    background-color: #bbb;
  }
}

.chart-tooltip-backdrop {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
}

.chart-tooltip-modal-backdrop {
  background: none !important;
}

.chart-tooltip-modal {
  width: 100%;
  height: 100%;
  background: none;
  margin: 0;
}

.chart-tooltip {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  background: none;

  & > .modal-body {
    padding: 0;
  }
}

.chart-tooltip-body {
  position: absolute;
  background: #fff;
  width: 250px;
  transition: 0.3s opacity;
  text-align: center;
  .chart-tooltip-title-text {
    line-height: 26px;
  }
  .list-group {
    margin-bottom: 0;
    button.list-group-item {
      text-align: center;
    }
  }
}

body.chart-tooltip-open {
  padding-right: 0 !important;
}

.select-treatment-modal {
  width: 80%;
  .close {
    position: absolute;
    top: 10px;
    font-size: 20px;
    right: 10px;
    background: none;
    border: none;
  }
  .modal-header {
    border: none;
  }
  .modal-body {
    border: 1px solid #cccccc;
    padding: 0;
    margin: 10px;
    max-height: 510px;
    height: 500px;
    overflow: auto;
    .table {
      th {
        white-space: nowrap;
        line-height: 1;
        vertical-align: middle;
      }
    }
  }
  .modal-footer {
    border: none;
    .btn {
      margin-left: 10px;
    }
  }
}

.chart-alerts-modal {
  .modal-content {
    background: red;
    color: white;
  }

  .modal-body-content {
    border-radius: 4px;
    background: white;
    margin: 15px;
    padding: 0 10px;
    color: #333;
    height: 150px;
    overflow-y: auto;
    .hidden-scrollbar();
  }

  .chart-alerts-title {
    display: flex;
    margin: 0 15px;
    justify-content: space-between;
  }

  .chart-alerts-item {
    line-height: 30px;

    & + .chart-alerts-item {
      border-top: 1px solid #ddd;
    }
  }

  .close {
    text-shadow: none;
    opacity: 1;
    color: #fff;
  }

  .modal-header {
    border: none;
  }
}

.chart-finish-modal {
  position: absolute;
  top: 100px !important;
  left: 10px !important;
  right: 10px !important;
  width: auto !important;
  bottom: 20px !important;
  display: flex;
  margin: 0;

  .modal-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .modal-header {
      border-bottom: none;
      flex-shrink: 0;
    }

    .modal-body {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .modal-footer {
      flex-shrink: 0;
      border-top: none;
    }
  }
}

.chart-bottom-menu {
  .dropzone {
    width: auto;
    height: auto;
    border: none;
  }
}

.chart-transaction-eye {
  float: right;
  cursor: pointer;
}

.chat-docs-download {
  img {
    vertical-align: top;
    width: 62px;
  }
}

.chat-docs-download-small {
  img {
    vertical-align: top;
    width: 31px;
  }
}
#patient-history-modal {
  .modal-body {
    height: 80vh;
    #_iframe {
      width: 100%;
      height: 100%;
    }
  }
}
.claims-filter-field {
  .react-datepicker__input-container {
    width: 100%;
  }
  .dropdown-button-wrapper {
    width: 100%;
  }
}
.claims-approvals-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.claims-approvals-cell-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chart-side-bar {
  .modal-body {
    padding: 0;
  }
}

.order-sidebar {
  width: 75% !important;
}

.order-pdf-view {
  flex: 1;
  display: flex;
  position: relative;

  & > span {
    z-index: -99999999;
    position: absolute;
    width: 100%;
    height: 100%;

    & > div {
      z-index: -99999999 !important;
    }
  }

  & > embed {
    flex: 1;
  }
}

.ConditionCrown-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: 0;
  img {
    height: 100%;
  }
}
.up .tooth-image-ConditionCrown-bottom {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  img {
    height: unset;
  }
}
.down .tooth-image-ConditionCrown-bottom {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  img {
    height: unset;
  }
}

.tooth-image-ConditionCrown-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooth-image-ConditionCrown {
  display: flex;
  justify-content: center;
}

.select-treatment-to-complete-modal-body {

  &__left {
    position: relative;
    display: flex;
    flex: 0 1 330px;
    flex-direction: row;
    margin-right: 12px;
    transition: all 0.5s ease-out;

    .menu-toggler {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      right: -20px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      background: #bec844;
      cursor: pointer;
    }
  }

  &__right {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }
}

.select-treatment-to-complete-patient-info {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all .5s ease-out;
  border-right: 1px solid #ccc;
  padding-left: 12px;
  //padding-right: 12px;
}

.transaction-chart-clinic-filter {
  width: 80% !important;
  padding-left: 12px;
}

.form-horizontal-doctors-select {
  width: 100%;

  .dropdown-button-wrapper {
    width: 80%;
    padding-left: 12px;
  }

  .select-picker__control {
    width: 80%;
  }
}

.select-treatment-to-complete-modal-body.menu-visible {
  .select-treatment-to-complete-modal-body__left {
    flex: 0 1;
    transition: all 0.4s ease-in-out;

    .select-treatment-to-complete-patient-info {
      transform: translateX(-330px);
      transition: all 0.3s ease-in-out;
    }
  }
}

.treatment-finish-mode {
  max-height: 57% !important;
}


.vital-comment-filed {
  &:focus {
    border-color: #ccc !important;
  }
}
