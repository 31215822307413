@import "variables";

.chart-dermatology {

}

.chart-dermatology-add {
  position: relative;
  margin-top: -15px;
}

.chart-dermatology-choose {
  display: flex;
  justify-content: center;

  .left-side {

    .area {
      text-align: right;
    }
  }

  .image {

  }

  .right-side {

  }

  .area {
    width: 130px;
    font-size: 20px;
    line-height: 40px;
    padding: 0 10px;
    border: 1px dashed transparent;
    cursor: pointer;

    &.active {
      border-color: #333;
      color: #a8b324;
      background: #ddd;

    }
  }

  .area-9 {
    margin-top: 20px;
  }
  .area-12 {
    margin-top: 15px;
  }
  .area-15 {
    margin-top: 40px;
  }
  .area-5 {
    margin-top: 60px;
  }
  .area-7 {
    margin-top: 20px;
  }
  .area-8 {
    margin-top: 40px;
  }
}