.app-layout {
  flex: 1 1 0%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > .app-layout-header {
    z-index: 10;
    display: flex;
    min-height: 55px;
    padding: 10px 15px;
    position: relative;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid #bbbbbb;

    & > .app-layout-back-button {
      padding: 6px 0;
      text-decoration: none;
    }

    & > .app-layout-right-component {
      flex: 1 1 0%;
      display: flex;
      justify-content: flex-end;
    }

    & > .app-layout-logo {
      left: 50%;
      position: absolute;
      margin-left: -54px;
    }
  }

  & > .app-layout-content {
    flex: 1 1 0%;
    display: flex;
    position: relative;
    flex-direction: column;

    & > .app-layout-background-mask {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.5);
    }

    & > .app-layout-background {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      position: absolute;
      background-size: 100% auto;
      background-repeat: no-repeat;
    }

    & > .app-layout-data {
      z-index: 2;
      flex: 1 1 0%;
      display: flex;
      flex-direction: column;
    }

    &.app-layout-scrollable {
      overflow-y: auto;
      overflow-x: hidden;

      .hidden-scrollbar();
    }
  }
}
