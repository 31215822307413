.late-appointment-info-container {
  display: flex;
  flex-shrink: 0;
  margin-bottom: 12px;
  flex-direction: column;

  & > .late-appointment-info-row {
    display: flex;

    & > .late-appointment-info-cell {
      flex: 1 1 0%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > .late-appointment-info-count {
        margin-left: 6px;
        font-weight: bold;
      }
    }
  }
}

.late-appointment-cell {
  display: flex;
  flex: 1 0 100px;
  padding: 0 12px;

  & .Select {
    width: 100%;

    & Select-menu-outer {
      z-index: 100;
    }
  }

  &.late-appointment-header-cell {
    font-weight: bold;
  }

  &.late-appointment-id-cell {
    flex: 0 0 50px;
  }

  &.late-appointment-time-cell {
    flex: 0 0 80px;
  }
}

.late-appointment-table-header {
  height: 50px;
  display: flex;
  flex-shrink: 0;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  align-items: center;
}

.late-appointment-table-body {
  flex: 1 1 0%;
  display: flex;
  overflow-y: auto;
  flex-direction: column;

  .hidden-scrollbar();

  & > .late-appointment-table-row {
    height: 50px;
    display: flex;
    flex-shrink: 0;
    border-top: 1px solid #dee2e6;
    align-items: center;

    &:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}