.rc-time-picker {
  display: inline-block;
  box-sizing: border-box;
}
.rc-time-picker * {
  box-sizing: border-box;
}
.blocked-doctors-select {
  width: 100%;
}
.rc-time-picker-input {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  height: 28px;
  cursor: text;
  font-size: 12px;
  line-height: 1.5;
  color: #666;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.rc-time-picker-input[disabled] {
  color: #ccc;
  background: #f7f7f7;
  cursor: not-allowed;
}
.rc-time-picker-panel {
  z-index: 10050;
  width: 170px;
  position: absolute;
  box-sizing: border-box;
}
.rc-time-picker-panel * {
  box-sizing: border-box;
}
.rc-time-picker-panel-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px #ccc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  line-height: 1.5;
}
.rc-time-picker-panel-narrow {
  max-width: 113px;
}
.rc-time-picker-panel-input {
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: auto;
  line-height: 1.5;
  outline: 0;
  border: 1px solid transparent;
}
.rc-time-picker-panel-input-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 6px;
  border-bottom: 1px solid #e9e9e9;
}
.rc-time-picker-panel-input-invalid {
  border-color: red;
}
.rc-time-picker-panel-clear-btn {
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 6px;
  margin: 0;
}
.rc-time-picker-panel-clear-btn-icon:after {
  content: "x";
  font-size: 12px;
  font-style: normal;
  color: #aaa;
  display: inline-block;
  line-height: 1;
  width: 20px;
  transition: color 0.3s ease;
}
.rc-time-picker-panel-clear-btn-icon:hover:after {
  color: #666;
}
.rc-time-picker-panel-select {
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  max-height: 144px;
  overflow-y: auto;
  position: relative;
}
.rc-time-picker-panel-select-active {
  overflow-y: auto;
}
.rc-time-picker-panel-select:first-child {
  border-left: 0;
  margin-left: 0;
}
.rc-time-picker-panel-select:last-child {
  border-right: 0;
}
.rc-time-picker-panel-select ul {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
}
.rc-time-picker-panel-select li {
  list-style: none;
  box-sizing: content-box;
  margin: 0;
  padding: 0 0 0 16px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rc-time-picker-panel-select li:hover {
  background: #edfaff;
}
li.rc-time-picker-panel-select-option-selected {
  background: #f7f7f7;
  font-weight: bold;
}
li.rc-time-picker-panel-select-option-disabled {
  color: #ccc;
}
li.rc-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}

.blocked-date {
  & > div {
    width: 100% !important;

    & > input {
      width: 100% !important;
    }
  }
}

.blocked-time-slot {
  display: flex;
  flex-direction: column;
  color: #58666e;

  .b-b {
    border-bottom: 1px solid #dee5e7;
  }

  .blocked-timeslot-form {
    .form-group {
      & > * {
        margin: 0 6px;
      }

      & > .form-control {
        width: 150px;
      }
    }
  }

  .blocked-time-slot-header {
    margin-top: 0;

    & > img {
      width: 120px;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
    }
  }

  .blocked-time-slot-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #f6f8f8;
    .blocked-time-slot-panel-content {
      border-right: 1px solid #dee5e7;
      border-left: 1px solid #dee5e7;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      &:last-child {
        border-bottom: 1px solid #dee5e7;
        margin-bottom: 20px;
      }
    }

    .blocked-time-slot-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 20px;

      .blocked-time-slot-panel {
        //border: 1px solid #dee5e7;
        //background-color: #fff;

        & > .blocked-time-slot-table {
          display: flex;
          flex-direction: column;
          flex: 1;

          & .col-name {
            flex: 0 0 100px;
          }

          & .col-doctors {
            flex: 1;
          }

          & .col-reason {
            flex: 0 0 135px;
          }
          & .col-week-days {
            flex: 0 0 130px;
          }

          & .col-time {
            flex: 0 0 170px;
          }

          & .col-date {
            flex: 0 0 170px;
          }

          & .col-action {
            flex: 0 0 120px;
          }

          & > .blocked-time-slot-table-header {
            height: 37px;
            line-height: 37px;
            font-weight: bold;
            display: flex;
            flex-direction: row;
            flex-shrink: 0;

            & > div {
              padding: 0 15px;
            }
          }

          & > .blocked-time-slot-table-body {
            flex: 1;
            overflow-y: scroll;
            overflow-x: hidden;
            .hidden-scrollbar();
            margin-right: -5px;

            & > .blocked-time-slot-table-body-row {
              display: flex;
              flex-direction: row;
              min-height: 50px;
              align-items: center;

              & > div {
                padding: 0 15px;
                overflow: hidden;
              }

              & .btn {
                width: 44px;
                height: 34px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 40%;
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            & > .blocked-time-slot-table-body-row:nth-child(odd) {
              background-color: #FAFBFC;
            }

            & > .blocked-time-slot-table-body-row:not(:last-child) {
              border-bottom: 1px solid #f6f8f8;
            }
          }
        }

        & > .blocked-time-slot-panel-header {
          padding: 15px 0;
          margin: 0;
          flex-shrink: 0;

          & .input-group {
            width: 100%;
            z-index: 0;

            & > .input-group-btn {
              z-index: 0;
              width: auto;
            }
          }

          .blocked-time-slot-list-reload {
            width: 19px;
          }
        }

        &.blocked-time-slot-main-panel {
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        &.blocked-time-slot-add-edit-panel {
          margin: 0 auto;
          width: 800px;

          & > .blocked-time-slot-panel-header {
            padding: 10px 15px;
            background-color: #edf1f2;
            font-weight: bold;
            color: #333;
          }

          & > .blocked-time-slot-panel-body {

            .blocked-buttons {
              margin-bottom: 15px;

              & > button:first-child {
                margin-right: 5px;
              }
            }

            .form-group {
              margin-top: 10px;
              margin-bottom: 0;

              .col-sm-4 {
                height: 34px;
                line-height: 34px;

                .display-time {
                  height: 20px;
                  line-height: 20px;
                }

                &.blocked-date {
                  & > div {
                    width: 100%;

                    & > input {
                      width: 100%;
                    }
                  }
                }
              }
            }

            .dropdown {
              width: 100%;


            }
          }
        }
      }
    }
  }
}

.dropdown-doctor-text.active:after {
  content: '\2713';
  position: absolute;
  right: 10px;
}

.dropdown-toggle {
  & > .calendar-doctor-dropdown-title {
    text-align: left;
  }
}

.calendar-doctor-dropdown-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.blocked-time-slot-panel-header {

  .dropdown-doctor-text.active:after {
    content: '\2713';
    position: absolute;
    right: 2px;
  }

  .calendar-doctor-dropdown-title {
    width: 120px;
  }

  .react-datepicker__input-container {
    & input {
      width: 120px !important;
    }
  }
}
