.open-external-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 12px;

  & > button:not(:first-child) {
    margin-left: 6px;
  }
}

.modal-backdrop.show {
  background-color: rgba(0, 0, 0, 0.5);
}

.sidebar-modal {
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  width: 650px;
  display: flex;
  position: absolute;
  flex-direction: column;

  & > .modal-content {
    flex: 1 1 0%;
    border: none;
    display: flex;
    border-radius: 0;
    flex-direction: column;

    .procedure-category-item {
      border: none;
      outline: none;
      display: flex;
      flex-shrink: 0;
      background: none;
      height: 40px;
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 1px solid #ccc;

      & > span:first-child {
        flex: 1 1 0%;
        display: flex;
      }

      &:focus {
        outline: none;
      }

      &.item {
        font-weight: normal;
      }

      &.title {
        padding: 4px;
        height: 30px;
        font-size: 12px;
        align-items: center;
      }
    }

    .procedure-category-list {
      display: flex;
      margin-bottom: 4px;
      flex-direction: column;

      & > button {
        flex-shrink: 0;
      }
    }

    .item-details {
      padding: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      .calculation-box {
        background: #efefef;
        border: 1px solid #cecece;
        padding: 10px;
        font-size: 14px;
        color: #808080;
        .set-border-radius(3px);
        .item-info-box {
          position: relative;
          .title {
            font-size: 14px;
            font-weight: bold;
            margin: 0 0 5px;
            padding: 0;
          }
          .ellipsis-mode {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .description {
            margin-bottom: 0;
            padding-bottom: 15px;
          }
          .full-btn {
            font-size: 11px;
            display: block;
            padding: 2px 0;
            text-align: right;
            position: absolute;
            bottom: 0;
            right: 0;
            color: #bec844;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .label {
          margin-right: 10px;
        }
        .quantity-wrap {
          display: inline-block;
          margin: 1px 0 0;
          .input-box {
            margin-right: 10px;
          }
        }
        .unit-wrap {
          display: inline-block;
          margin: 1px 0 0;
        }
        .input-box {
          display: inline-block;
          margin-left: 30px;
          .btn-direction {
            width: 40px;
            display: inline-block;
            background-color: transparent;
            border: 1px solid #999999;
            .set-border-radius(50%);
            &.up {
              -webkit-background-size: 16px;
              background-size: 16px;
            }
            &.down {
              -webkit-background-size: 16px;
              background-size: 16px;
            }
          }
          .number-field {
            display: inline-block;
            width: 60px;
            text-align: center;
            margin: 0 10px;
          }
        }
        .price-box {
          .dropdown {
            display: flex;
            flex-direction: column;

            &.show {
              display: flex !important;
            }

            & > .dropdown-menu {
              padding: 8px;

              & > .dropdown-item {
                cursor: pointer;
              }

              & > .dropdown-divider {
                height: 1px;
                margin: .5rem 0;
                overflow: hidden;
                border-top: 1px solid rgba(0,0,0,.15);
              }
            }
            .dropdown-toggle {
              text-align: center;
              background-color: white;
              display: block;
              color: black;
              min-height: 32px;
              .caret {
                float: right;
                margin-top: 8px;
              }
            }
            .dropdown-menu {
              width: 100%;
              .price {
                float: right;
                margin-right: 15px;
              }
            }
          }
        }
      }
      .shape-wrap {
        padding: 25px 0 0;
        color: #7d7d7d;
        text-align: center;
        .title {
          text-transform: none;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          margin: 0 0 15px;
          padding: 0;
        }
        .slider-wrap {
          min-width: 0;
          &.surface-slider {
            width: 100%;
            display: inline-block;
            margin: 0 auto;

            .slick-slide {
              & > div {
                & > div {
                  display: flex !important;
                  flex-wrap: wrap;
                }
              }
            }
          }
          .tooth {
            padding: 5px;
            &.selected {
              background: rgba(0, 0, 0, 0.1);
              border: 1px dashed rgba(0, 0, 0, 0.4);
            }
          }
        }

        .tooth-image {
          text-align: center;
          min-height: 120px;
          img {
            display: inline-block;
            max-width: 100%;
          }
        }
        .numeric {
          text-align: center;
        }
      }
      .diagnosis-wrap {
        .panel-default {
          overflow-y: auto;
          overflow-x: hidden;

          .hidden-scrollbar();
        }

        ul.list-group {
          padding: 0;
        }
        .list-group-item {
          padding: 0;
          &.selected {
            -webkit-background-size: 16px;
            background-size: 16px;
            .select-btn {
              padding-left: 30px;
            }
          }
          .select-btn {
            float: left;
            min-height: 40px;
            width: 80%;
            padding: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            border: none;
            background: transparent;
          }
          .edit-btn {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 20%;
            border: 0;
            .set-border-radius(0);
            -webkit-background-size: 32px;
            background-size: 32px;
          }
        }
      }
    }

    & .sidebar-modal-header {
      display: flex;
      flex-shrink: 0;
      padding: 15px 0;
      min-height: 50px;
      background-color: #bec844;

      & > .sidebar-header-left-component {
        width: 100px;
        display: flex;
        margin-left: 15px;
        align-items: center;
      }

      & > .sidebar-header-component {
        flex: 1 1 0%;
        margin: 0 12px;

        &.sidebar-header-tooth {
          display: flex;
          align-items: center;
          justify-content: center;

          & > .form-control {
            width: 150px;
          }
        }
      }

      & > .sidebar-header-right-component {
        width: 100px;
        display: flex;
        margin-right: 15px;
        align-items: center;
        justify-content: flex-end;
      }
    }

    & .sidebar-modal-body {
      flex: 1 1 0%;
      display: flex;
      flex-direction: column;
    }
  }
}

.switcher {
  display: inline-block;
  > div {
    position: relative;
    vertical-align: middle;
    width: 54px;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 999px;
    background-color: rgba(0, 0, 0, 0.01);
    box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0.4);
    > div {
      position: absolute;
      left: 0;
      width: 30px;
      height: 30px;
      border-radius: inherit;
      background: #ffffff;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3),
        0px 0px 0 1px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    }
  }
  &.state_click > div {
    background: #13bf11 0 0;
    border: 1px solid rgba(0, 162, 63, 1);
    box-shadow: inset 0 0 0 10px #13bf11;
    > div {
      left: auto;
      right: 0;
      background-color: #ffffff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 162, 63, 1),
        0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    }
  }
}

.popover-box {
  .popover-bg {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    display: none;
  }
  .custom-popover {
    font-size: 12px;
    left: 94%;
    top: 0;
    width: 300px;
    z-index: 1000;
    .set-transition();
    .set-box-shadow(0 0 10px rgba(0, 0, 0, 0.3));
    .arrow {
      top: 100px;
    }
    .popover-title {
      font-size: 16px;
      text-align: center;
      background: none;
    }
    .popover-content {
      padding: 0;
      max-height: 200px;
      overflow: auto;
      border-bottom: 1px solid #cccccc;
    }
    .popover-footer {
      padding: 10px;
      text-align: center;
      .btn {
        background: none;
      }
    }
    .patient-table {
      width: 100%;
      .label {
        color: #888;
        padding: 0;
      }
      tr {
        td {
          padding: 5px 10px;
        }
        + tr {
          border-top: 1px solid #cccccc;
        }
      }
    }
  }
}

.thumbnail {
  border: none;

  & > .caption {
    padding: 0 30px;
    min-height: 150px;

    & > h3 {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }

  .footer {
    &.btn-group {
      .btn {
        &.btn-patient-chart {
          font-size: 1.4em;
        }
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

.custom-select-box {
  position: relative;
  .spinner {
    z-index: 3;
  }
  &.has-mask {
    .selected-mask {
      display: block;
    }
    select {
      opacity: 0;
      position: relative;
    }
  }
  .form-control,
  select {
    &[readonly] {
      background: #ffffff;
    }
  }
  .selected-mask {
    position: absolute;
    display: none;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .select-dropdown {
    width: 100%;
    z-index: 5;
    padding: 12px 8px;
    background: #ffffff;
    border: 1px solid #cccccc;
    color: #b9b8b8;
    .set-border-radius(5px);
    .set-box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075));
  }

  & .Select {
    padding: 6px 0;

    & > .Select-control {
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.sort-picker {
  display: inline-block;
  position: relative;
  @fSize: 14px;
  .sort-btn {
    font-size: @fSize;
    white-space: nowrap;
  }
  .title {
    padding: 10px;
    font-size: @fSize;
    margin: 0;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  .sort-options {
    font-size: @fSize;
    li {
      cursor: pointer;

      &.element {
        padding: 7px 10px;
        float: left;
        width: 50%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
  }
}

.choose-modal {
  font-size: 15px;
  .modal-body {
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
  }
  .modal-body {
    padding: 0;
    &.two-buttons {
      display: table;
      width: 100%;
      > .btn {
        display: table-cell;
        border-radius: 0;
        width: 50%;
        .set-box-shadow(inset 0 0 1px rgba(0, 0, 0, 0.5));

        &:first-child {
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-bottom-rigth-radius: 4px;
        }
      }
    }
    &.multi-buttons {
      .btn {
        & + .btn {
          border-top: 1px solid #cccccc;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        .set-border-radius(0);
      }
    }
    .btn {
      display: block;
      width: 100%;
      border: none;
      padding: 10px;
    }
  }
}

.surface-shape-wrap {
  display: flex;
  max-height: 205px;
  text-align: center;
  flex-direction: column;
  justify-content: space-around;
  border: 1px dashed transparent;

  &.questions {
    max-height: 387px;
  }

  &.active {
    border: 1px dashed #bec844;
  }

  &:not(.active):hover {
    border: 1px dashed #cccccc;
  }

  &.without-border {
    border: none;

    &:hover {
      border: none;
    }
  }

  .surface-shape {
    width: 150px;
    display: inline-block;
    background: #ffffff;

    &.questions {
      width: 320px;
      height: 320px;
    }

    path {
      fill: transparent;
      fill-opacity: 1;
      stroke: #bec844;
      stroke-width: 1;
      stroke-miterlimit: 3.9000001;
      stroke-opacity: 1;
      stroke-dasharray: none;
      &.selected {
        fill: #bec844;
      }
    }
    text {
      font-size: 36px;
      font-style: normal;
      font-weight: normal;
      line-height: 125%;
      letter-spacing: 0;
      word-spacing: 0;
      fill: #000000;
      fill-opacity: 1;
      stroke: none;

      &.selected {
        tspan {
          fill: white;
        }
      }
      tspan {
        font-size: 28px;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        font-stretch: normal;
        fill: #808080;
        -inkscape-font-specification: Sans;
      }
    }
  }
  .btn-numeric {
    width: 100px;

    display: block;
    margin: 0 auto;
    padding: 3px 10px;

    &.editable {
      background: #efefef;
      color: #000;
      border: 1px dashed #000;
    }
  }
}

.mouth-shape-wrap {
  text-align: center;
  svg {
    display: inline-block;
  }
  .rect {
    fill: transparent;
    fill-opacity: 1;
    display: flex;
    cursor: pointer;
    span {
      margin-left: 3px;
      font-size: 18px;
    }
  }
  .teeth-image {
    display: inline;
  }
  text {
    font-size: 50.50881195px;
    font-style: normal;
    font-weight: normal;
    line-height: 125%;
    letter-spacing: 0px;
    word-spacing: 0px;
    fill: #6c5d53;
    fill-opacity: 1;
    stroke: none;
    display: inline;
    tspan {
      font-size: 17.67808533px;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      font-stretch: normal;
      fill: #6c5d53;
      -inkscape-font-specification: Serif;
    }
  }
  .layer-selectors {
    fill: none;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      position: absolute;
      top: 49%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }

    .rect-selector {
      fill: none;
      stroke: #bec945;
      border: 1px solid #bec945;
      border-radius: 3px;
      margin-bottom: 1px;
      stroke-width: 0.64794195;
      stroke-opacity: 1;
      &.selected {
        fill: #bec945;
        background-color: #bec945;
      }
    }
  }
  g[class^="layer"] {
    display: inline;
  }
}

.slick-slider {
  margin-bottom: 30px;
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  &.slick-initialized .slick-slide {
    display: flex;
    height: 410px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-loading .slick-list {
    background: #fff center center no-repeat;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
  }

  .slick-track:before,
  .slick-track:after {
    content: "";
    display: table;
  }

  .slick-track:after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
  }

  [dir="rtl"] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 30px;
    width: 30px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: #bec844;
    color: transparent;
    margin-top: -12px;
    padding: 0;
    border: none;
    outline: none;
    bottom: 0;
    z-index: 9999;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: #bbc94a;
    color: red;
  }

  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: 0;
  }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: 0px;
  }

  .slick-prev:before {
    content: "←";
  }
  [dir="rtl"] .slick-prev:before {
    content: "→";
  }

  .slick-next {
    right: 0px;
  }
  [dir="rtl"] .slick-next {
    left: 0px;
    right: auto;
  }

  .slick-next:before {
    content: "→";
  }
  [dir="rtl"] .slick-next:before {
    content: "←";
  }

  .slick-dots {
    position: absolute;
    bottom: -45px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }

  .slick-dots li button {
    border: 0;
    background: transparent;
    display: block;
    height: 20px;
    width: 20px;
    outline: none;
    line-height: 0;
    font-size: 0;
    color: transparent;
    padding: 5px;
    cursor: pointer;
  }

  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }

  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }

  .slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "•";
    width: 20px;
    height: 20px;
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    text-align: center;
    color: black;
    opacity: 0.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-dots li.slick-active button:before {
    color: black;
    opacity: 0.75;
  }
}

@switchPrefixCls: rc-switch;

@duration: 0.3s;

.@{switchPrefixCls} {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 44px;
  height: 22px;
  line-height: 20px;
  vertical-align: middle;
  border-radius: 20px 20px;
  border: 1px solid #ccc;
  background-color: #ccc;
  cursor: pointer;
  transition: all @duration cubic-bezier(0.35, 0, 0.25, 1);

  &-inner {
    color: #fff;
    font-size: 12px;
    position: absolute;
    left: 24px;
  }

  &:after {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 2px;
    top: 1px;
    border-radius: 50% 50%;
    background-color: #ffffff;
    content: " ";
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    transform: scale(1);
    transition: left @duration cubic-bezier(0.35, 0, 0.25, 1);
    animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
    animation-duration: @duration;
    animation-name: rcSwitchOff;
  }

  &:hover:after {
    transform: scale(1.1);
    animation-name: rcSwitchOn;
  }

  &:focus {
    box-shadow: 0 0 0 2px tint(#2db7f5, 80%);
    outline: none;
  }

  &-checked {
    border: 1px solid #87d068;
    background-color: #87d068;

    .@{switchPrefixCls}-inner {
      left: 6px;
    }

    &:after {
      left: 22px;
    }
  }

  &-disabled {
    cursor: no-drop;
    background: #ccc;
    border-color: #ccc;

    &:after {
      background: #9e9e9e;
      animation-name: none;
      cursor: no-drop;
    }

    &:hover:after {
      transform: scale(1);
      animation-name: none;
    }
  }

  &-label {
    display: inline-block;
    line-height: 20px;
    font-size: 14px;
    padding-left: 10px;
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    user-select: text;
  }
}

@keyframes rcSwitchOn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes rcSwitchOff {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.reminders-choose-patient-modal {
  .dropdown {
    width: 100%;
    .dropdown-toggle {
      width: 100%;
      text-align: left;
      display: flex;
      padding: 6px 10px;
      > span {
        float: right;
      }
    }
    .dropdown-menu {
      width: @calendar-dropdown-width;
    }
  }

  .dropdown-doctor-text {
    position: relative;

    &.active:after {
      content: "\2713";
      position: absolute;
      right: 0;
    }
  }

  .input-group {
    &.search {
      margin-bottom: 10px;
    }
  }

  .reminders-select-all {
    margin-top: 30px;
  }

  .modal-title {
    text-align: center;
    font-weight: bold;
    margin-top: 5px;
  }

  .list-group {
    overflow-y: scroll;
    overflow-x: hidden;
    .hidden-scrollbar();
    height: 200px;
    margin-bottom: 0;
  }

  .list-group-item {
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .text {
      flex: 1;
    }
    .arrow {
      color: @brand-primary;
    }
  }
  .modal-sm {
    width: 400px;
  }
}

.reminders-choose-dentist-modal {
  .input-group {
    &.search {
      margin: 10px 0;
    }
  }

  .modal-title {
    text-align: center;
    font-weight: bold;
    margin-top: 5px;
  }

  .list-group {
    overflow-y: scroll;
    overflow-x: hidden;
    .hidden-scrollbar();
    height: 200px;
    margin-bottom: 0;
  }

  .list-group-item {
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .text {
      flex: 1;
    }
    .arrow {
      color: @brand-primary;
    }
  }
}

.reminders-modal {
  .modal-dialog {
    width: 99%;
    position: absolute;
    height: 99%;
    display: flex;
    margin: auto;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;

    .modal-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      .modal-body {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  }

  .modal-title {
    text-align: center;
    font-weight: bold;
    margin-top: 5px;
  }

  .btn-group-modal {
    text-align: left;
    float: left;
    width: auto;
  }

  .reminders-box {
    margin-top: 25px;

    .btn-select-dentist {
      width: 100%;
      text-align: left;
      color: #777;
    }

    .btn-select-dentist:hover,
    .btn-select-dentist:active,
    .btn-select-dentist:focus {
      background-color: #fff;
      box-shadow: none;
    }

    .table-reminders {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-bottom: 0;

      thead {
        width: 100%;
        display: block;

        & > tr {
          display: block;

          & > th {
            float: left;
            border-bottom-width: 0;
            display: block;
          }
        }
      }

      tbody {
        height: 230px;
        .hidden-scrollbar();
        overflow-y: auto;
        flex-direction: column;
        flex: 1;
        width: 100%;
        display: block;

        .tr-spinner {
          td {
            border-top: none;
          }
        }

        tr {
          display: flex;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          td {
            float: left;
            border-bottom-width: 0;
            overflow-x: hidden;
          }
        }
      }
    }

    label {
      text-align: left;
    }

    &.reminders-manual-mode {
      display: flex;
      flex-direction: column;
      flex: 1;

      .reminders-manual-box {
        height: 250px;
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .select-recipient-type {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        cursor: pointer;
        border-radius: 50%;
        border: 2px #bec844 solid;
        float: left;
      }

      .dentist-patient-select {
        & .col-sm-6 {
          padding-left: 0;

          & label {
            padding-right: 0;
          }
        }
        .selecting {
          cursor: pointer;
        }
      }

      .form-group-submit-rule {
        margin: 0;
        text-align: center;
        margin-bottom: 15px;

        .btn-submit-rule {
          width: 30%;
        }
      }
    }

    &.reminders-automatic-mode {
      display: flex;
      flex-direction: column;
      flex: 1;

      .reminders-automatic-box {
        height: 250px;
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .checkTitle {
        padding-bottom: 2px;
      }

      .checkedAutomaticList {
        display: inline-block;
        border: 1px #000 solid;
        border-radius: 50%;
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-right: 10px;

        &.checked {
          background-size: 60%;
          background-position: 50%;
          background-repeat: no-repeat;
          border: 1px #bec844 solid;
        }

        &.unchecked {
          background: #fff;
          border: 1px #ddd solid;
        }
      }

      .form-group-submit-rule {
        margin: 0;

        .btn-submit-rule {
          width: 100%;
        }
      }
    }
  }
}

.temporary-patient {
  position: relative;
}

.temporary-result {
  position: absolute;
  top: 0;
  left: 400px;
  width: 250px;
  height: 300px;
  border: 1px solid #aaa;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  .temporary-result-title {
    font-size: 16px;
  }

  .temporary-result-body {
    height: 200px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    .hidden-scrollbar();
    overflow-y: auto;

    .temporary-result-body-item {
      display: flex;
      padding: 0 6px;
      cursor: pointer;
      border-bottom: 1px solid #ddd;

      & > div {
        &:first-child {
          flex: 1;
        }

        display: flex;
        flex-direction: column;

        & > span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          &:first-child {
            color: #ccc;
          }
        }
      }
    }
  }

  .temporary-result-button {
    background-color: white;
    width: 100%;
    border-radius: 0;
    height: 44px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.choose-modal-save-temp {
  .modal-footer {
    padding: 0;
    display: flex;

    & > button {
      flex: 1;
      margin: 0;
      border-radius: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 10px 0;
    }
  }
}

.save-and-close {
  font-size: 16px;
  font-weight: normal;
  text-align: left;

  textarea {
    resize: none;
  }

  .form-group {
    margin: 0 6px 6px 6px;
  }
}

.temporary-form {
  font-weight: bold;
  font-size: 16px;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    margin-bottom: 12px;
    font-size: 16px;
  }

  .Select,
  .Code {
    width: 80px;
  }

  .Select-menu-outer {
    width: 250px;
    font-weight: normal;
  }

  label {
    font-size: 14px;
    color: #555;
  }
}

.custom-modal-dialog {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 10000;
  position: fixed;
  align-items: center;
  justify-content: center;

  & .modal-dialog {
    width: 400px;
  }

  & .modal-dialog-content {
    width: 400px;
    padding: 8px;
    display: flex;
    z-index: 10000000;
    border-radius: 6px;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #cccccc;

    & > .modal-dialog-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    & > .modal-dialog-actions {
      display: flex;
      margin-top: 8px;
      justify-content: flex-end;
    }
  }
}

.delete-button {
  width: 30px;
  height: 30px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.transaction-header > .typeahead-autocomplete {
  & > div {
  }
}

.transaction-select {
  .Select-menu-outer {
    width: auto !important;
  }

  .Select-control {
    display: flex;

    .Select-multi-value-wrapper {
      flex: 1;
    }

    .Select-arrow-zone {
      width: auto;
      margin-left: 6px;
      padding-top: 6px;
      align-items: center;
      display: flex !important;
    }
  }
}


.toggle-patient-clinic-filter {
  z-index: 100;
}

.flat-button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }
}

.push-notification-dialog {
  width: 700px
}

.member-image {
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: auto;
    height: auto;
  }
}

.input-lg {
  height: 46px !important;
}

.react-datepicker__week,
.react-datepicker__day-names,
.react-datepicker__current-month,
.react-datepicker__today-button,
.react-datepicker__header__dropdown--select {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker-popper {
  z-index: 100050 !important;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__current-month {
  height: 18px;
}

.react-datepicker__header__dropdown--select {
  height: 16px;
}

.react-datepicker__day-names {
  height: 27px;
}

.react-datepicker__today-button {
  height: 27px;
}
button[disabled], html input[disabled] {
  cursor: not-allowed;
}
.react-datepicker-wrapper {
  width: 100%;
}

.select-picker__menu {
  z-index: 1000 !important;
}

.dropdown-price-text.active {
  background: rgba(0, 0, 0, 0.1) !important;
}

.modal-header {
  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
.bg-gray {
  background-color: #808080;
  &:hover {
    background-color: #606060;
  }
}
