@import "classes";

@call-list-column-width: 440px;
@call-list-gray-bg: #cdcdcd;
@call-list-gray-text: rgba(0, 0, 0, 0.5);
@call-list-title-height: 30px;
@call-list-menu-height: 40px;
@call-list-menu-padding: (@call-list-menu-height - 34px)/2;

.dropdown-button-list {
  z-index: 100 !important;
}

.call-list-page {
  height: 100%;
  position: relative;
  padding-top: @call-list-menu-height;
  background-size: 100% auto;
}

.call-list-menu {
  height: @call-list-menu-height;
  background: white;
  width: 100%;
  box-shadow: 0 0 1px;
  margin-top: -@call-list-menu-height;
  padding-top: @call-list-menu-padding;

  .form-inline {
    float: right;
    padding-right: @call-list-menu-padding;
  }

  .form-group {
    margin-right: @call-list-menu-padding;
  }
}

.call-list {
  padding: 0;
  height: 100%;
  overflow: auto;
}

.call-list-title {
  background: white;
  margin-top: 0;
  background: @call-list-gray-bg;
  white-space: nowrap;
  position: sticky;
  top: 0;
  z-index: 5;
}

.call-list-title-wrap {
  white-space: nowrap;
  height: @call-list-title-height;
}

.call-list-title-item {
  width: @call-list-column-width;
  vertical-align: top;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: @call-list-title-height;
  padding: 10px;
  color: @call-list-gray-text;
  border-right: 1px solid white;
  font-size: 12px;

  .point, .doctor {
    vertical-align: top;
    display: inline-block;
  }
}

.call-list-body {
  height: 100%;
  position: relative;
}

.call-list-body-wrap {
  position: relative;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.call-list-column {
  position: relative;
  border-right: 1px solid white;
  width: @call-list-column-width;
  color: #fff;
  float: left;
  min-height: 40px;

  .cell {
    width: 100%;
    display: flex;
    border-bottom: 1px solid white;
    color: inherit;
    height: 150px;
    cursor: pointer;
    position: relative;
    transition: all 0.1s ease-in;

    &:hover {
      text-decoration: none;
      -webkit-box-shadow: 0 0 20px 15px rgba(255, 255, 255, 0.2);
      -moz-box-shadow: 0 0 20px 15px rgba(255, 255, 255, 0.2);
      box-shadow: 0 0 20px 15px rgba(255, 255, 255, 0.2);
    }

    .patient-image {
      width: 70px;
      display: inline-block;
      vertical-align: top;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: auto 34%;
    }

    .left {
      width: 300px;
      padding: 6px 12px;
      display: inline-flex;
      flex-direction: column;
      vertical-align: top;
      justify-content: space-between;
      background: #2472D2;

      .icon-buttons {
        margin: 4px 0;
        display: flex;

        & > img {
          margin-right: 6px;
        }
      }

      .top {
        position: relative;
        .name {
          width: 179px;
          padding-left: 5px;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          vertical-align: top;
        }
        .number {
          position: absolute;
          right: 0;
          top: 0;
          padding-left: 5px;
          padding-right: 5px;
          background: #2472D2;
        }
      }

      .bottom {
        padding-left: 5px;

        .phone {
          img {
            height: 20px;
          }
          margin: 4px 0;
        }
      }
    }
    .right {
      width: 140px;
      vertical-align: top;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #4FD224;

      .top {
        font-size: 12px;
      }
    }

    .cell-buttons {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 0;
      display: none;
      text-align: center;
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        background: #51ffff;
        transition: all 0.1s ease-in;

        &:hover {
          -webkit-box-shadow: 0 0 20px 15px rgba(34, 60, 80, 0.2) inset;
          -moz-box-shadow: 0 0 20px 15px rgba(34, 60, 80, 0.2) inset;
          box-shadow: 0 0 20px 15px rgba(34, 60, 80, 0.2) inset;
        }

        .text {
          font-size: 12px;
          line-height: 30px;
          white-space: nowrap;
        }

        img {
          width: 25px;
        }
      }

      .confirmed {
        background-color: rgb(4, 198, 163);
      }
      .no-response {
        background-color: rgb(254, 203, 2);
      }
      .canceled {
        background-color: rgb(255, 2, 102);
      }
      .move-appt {
        background-color: rgb(51, 153, 253);
      }
    }
  }
}

.call-list-modal {
  textarea {
    width: 100%;
  }
}
