@import "variables";
@import "classes";

@whatsapp-chatting-time-width: 124px;
@whatsapp-chatting-column-width: 200px;
@whatsapp-chatting-caption-gray-bg: #e6e6e6;
@whatsapp-chatting-gray-bg: #cdcdcd;
@whatsapp-chatting-tr-bg-sky: #eef5f7;
@whatsapp-chatting-message-bg-primary: #cdd380;
@whatsapp-chatting-message-bg-secondary: #dcdcd7;
@whatsapp-chatting-tr-bg-danger: #f9f9f9;
@whatsapp-chatting-gray-text: rgba(0, 0, 0, 0.5);
@whatsapp-chatting-title-height: 50px;
@whatsapp-chatting-header-height: 50px;
@whatsapp-chatting-dropdown-width: 250px;
@whatsapp-chatting-header-padding: (@whatsapp-chatting-header-height - 34px)/2;

@whatsapp-chatting-bg: #f9dbd9;
@whatsapp-chatting-patient-bg: #d1e7f5;

.test-simple {
  &:hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.react-datepicker__week {
  & .react-datepicker__day {
    &.react-datepicker__day--today:not(.react-datepicker__day--selected) {
      color: #ff0000;
    }
  }
}

.whatsapp-chatting-filter-form {
  padding: 12px 35px 12px 0;
  margin-right: 20px;

  .form-control:first-child {
    width: 170px;
  }

  .form-control {
    width: 115px;
  }

  .form-group .react-datepicker__input-container input {
    width: 115px;
  }

  .Select {
    display: inline-block;
    vertical-align: inherit;
    width: 170px;
  }

  select.form-control {
    width: 170px;
  }
}

.whatsapp-chatting-top-form {
  .whatsapp-chatting-doctor-dropdown-title {
    width: 165px;
  }

  .whatsapp-chatting-select-date {
    width: 100px !important;
  }

  .dropdown-toggle {
    width: 200px !important;
  }

  .no-response-zoom {
    zoom: 85%;
    vertical-align: 3px;
  }

  .whatsapp-image {
    zoom: 98%;
    vertical-align: 3px;
  }

  .react-datepicker__input-container {
    vertical-align: 3px;
  }
}

.checked-in-time {
  margin-top: -5px;
}

.checked-in-title {
  margin-bottom: 5px;
}

.whatsapp-chatting-week-buttons {
  color: #bec844;
  font-weight: bold;

  &>.btn-link {
    text-decoration: none;
  }
}

.whatsapp-chatting-page {
  height: 100%;
  position: relative;
  padding-top: @whatsapp-chatting-header-height;
  background-size: 100% auto;
}

.whatsapp-chatting-print-details-modal {
  .modal-buttons {
    width: 100%;
    text-align: center;

    button {
      width: 100px;

      &:first-child {
        margin-right: 5px;
      }
    }
  }
}

.whatsapp-chatting-print-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  embed {
    width: 100%;
    height: 100%;
  }

}

.find-open-slot-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  width: 40%;
  height: 100%;

  .modal-content {
    height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;

    .modal-body {
      padding: 0;
      flex: 1;
      margin: 0 10px;
      display: flex;
      flex-direction: column;

      & .list-group {
        overflow-y: auto;
        display: block;
        flex: 1;
        .hidden-scrollbar();
        overflow-x: hidden;
        margin-bottom: 0;
      }

      .find-open-slot-sidebar-list {
        .find-open-slot-sidebar-list-item {
          height: 60px;
          position: relative;
          border-bottom: 1px #999 solid;
          cursor: pointer;

          &>span,
          div {
            position: absolute;
          }

          .data-arrow {
            height: 50px;
            top: 5px;
            right: 15px;
            font-size: 35px;
          }

          .data-date {
            top: 5px;
            left: 5px;
            font-weight: bold;
          }

          .data-time {
            top: 5px;
            right: 40px;
            font-weight: bold;
          }

          .data-name {
            bottom: 5px;
            left: 5px;
          }

          .data-day {
            bottom: 5px;
            right: 40px;
          }
        }
      }
    }

    .open-slot-form-days {
      margin-top: 5px;

      &>div {
        float: left;
      }

      label,
      input {
        float: left;
        margin-right: 5px;
      }
    }

    .react-datepicker__input-container {
      width: 100%;

      .form-control {
        width: 100%;
      }
    }

    .control-label {
      display: block;
      margin-top: 5px;
    }

    .modal-header {
      background-color: #bec844;

      button {
        color: #fff;
      }

      .title {
        text-align: center;
        color: #fff;
        line-height: 34px;
        text-transform: uppercase;
        font-size: 16px;
      }
    }

    .find-appointments-clinic-filter {
      width: 100%;
      margin-top: 8px;
    }

    .find-appointments-cell {
      color: #fff;
      width: 100%;
      display: flex;
      border-bottom: 1px solid white;
      overflow: hidden;
      cursor: pointer;
      margin: 6px 0;

      &:hover {
        text-decoration: none;
      }

      .left {
        flex: 1;
        display: inline-flex;
        flex-direction: column;
        vertical-align: top;
        justify-content: space-between;
        background: #2472D2;
        position: relative;

        .top {
          position: relative;

          .name {
            width: 179px;
            padding-left: 5px;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            vertical-align: top;
          }

          .number {
            position: absolute;
            right: 0;
            top: 0;
            padding-left: 5px;
            padding-right: 5px;
            background: #2472D2;
          }
        }

        .bottom {
          padding-left: 5px;
        }
      }

      .right {
        width: 120px;
        vertical-align: top;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #4FD224;

        .top {
          font-size: 12px;
        }
      }
    }

    .cell {
      color: #fff;
      width: 100%;
      display: flex;
      border-bottom: 1px solid white;
      overflow: hidden;
      cursor: pointer;
      margin-top: 10px;

      &:hover {
        text-decoration: none;
      }

      .left {
        flex: 1;
        display: inline-flex;
        flex-direction: column;
        vertical-align: top;
        justify-content: space-between;
        background: #2472D2;
        position: relative;

        .top {
          position: relative;

          .name {
            width: 179px;
            padding-left: 5px;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            vertical-align: top;
          }

          .number {
            position: absolute;
            right: 0;
            top: 0;
            padding-left: 5px;
            padding-right: 5px;
            background: #2472D2;
          }
        }

        .bottom {
          padding-left: 5px;
        }
      }

      .right {
        width: 120px;
        vertical-align: top;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #4FD224;

        .top {
          font-size: 12px;
        }
      }
    }
  }
}

.whatsapp-chatting-header {
  height: @whatsapp-chatting-header-height;
  background: white;
  width: 100%;
  box-shadow: 0 0 1px;
  line-height: 18px !important;
  text-align: left !important;
  height: 60px !important;
  padding: 10px 0;

  .btn.btn-back.btn-lg {
    height: 60px;
  }

  .form-inline {
    float: right;
    padding-right: @whatsapp-chatting-header-padding;
    height: @whatsapp-chatting-header-height;
  }

  .form-group {
    margin-right: @whatsapp-chatting-header-padding;
  }

  .dropdown {
    .dropdown-toggle {
      width: @whatsapp-chatting-dropdown-width;
      text-align: left;

      >span {
        float: right;
      }
    }

    .dropdown-menu {
      width: @whatsapp-chatting-dropdown-width;
    }
  }

  .dropdown-doctor-text {
    position: relative;

    &.active:after {
      content: '\2713';
      position: absolute;
      right: 0;
    }
  }
}

.whatsapp-chatting-users {
  height: 100%;
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .whatsapp-chatting-tab-buttons {
    width: 95%;
    margin: 1% 2%;
  }

  .whatsupp-message-text {
    padding-left: 10px;
  }

  .whatsapp-chatting-users-table {
    width: 100%;

    tr {
      border-bottom: 2px solid white;
    }

    tr:nth-child(odd) {
      background-color: @whatsapp-chatting-tr-bg-sky;
    }

    tr:nth-child(even) {
      background-color: @whatsapp-chatting-tr-bg-danger;
    }

    tr.chatting-table-caption {
      background-color: @whatsapp-chatting-caption-gray-bg;
    }

    tbody {
      .t-data-patient {
        padding: 5px 10px;
      }

      .whatsapp-icon-vector {
        cursor: pointer;
      }

      .buttons {
        display: flex;
        align-items: center;
        gap: 25px;
        height: 60px;
      }
    }

    .chatting-patient-profile {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .chatting-patient-name {
      margin-bottom: 0;

    }

    .chatting-table-caption {
      background-color: @whatsapp-chatting-caption-gray-bg;

      th:first-child {
        text-align: left;
        padding-left: 76px;
      }

      th:nth-child(5) {
        text-align: left;
        padding-left: 10px;
      }

      th:nth-child(6) {
        text-align: center;
      }

      th {
        padding: 6px;
      }

      th:nth-child(6) {
        width: 30%;
      }

      th:last-child {
        width: 5%;
      }
    }
  }
}

.whatsapp-chatting.html2canvas {
  height: auto;
  width: fit-content;

  .whatsapp-chatting-time,
  .whatsapp-chatting-title {
    overflow: visible;
  }

  .whatsapp-chatting-body {
    overflow: visible;
  }

  .whatsapp-chatting-title-item {
    background: #cdcdcd;
  }

  .whatsapp-chatting-time-item {
    background: #cdcdcd;
  }

  .whatsapp-chatting-body-start-bg,
  .whatsapp-chatting-body-patient-bg,
  .whatsapp-chatting-body-end-bg {
    opacity: 1;
  }
}

.whatsapp-chatting-title {
  height: 50px;
  background: white;
  margin-top: -@whatsapp-chatting-title-height;
  background: @whatsapp-chatting-gray-bg;
  overflow: hidden;
}

.whatsapp-chatting-title-wrap {
  white-space: nowrap;
  height: 50px;
}

.whatsapp-chatting-title-item {
  &.comfort {
    height: 50px;
    width: 400px;
  }

  &.compact {
    width: 200px;
  }

  vertical-align: top;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: @whatsapp-chatting-title-height;
  padding: 10px;
  color: @whatsapp-chatting-gray-text;
  border-right: 1px solid white;
  font-size: 12px;

  .point,
  .doctor {
    vertical-align: top;
    display: inline-block;
  }
}

.whatsapp-chatting-time {
  width: @whatsapp-chatting-time-width;
  margin-left: -@whatsapp-chatting-time-width;
  float: left;
  background: @whatsapp-chatting-gray-bg;
  color: @whatsapp-chatting-gray-text;
  text-align: center;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.whatsapp-chatting-time-item {

  &.comfort {
    line-height: 38px;
    height: 40px;
  }

  &.compact {
    line-height: 18px;
    height: 20px;
  }

  border-bottom: 1px solid white;
  border-top: 1px solid transparent;
}

.whatsapp-chatting-body {
  height: 100%;
  position: relative;
  overflow: scroll;
}

.whatsapp-chatting-body-wrap {
  position: relative;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.whatsapp-chatting-body-start-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: @whatsapp-chatting-bg;
  opacity: 0.9;
}

.whatsapp-chatting-body-patient-bg {
  position: absolute;
  left: 0;
  right: 0;
  background: @whatsapp-chatting-patient-bg;
  opacity: 0.9;
}

.whatsapp-chatting-body-end-bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: @whatsapp-chatting-bg;
  opacity: 0.9;
}

.whatsapp-chatting-body-line-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.whatsapp-chatting-column {
  &.comfort {
    width: 400px;
  }

  &.compact {
    width: 200px;
  }

  position: relative;
  border-right: 1px solid white;
  color: #fff;
  float: left;
  min-height: 40px;
  height: 100%;

  .cell {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    border-bottom: 1px solid white;
    color: inherit;
    overflow: hidden;
    cursor: pointer;

    &.cell-single-doctor {
      &:hover {
        z-index: 100;
      }
    }

    &.small-cell {
      font-size: 12px;
      line-height: 13px;

      .left {
        justify-content: center;

        .number {
          display: none;
        }

        .bottom {
          display: none;
        }
      }

      .right {
        .top {
          display: none;
        }
      }
    }

    &:hover {
      text-decoration: none;
    }

    .left {
      flex: 1;
      display: inline-flex;
      flex-direction: column;
      vertical-align: top;
      justify-content: space-between;
      background: #2472D2;
      position: relative;

      &>.note-modal-link {
        text-decoration: underline;
        position: absolute;
        bottom: 0;
        right: 5px;
      }

      &>.note-modal-link:hover {
        font-weight: bold;
      }

      .top {
        position: relative;
        flex-shrink: 0;
        min-height: 20px;

        .name {
          padding-left: 5px;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          vertical-align: top;
        }

        .number {
          &.comfort {
            right: 0;
          }

          position: absolute;
          top: 0;
          padding-left: 5px;
          padding-right: 5px;
          background: #2472D2;
        }
      }

      .center {
        overflow: hidden;
        padding: 0 5px;
        font-style: italic;
      }

      .bottom {
        padding-left: 5px;
        flex-shrink: 0;
      }
    }

    .right {
      width: 120px;
      vertical-align: top;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #4FD224;

      .top {
        font-size: 12px;
        white-space: nowrap;

        &.name {
          align-self: flex-start;
        }
      }
    }
  }

  .time-slot {
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
    background: #cdcdcd;
    padding: 5px;

    &.reserved {
      background: #ddd;
      cursor: auto;
      color: #333;
    }
  }
}

.whatsapp-chatting-doctor-dropdown-title {
  width: @whatsapp-chatting-dropdown-width - 35px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-toggle .whatsapp-chatting-doctor-dropdown-title {
  float: left;
}

.whatsapp-chatting-modal {
  width: 27% !important;
  min-width: 380px !important;
  max-width: 420px !important;
  box-shadow: none !important;

  .whatsapp-chatting-input-wrapper {
    padding: 16px;
    position: relative;
  }

  .whatsapp-chatting-message-date {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
  }

  .whatsapp-chatting-sender-icon {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .whatsapp-chatting-input {
    border: 1px solid #9F9F9F;
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 14px 40px 14px 14px;
    width: 100%;
  }

  .whatsapp-chatting-message-wrapper {
    display: flex;
  }

  .whatsapp-chatting-content {
    padding: 16px;
    overflow: auto;
    height: 81vh;
  }

  .whatsapp-chatting-message {
    text-align: right;
    width: 80%;
  }

  .whatsapp-chatting-message-content {
    background-color: @whatsapp-chatting-message-bg-primary;
    text-align: left;
    padding: 16px 19px;
    border-radius: 7px;

    & p {
      margin: 0;
    }
  }

  .whatsapp-chatting-side-header {
    background: @brand-primary;
    padding-top: 20px;
    padding-bottom: 10px;
    flex-shrink: 0;
    height: fit-content;
    width: 100%;
  }

  .whatsapp-chatting-message-types {
    width: 100%;
  }

  .whatsapp-note-title {
    color: white;

    .whatsapp-note-title-caption {
      margin-bottom: 7px;
      font-size: 16px;
      text-transform: uppercase;
      font-family: Helvetica, Arial, sans-serif;
    }

    .whatsapp-note-title-chart {
      min-width: 150px;
      font-size: 14px;
      font-family: Helvetica, Arial, sans-serif;
    }
  }

  .btn-primary,
  .btn-default {
    border-color: #fff;
  }

  .btn-link {
    color: #fff;
  }

  .btn-default {
    color: @brand-primary;
    background: #fff;
  }

  .chart-side-header-type {
    margin-top: 15px;
    display: flex;

    button {
      flex: 1;
      font-size: 17px;
      line-height: 1.1;
    }
  }
}

.search-form-wrapper {
  & .search-form {
    width: 80% !important;
    min-width: 600px;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
    margin-left: auto;
    padding: 2px 10px;
    display: flex;

    & .form-group {
      margin-bottom: 0;
    }

    & .flex-datepicker {
      display: flex;
      gap: 8px;
      align-items: center;

      & label {
        margin-bottom: 0;
        width: fit-content;
      }
    }
  }
}

.whatsapp-chatting-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;

  .whatsapp-chatting-table {
    flex: 1 1 0;
    overflow: auto;
    position: relative;
    width: 100%;
  }
}
