.patient-consent-form-edit-file-picker {
  display: flex;

  label {
    flex: 1;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
  }
}