.member-login-page {
  position: relative;
  min-height: 100%;
  background-size: cover;
  display: flex;

  .login-members-filter {
    width: 354px;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    &:focus {
      opacity: 1;
    }
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  .left-side,
  .right-side {
    flex: 45%;
    margin-right: 5%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .left-side {
    background: rgba(255, 255, 255, 0.75);
    box-shadow: 5px 0 10px -5px rgba(0, 0, 0, 0.5);

    .row {
      width: 100%;

      .member-log-out {
        width: 100%;
      }
    }

    .clinic-name {
      font-weight: bold;
      font-size: 18px;
      color: #2b2b2b;
      text-align: center;
      margin: 20px 0;
    }
  }

  .member-carousel {
    display: flex;
    align-items: stretch;
    justify-content: center;
    .member-carousel-prev,
    .member-carousel-next {
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        zoom: 2;
      }
    }
    .member-carousel-screen {
      width: 354px;
      height: 384px;
      overflow: hidden;
      .member-carousel-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-start;
        height: 384px;
        transition: 0.5s all;
      }
    }
  }

  .member-item {
    padding: 10px 5px;
    img {
      .img();
    }
  }

  .img() {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .right-side form {
    width: 470px;
    height: 474px;
    padding: 90px 60px 60px;
    .forgot {
      margin: 20px 0;
    }
  }

  .selected-member {
    display: flex;
    margin-bottom: 40px;
    height: 100px;
    .info {
      flex: 1;
      color: #7d7d7d;
      margin-right: 10px;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .name {
        font-weight: bold;
        font-size: 16px;
      }
      .surname {
        font-size: 16px;
        margin: 5px 0;
      }
    }
    .image {
      img {
        .img();
      }
    }
  }

  .member-image {
    width: 107px;
    height: 107px;
    cursor: pointer;
  }
}

.member-image {
  border-radius: 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
}
