.dropdown-button-wrapper {
  color: black;
  width: 200px;
  height: 34px;
  position: relative;

  & > .dropdown-button {
    width: 100%;

    & > .title {
      overflow: hidden;
      white-space: nowrap;
    }
  }

  & > .dropdown-button-list {
    z-index: 1;
    color: black;
    display: flex;
    padding: 8px 0;
    margin: 4px 0 0;
    min-width: 100%;
    overflow-y: auto;
    max-height: 250px;
    position: absolute;
    flex-direction: column;
    background-color: white;
    border: 1px solid #eeeeee;

    .hidden-scrollbar();

    & > .dropdown-button-list-item {
      height: 34px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      white-space: nowrap;
      padding: 8px 38px 8px 20px;

      &.active:after {
        right: 15px;
        content: '\2713';
        position: absolute;
      }

      &:not(.empty-item):hover {
        background-color: #eeeeee;
      }

      &:not(:last-child).divider {
        border-bottom: 1px solid #cccccc
      }
    }
  }
}
