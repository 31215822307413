.settings-page {
  display: flex;
  height: 100%;
}

.settings-bar {
  flex: 0 0 400px;

  .list-group {
    text-transform: uppercase;
    .list-group-item {
      border-width: 0;
      border-radius: 0;
      border-bottom: 1px solid #ddd;
      margin-bottom: 0;
    }
  }
}

.settings-password-dialog {
  user-select: none;

  .settinds-password-dialog-error {
    text-align: center;
    display: block;
    margin-top: 10px;
    color: #ff0000;
  }

  .modal-dialog {
    width: 300px;
  }

  .modal-body {
    padding: 10px 15px;
  }

  .modal-title {
    text-align: center;
    padding-top: 10px;
  }

  .modal-footer {
    padding: 0;
    height: 40px;

    & > button {
      border-radius: 0;
      width: 50%;
      height: 100%;

      &:first-child {
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-bottom-right-radius: 4px;
        margin: 0;
      }
    }
  }
}

.settings-bar-title {
  border-bottom: 1px solid #ddd;

  .name {
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    line-height: 34px;
    color: #bec844;
    user-select: none;
  }
  .btn {
    position: absolute;
  }
}

.settings-body {
  flex: 1;
  border-left: 1px solid #ddd;

  .title {
    border-bottom: 1px solid #ddd;

    .name {
      text-transform: uppercase;
      text-align: center;
      font-size: 16px;
      line-height: 34px;
      color: #bec844;
    }
    .btn {
      position: absolute;
    }
  }

  .body {
    padding: 10px;
    height: 100%;
    overflow: auto;

    .list-group {
      text-transform: uppercase;

      & > span {
        cursor: pointer;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }

  .well {
    text-align: center;
  }

  .color {
    float: right;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .check {
    color: transparent;
    &.active {
      color: #bec844;
    }
  }

  .btn-color-right {
    right: 0;
  }

  .chrome-picker {
    width: 600px;
  }
}
