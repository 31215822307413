@dashboard-patient-bg: #197373;
@dashboard-appointment-bg: #a24773;
@dashboard-news-bg: #009dd9;
@import "classes";

.dashboard-page {
  height: 100%;
  overflow: auto;
  position: relative;
  padding: 70px 20px 15px;
  background-color: #ffffff;

  .page-title {
    position: absolute;
    top: 0;
    font-size: 20px;
    line-height: 70px;
    left: 20px;
  }

  .boxes {
    height: 100%;
    max-height: 710px;
    min-height: 710px;
    display: flex;
    align-items: stretch;
    color: #fff;

    .left-group {
      height: 100%;
      display: flex;
      width: 66.37%;
      float: left;
      margin-right: 0.91%;
      text-transform: uppercase;
      flex-wrap: wrap;

      .box {
        margin: 0 1%;
        width: 48%;
        height: 49%;
        .top {
          height: 50%;
          display: flex;
          .left, .right {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 25px;
            text-align: center;
            .link-default();

          }
          .left {
            background: rgba(255, 255, 255, 0.2);
          }
          .right {
            background: rgba(0, 0, 0, 0.2);
          }
          .text {
            font-size: 15px;
          }
          .number {
            font-size: 30px;
          }
          .click {
            font-size: 10px;
          }
        }
        .bottom {
          height: 50%;
          padding: 15px;

        }
      }

      a.box {
        display: block;
        .link-default();
      }

      .patients {
        background: @dashboard-patient-bg;
      }

      .appointments {
        background: @dashboard-appointment-bg;
      }

      .report {
        background: @dashboard-news-bg;
      }
    }
    .news {
      width: 32.72%;
      float: left;
      height: 100%;
      background: @dashboard-news-bg;
      padding: 15px;
      display: flex;
      flex-direction: column;

      .head {
        img {
          width: 100%;
        }
      }

      .body {
        flex: 1;
        margin: 15px 0;
        flex-direction: column;
        overflow-y: scroll;

        .hidden-scrollbar();

        .item {
          display: block;
          padding: 10px 0;
          .link-default();

        }
      }

      .bottom {
        text-transform: uppercase;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .title {
      font-size: 15px;
    }
  }
}

