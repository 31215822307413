.calendar-bottom-menu {
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  z-index: 30000000;
  transition: bottom 270ms ease-in-out;

  .calendar-bottom-menu-opener {
    cursor: pointer;
    position: absolute;
    left: 50%;
    margin-left: -67px;
    top: -25px;

    img {
      vertical-align: bottom;
    }
  }
}

#calendar-bottom-menu {
  position: relative;

  .print-menu {
    right: -40px;
    left: auto;
  }
}

.page-bottom-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  .menu-opener {
    cursor: pointer;
    position: absolute;
    left: 50%;
    margin-left: -67px;
    bottom: 0;

    img {
      vertical-align: bottom;
    }
  }

  .menu-container {
    display: none;
    position: relative;
  }
}
