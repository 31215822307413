.typeahead-autocomplete {
  position: relative;
}

.typeahead-autocomplete-loader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
}

.typeahead-autocomplete-list {
  z-index: 1;
  display: flex;
  margin: 0;
  padding: 0;
  //margin-top: 6px;
  overflow-y: auto;
  max-width: 300px;
  max-height: 250px;
  position: absolute;
  flex-direction: column;
  background-color: white !important;
}

.typeahead-autocomplete-button {
  flex-shrink: 0;
  color: black !important;
  border: none;
  padding: 6px;
  outline: none;
  text-align: left;
  overflow: hidden;
  background: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #ccc;
  text-decoration: none !important;

  & > a {
    border: none !important;
  }

  &:hover {
    text-decoration: none !important;
  }

  &:focus {
    outline: none;
  }
}
