.documents-assigned-table {
  .arrow {
    width: 30px;
    margin: 0 auto;
    text-align: center;
  }
}

.documents-assigned-inside-modal {
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .modal-content {
    height: 100%;
    border-radius: 0;
  }
}