@import "classes";

.docs-view {
  background: #000;
  color: #fff;

  .btn-link {
    color: #fff;
  }
}

.docs-list {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  .hidden-scrollbar();
}

.docs-item {
  flex: 0 0 100px;
  height: 100px;
  background-position: 50% 50%;
  background-size: cover;
  text-align: right;
  margin-right: 5px;
  margin-bottom: 5px;
}

.docs-description {
  text-align: center !important;
  margin-top: 45px;
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.btn-docs-delete {
  padding: 1.25px 12px;
}

.docs-body {
  display: flex;
  flex-direction: column;
  flex: 1;

  .image {
    flex: 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-align: right;
  }
  .text {
    text-align: center;
    line-height: 30px;
  }

  .navigation {
    text-align: center;
    padding-right: 46px;

    .arrow-left {
      margin-right: 150px;
    }
  }
}