@import "classes";
@import "variables";

.chart-general {
  .grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.5%;

    .col {
      & table {
        color: #000000;
        background-color: #ffffff;
      }
      display: flex;
      flex-direction: column;
      color: #fff;
      padding: 10px;
      margin: 0 0.5% 10px;
      height: 190px;
      cursor: pointer;
      position: relative;

      .title {
        line-height: 25px;
        font-size: 15px;
        text-transform: uppercase;
      }

      .body {
        flex: 1;
        line-height: 30px;
        font-size: 16px;
        overflow: auto;
        .hidden-scrollbar();

      }
      .footer {
        display: flex;
        justify-content: space-between;
        .text {
          text-transform: uppercase;
          line-height: 25px;
          font-size: 15px;
        }
        .button {
          flex: 1;
        }
      }
    }

    .clinical-note {
      background: #5981d3;
      flex: 0 0 32.3%;
    }
    .reports {
      background: #f7a527;
      flex: 0 0 32.3%;
    }
    .goals {
      background: #308181;
      flex: 0 0 32.3%;
    }
    .sticky {
      background: #30aa82;
      flex: 0 0 24%;
    }
    .fill-in-forms {
      background: #ab5881;
      flex: 0 0 24%;
    }

    .fill-in-form-item {
      display: flex;
      justify-content: space-between;

      .date {
        flex: 0 0 35%;
      }

      .name {
        white-space: nowrap;
        flex: 0 0 65%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: right;
      }
    }

  }
}

.chart-general-goal-item {
  padding-left: 20px;

  .goal-checkbox {
    float: left;
    margin-left: -20px;

    &.goal-plus-sidebar {
      cursor: pointer;
      margin-left: -12px;
    }

    &.goal-sidebar {
      margin-top: -4px;
      margin-left: -12px;
      margin-right: 12px;
    }
  }

  .text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .white-circle {
    width: 16px;
    height: 16px;
    margin-top: 7px;
    border-radius: 50%;
    background: white;
  }

  .green-circle {
    width: 16px;
    height: 16px;
    margin-top: 7px;
    border-radius: 50%;
    background: @brand-primary;
  }

}

.chart-general-add {
  margin: 0 10px;
}

.chart-clinical-note {

  .chart-general-goal-item {
    border-bottom: 1px solid #ddd;
    padding: 5px 25px;

    .delete {
      float: right;
      margin-right: -20px;

      &.goal-sidebar {
        margin-right: -12px;
      }

      img {
        width: 20px;
      }
    }

    .text {

      input {
        width: 100%;
        border: none;
      }
    }
  }


}
