.flex-box-component {
  position: relative;
  z-index: 10;

  &.root {
    display: flex;
    box-sizing: border-box;
  }

  &.withoutHeight {
    height: 0;
  }

  &.gutter-8 {
    margin: -4px;
    & > .root {
      padding: 4px;
    }
  }
  &.gutter-16 {
    margin: -8px;
    & > .root {
      padding: 8px;
    }
  }
  &.gutter-24 {
    margin: -12px;
    & > .root {
      padding: 12px;
    }
  }
  &.gutter-40 {
    margin: -20px;
    & > .root {
      padding: 20px;
    }
  }

  &.container-8 {
    padding: 4px;
  }
  &.container-16 {
    padding: 8px;
  }
  &.container-24 {
    padding: 12px;
  }
  &.container-40 {
    padding: 20px;
  }

  &.direction-row {
    flex-direction: row;
    min-width: 0;
  }
  &.direction-row-reverse {
    flex-direction: row-reverse;
    min-width: 0;
  }
  &.direction-column {
    flex-direction: column;
    min-height: 0;
  }
  &.direction-column-reverse {
    flex-direction: column-reverse;
    min-height: 0;
  }

  &.wrap-nowrap {
    flex-wrap: nowrap;
  }
  &.wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  &.align-center {
    align-items: center;
  }
  &.align-flex-end {
    align-items: flex-end;
  }
  &.align-stretch {
    align-items: stretch;
  }

  &.justify-center {
    justify-content: center;
  }
  &.justify-flex-end {
    justify-content: flex-end;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
  &.justify-space-around {
    justify-content: space-around;
  }
}
