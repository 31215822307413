@import "variables";

.referral-next-visit-wrap {
  height: 100%;
  overflow: hidden;
  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: @brand-primary;
  }
  .referral-next-visit-body {
    height: 100%;
    overflow: auto;
  }
  .referral-next-visit-form {
    margin-bottom: 1.5rem;
  }
}
.referral-next-visit-form-field {
  .form-group {
    position: relative;
  }
  .picker-delete-btn {
    position: absolute;
    width: unset;
    right: 0;
    top: 0;
    z-index: 99;
  }
  .react-datepicker__input-container {
    width: 100%;
    input {
      width: 100% !important;
    }
  }
}
.referral-appointments-cell {
  color: #000000;
  width: 100%;
  display: flex;
  border-bottom: 1px solid white;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  .left {
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    justify-content: space-between;
    background: #2472D2;
    position: relative;

    .top {
      position: relative;
      padding: 0 5px;

      .name {
        padding-left: 5px;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        vertical-align: top;
      }

      .number {
        position: absolute;
        right: 0;
        top: 0;
        padding-left: 5px;
        padding-right: 5px;
        background: #2472D2;
      }
    }

    .bottom {
      padding: 0 5px;
    }
  }
  .right {
    width: 120px;
    vertical-align: top;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #4FD224;
    .top {
      font-size: 12px;
    }
  }
}
.chart-side-referral-list {
  display: flex;
  width: 100%;
  div {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid rgb(204, 204, 204);
    flex: 1;
    &:not(:last-child) {
      border-right: none;
    }
  }
  //&:nth-child(1) {
  //  width: ;
  //}
}
.chart-side-referral-list-body {
  display: flex;
  span {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid rgb(204, 204, 204);
    background-color: #cccccc6e;
    flex: 1;
    &:not(:last-child) {
      border-right: none;
    }
  }
}
