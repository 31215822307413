.time-picker {
  position: relative;

  &.full-size {
    width: 100%;
  }

  & > input {
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    display: block;

    &:focus {
      border-color: #66afe9;
      outline: 0;
    }
  }

  .time-picker-main-form {
    position: absolute;
    user-select: none;
    box-shadow: 0 0 0 1px rgba(14,41,57,.12),0 2px 5px rgba(14,41,57,.44),inset 0 -1px 2px rgba(14,41,57,.15);
    background: #fefefe;
    margin: auto;
    left: 0;
    right: 0;
    border-radius: .1px;
    width: 270px;
    height: 130px;
    font-size: 14px;
    z-index: 999;
    top: 37px;

    .main-form-header {
      background: #f2f2f2;
      position: relative;
      margin: 0 auto;
      border-bottom: 1px solid #e5e5e5;
      padding: 12px 11px 10px 15px;
      color: #4C4C4C;
      font-size: inherit;
      text-align: center;
    }
    .time-block-group {
      width: 85px;
      margin: auto;

      .time-block {
        width: 28px;
        float: left;

        .time-arrow-up {
          background-size: 70%;
          width: 28px;
          height: 28px;
          cursor: pointer;
        }

        .time-arrow-down {
          background-size: 70%;
          width: 28px;
          height: 28px;
          cursor: pointer;
        }

        .display-time {
          text-align: center;
        }
      }
    }
  }
}
.time-picker-dialog {
  z-index: 10070 !important;
}
.css-nnbavb {
  display: none;
}
.time-picker-control {
  fieldset {
    top: 0 !important;
  }
  input {
    padding: 7px !important;
  }
}
