@import "classes";

.change-to-cash-wrapper {
  display: flex;
  justify-content: center;
}

.price-list-select-wrapper {
  flex: 1;
  margin: -8px 0;

  .Select-control {
    display: flex;
    background-color: transparent !important;
    border: none;

    &:hover {
      box-shadow: none;
    }

    .Select-multi-value-wrapper {
      flex: 1;

      .Select-placeholder {
        color: #000000;
      }
    }

    .Select-arrow-zone {
      display: flex !important;
      align-items: center;
      justify-content: center;

      .Select-arrow {
        border-color: #fff transparent transparent;
      }
    }
  }
}

.transaction-date-cell {
  display: flex;
  position: relative;

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cell-title {
    flex: 1;
  }

  .cell-button {
    position: relative;
    .react-datepicker-wrapper {
      width: unset;
    }

    .react-datepicker__input-container {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      position: absolute;

      .cell-input {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

    .cell-icon {
      pointer-events: none;
    }
  }
}

.transaction-clinic-filter {
  text-align: left;

  .Select-control {
    width: 200px;
  }

  .Select-menu-outer {
    //top: auto;
    //bottom: 100%;
  }
}

.chart-transaction-view-header {
  .buttons {
    display: flex;
    justify-content: flex-end;
    line-height: 30px;

    .edit,
    .delete {
      cursor: pointer;
    }

    .edit + .delete {
      margin-left: 25px;
    }
  }
}

.chart-transaction-view-body {
  overflow-y: scroll;
  .hidden-scrollbar();

  .posted {
    padding: 5px;
    border-bottom: 1px solid #ddd;
  }

  .completed-date {
    padding: 5px;
    border-bottom: 1px solid #ddd;

    .pull-right {
      margin-top: 5px;
    }

    .react-datepicker__input-container {
      input {
        display: none;
      }
    }

    label {
      cursor: pointer;
    }
  }

  .description {
    padding: 5px;
    border-bottom: 1px solid #ddd;
    max-height: 90px;
    overflow: hidden;
  }
  .fee {
    padding: 5px;
    border-bottom: 1px solid #ddd;
  }

  .area {
    padding: 5px;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
  }

  .diagnosis {
    padding: 5px;
    border-bottom: 1px solid #ddd;
    max-height: 70px;
    overflow: hidden;
  }

  .note {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;

    .note-title {
      font-weight: bold;
      margin-right: 40px;
    }

    .note-body {
      flex: 1;
      textarea {
        vertical-align: top;
        width: 100%;
      }
    }
  }

  .actions {
    display: flex;
    flex-wrap: wrap;

    .action {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      flex: 0 0 50%;

      .image {
        margin-right: 10px;
        flex-shrink: 0;
      }
      .name {
        text-transform: uppercase;
        font-size: 18px;
      }
    }
    .action-big {
      flex: 0 0 100%;
    }
  }
}

.chart-approve-table {
  flex: 1;

  .col-1 {
    flex: 0 0 100px;
  }
  .col-2 {
    flex: 0 0 120px;
  }
  .col-3 {
    flex: 0 0 60px;
  }
  .col-4 {
    flex: 1;
  }
  .col-5 {
    flex: 0 0 80px;
  }
  .col-6 {
    flex: 0 0 80px;
  }
  .col-7 {
    flex: 0 0 130px;
  }
  .col-8 {
    flex: 0 0 50px;
    cursor: pointer;
    text-align: center;
  }
}

.chart-plan-table {
  flex: 1;

  .col-1 {
    flex: 0 0 70px;
  }
  .col-2 {
    flex: 0 0 70px;
  }
  .col-3 {
    flex: 0 0 50px;
  }
  .col-4 {
    flex: 0 0 60px;
  }
  .col-5 {
    flex: 1;
  }
  .col-6 {
    flex: 0 0 50px;
  }
  .col-7 {
    flex: 0 0 80px;
  }
  .col-8 {
    flex: 0 0 70px;
  }
  .col-9 {
    flex: 0 0 100px;
  }
  .col-10 {
    flex: 0 0 40px;
    text-align: center;
  }

  .chart-plan-row-plan {
    border-top: 1px solid green;
  }

  .chart-plan-row-session {
    border-top: 1px solid black;
  }

  .chart-plan-row-red {
    background: #f9bfc2;
  }
}
