@import "classes";
@import "variables";

.patient_role_date {
  background-color: #F2F2F2 !important;
}

.patient_details-role_date {
  .form-group {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.perio-tab {
  background: #bec844;
  padding: 20px 5px 10px;
  flex-shrink: 0;

  .btn-primary,
  .btn-default {
    border-color: #fff;
  }

  .btn-default,
  select {
    color: @brand-primary;
    background: #fff;
  }
}

.patient-terminal-root {
  min-width: 1280px !important;
  min-height: 780px !important;

  height: 100%;

  .header {
    margin-top: 0;

    img {
      height: 40px;
      margin: 0 auto;
    }
  }

  .left-column {
    width: 300px;

    .patient-card {
      background-color: #2f8081;

      .member-image {
        width: 100%;
        height: 60%;
      }
    }

    .card-complaint {
      background-color: #d11b1c;
    }

    .card-forms {
      background: #a64b72;
    }

    .card-feedback {
      background-color: #ffa319;
    }

    .button-container {
      height: 33.3%;

      .card-button {
        cursor: pointer;
        user-select: none;
        position: relative;
        background-color: rgba(255, 255, 255, .1);

        &:hover {
          background-color: rgba(255, 255, 255, .3);
        }

        .button-count {
          top: 8px;
          right: 8px;
          width: 40px;
          height: 40px;
          color: #ffffff;
          font-size: 18px;
          position: absolute;
          border-radius: 50%;
          background-color: #ff0000;
        }

        .button-icon {
          width: 100%;
          height: 100%;
          background-size: 14%;
          background-repeat: no-repeat;
          background-position: center bottom;
        }

        .button-title {
          color: #ffffff;
          font-size: 18px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
  }

  .card-details {
    background-color: #5680d3;
  }

  .card-patient-alerts {
    background-color: #30aa83;
  }

  .card-appointment {
    background-color: #a64b72;

    .appointment-title {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .appointment-item {
      font-weight: bold;
      padding-left: 12px;
    }
  }

  .card-medication-alerts {
    background-color: #2f8081;
  }

  .card-view-button-container {
    padding: 12px !important;

    .btn {
      margin: 12px;
      display: flex;
      justify-content: center;

      &.btn-danger {
        border-color: #ff0000;
        background-color: #ff0000;

        &:hover {
          border-color: #d43f3a;
          background-color: #c9302c;
        }
      }

      &.text-upper {
        text-transform: uppercase;
      }
    }
  }

  .card-content-container {
    color: #ffffff;
    padding: 12px !important;

    .card-title {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .card-content {
      font-size: 16px;
      overflow-y: scroll;
      overflow-x: hidden;
      .hidden-scrollbar();

      & > div {
        flex: none;
      }

      .details-name {
        font-size: 18px;
        font-weight: bold;
      }

      .details-value {
        font-weight: bold;
        padding-left: 12px;
      }
    }
  }
}

.patient-mobile {
  display: flex;
  flex-direction: column;
  background-size: 100% auto;
  height: 100%;
}

.patient-mobile-body {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patient-mobile-body-form {
  width: 470px;
  height: 368px;
  padding: 23px 22px 34px 21px;
  color: #777;

  .title {
    text-align: center;
    border-bottom: 1px solid #ddd;
    font-size: 20px;
    font-weight: bold;
    line-height: 50px;
    margin-bottom: 25px;
  }

  .patient-choose-account-title {
    padding: 10px;
    margin-top: -25px;
    font-size: 16px;
    border-bottom: 1px solid #ddd;
  }

  .choose-list-title {
    padding: 0 10px;
    background: #eee;
    font-weight: bold;
    color: #333;
    line-height: 25px;
  }

  .choose-list {
    overflow-y: scroll;
    overflow-x: hidden;
    .hidden-scrollbar();
    height: 130px;
  }

  .patient-choose-account-item {
    padding: 10px;
    font-size: 16px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;

    &:hover {
      background: #eee;
    }
  }

  .form-group {
    margin-bottom: 25px;

    .react-datepicker__input-container {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }

  .col-sm-padding;
  .input-number-arrows-none;
}

.patient-profile {
  height: 100%;
  display: flex;
  flex-direction: column;

}

.patient-contacts{
  height: 100%;
  display: flex;
  flex-direction: column;

}

.patient-contacts-body {
  overflow-y: scroll;
  overflow-x: hidden;
  .hidden-scrollbar();
  background: #b56b90;
  flex: 1;
  color: white;

  .head-title {
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .content-box {
    display: flex;
    justify-content: center;
  }

  .content-item {
    min-width: 275px;

    &.content-right {
      padding-left: 175px;
      justify-content: flex-start;
    }

    .title {
      margin-bottom: 20px;
    }

    .body {
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  .content-end {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.patient-profile-body {
  overflow-y: scroll;
  overflow-x: hidden;
  .hidden-scrollbar();
  background: #48b491;
  flex: 1;
  color: #333;
  font-size: 20px;

  .head-title {
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .content-box {
    display: flex;
    justify-content: center;
  }

  .content-item {
    min-width: 500px;
    .item {
      display: flex;
      justify-content: space-between;
    }

    .title {
      margin-bottom: 20px;
      margin-right: 1rem;
      flex: 1;
    }

    .body {
      font-weight: bold;
      margin-bottom: 20px;
      flex: 1;
    }
  }

  .content-end {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.patient-alerts-history-button {
  position: absolute;
  bottom: 30px;
  right: 30px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px dashed transparent;
}

.alerts-history-title {
  display: inline-block;
  margin-left: -70px;
  color: #777;
}

.patient-alerts-view-history {
  display: flex;
  flex-direction: column;
  height: 100%;

  .patient-alerts-view-history-body {
    display: flex;
    flex-direction: row;
    flex: 1;

    .patient-alerts-view-history-left-side {
      flex: 0 0 200px;
      overflow-x: hidden;
      overflow-y: scroll;
      .hidden-scrollbar();

      .patient-alerts-button-history:hover {
        background-color: #ccc;
      }

      .patient-alerts-button-history {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #ccc;
        text-align: right;
        color: #8c8c8c;
        margin: 0 5px;
        padding: 0 5px;
        position: relative;
        user-select: none;
        cursor: pointer;

        &.selected {
          background-color: #ccc;
        }

        & > span {
          color: #000;
          position: absolute;
          left: 5px;
        }
      }
    }

    .patient-alerts-view-history-right-side {
      flex: 1;
      display: flex;
      position: relative;

      & > span {
        z-index: -99999999;
        position: absolute;
        width: 100%;
        height: 100%;

        & > div {
          z-index: -99999999 !important;
        }
      }

      & > embed {
        flex: 1;
      }
    }
  }
}

.patient-alerts-history-button:hover {
  border-bottom: 1px dashed;
}

.patient-medication-alerts-edit {
  background-size: 100% auto;

  .patient-medication-alerts-please-sign {
    text-align: center;
    padding: 0 15px;
  }

  .medical-bottom {
    padding-bottom: 15px;
    position: relative;
  }

  .patient-medication-alerts-edit-box {
    border-bottom: 1px solid #fff;
    margin-bottom: 15px;
    padding: 15px 0;

    .patient-medication-alerts-table {
      width: 90%;
      margin: 0 auto;

      tr {
        height: 50px;
        text-align: center;

        td {
          padding: 0 18px;

          &:nth-child(2) {
            padding: 0 5px;
          }


          input.input-field {
            color: #333;
            font-family: Helvetica, Arial, sans-serif;
            font-size: 12px;
            line-height: 28px;
            padding-left: 10px;
            border-radius: 3px;
            border: 1px solid #ddd;
            max-width: 100%;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .medication-delete {
          height: 14px;
          width: 100%;
          background-position: 50%;
          background-repeat: no-repeat;
          cursor: pointer;
        }

        .medication-add-another {
          padding: 15px 0 0 70%;
        }
      }

      tr:not(.medication-table-caption) {
        height: 40px;
      }

      .medication-table-caption {
        text-align: left;
        font-weight: bold;
      }

      .date-field {
        width: 158px;
        text-align: center;
      }

      .medication-field {
        width: auto;
      }

      .dosage-field {
        width: 10%;
      }

      .unit-field {
        width: 15%;
        text-align: center;
      }

      .purpose-field {
        width: auto;
      }

      .delete-field {
        width: 5%;
        text-align: center;
      }
    }
  }

}

.patient-medical-alerts-edit {
  background-size: 100% auto;

  .patient-medical-alerts-please-sign {
    text-align: center;
    padding-top: 10px;
  }

  .patient-medical-alerts-sign {
    border-bottom: 1px #fff solid;
    padding-bottom: 15px;
  }

  .medical-bottom {
    padding: 15px 0;
    position: relative;
  }

  .patient-medical-alerts-edit-box {
    padding: 10px 0;
    border-bottom: 1px solid #fff;
    font-size: 12px;
    .rc-collapse-item:not(:last-child) {
      .rc-collapse-header {
        border-bottom: 1px solid white;
      }
    }
    .rc-collapse-content {
      overflow: unset !important;
      background: unset;
      color: white;
    }
    .alerts-row {
      clear: both;
      min-height: 35px;
      width: 70%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .medical-alerts-box {
        height: 100%;
        float: left;
        white-space: nowrap;
        overflow-x: hidden;

        &.box-label {
          //width: 40%;
          text-align: left;
        }

        &.box-switch {
          width: 200px;
          text-align: center;
        }

        &.box-description {
          width: 35%;

          input {
            width: 100%;
            height: 24px;
            color: #000;
          }
        }
      }
    }
  }
}

.switch-informer {
  width: 44px;
  height: 22px;
  margin: 0 auto;
  line-height: 22px;
  text-align: center;
  border-radius: 20px;

  color: #bec844;
  background-color: #fff;

  &.informer-yes {
    color: #fff;
    background-color: #bec844;
  }

  &.informer-undefined {
    width: 70px;
  }
}

.patient-details-edit {
  background-size: 100% auto;
}

.patient-details-edit-body {
  background: fadeout(#009dd9, 25);
  color: #fff;

  .main-info {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: space-between;
    line-height: 25px;
    padding: 5px;
    margin-top: 30px;

  }

  .line-title {
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;

    .text {
      font-size: 20px;
      padding: 0 10px;
    }
  }
}

.line-title {
  text-align: center;
  overflow: hidden;

  .text {
    position: relative;

    &:before, &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 1px;
      background: #fff;

    }

    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }
  }
}

.patient-details-edit-photo {
  background: rgba(255, 255, 255, 0.8);
  width: 190px;
  height: 190px;
  margin: 0 auto;

  .member-image {
    width: 190px;
    height: 190px;
    border-radius: 0;
  }
}

.patient-details-edit-box {
  padding: 16px 24px;

  .readElement {
    display: block;
    height: 20px;
  }

  .item {
    width: 300px;
    margin: 0 10px;
  }

  .input-number-arrows-none;
  .col-sm-padding;
  .form-group {
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 5px;
  }

  .control-label {
    padding: 0;
  }

  .form-group .react-datepicker__input-container input {
    width: 100%;
  }

  input[type="radio"] {
    cursor: pointer;
    appearance: none;
    background-color: #ffffff00;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 25px;
    height: 25px;
    border: 2px solid currentColor;
    border-radius: 50%;
    display: grid;
    place-content: center;
  }
  input[type="radio"]::before {
    content: "";
    width: 17px;
    height: 17px;
    border-radius: 50%;
    transform: scale(0);
    transition: 200ms transform ease-in-out;
    box-shadow: inset 1em 1em #ffffff;
    background-color: #ffffff;
  }
  input[type="radio"]:checked::before {
    transform: scale(1);
  }
  input[type="radio"]:focus {
    outline: 2px solid #ffffff;
    outline-offset: 2px;
  }
  input[type="checkbox"] {
    appearance: none;
    background-color: rgba(0,0,0,0);
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 25px;
    height: 25px;
    border: 2px solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }
  input[type="checkbox"]::before {
    content: "";
    width: 17px;
    height: 17px;
    transform: scale(0);
    transition: 200ms transform ease-in-out;
    background-color: #ffffff;
  }
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  input[type="checkbox"]:focus {
    outline: 2px solid #ffffff;
    outline-offset: 2px;
  }
}

.patient-details-edit-btn {
  border-color: transparent;
  color: #fff;
  background: #ff8000;
  &:hover, &:focus, &:active {
    color: #fff;
  }
}

.patient-details-edit-sign-text {
  border-top: 1px solid #fff;
  margin-top: 20px;
  padding: 20px 10px 0;
}

.patient-details-edit-signature {
  height: 100px;
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px 20px;
  position: relative;
  img {
    margin: 0 auto;
    height: 100px;
  }
}

.patient-details-household {
  .modal-header {
    border-bottom-width: 0;
  }

  .search {
    padding: 10px;
    background: #bbb;

    .input-group-addon {
      background: white;
      padding: 3px 6px;
    }
    .form-control {
      border-left: 0;
      height: 28px;
      padding: 3px 12px;
    }
  }

  .member-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .list-group {
    overflow-y: scroll;
    overflow-x: hidden;
    .hidden-scrollbar();
    height: 200px;
    margin-bottom: 0;
  }

  .list-group-item {
    padding: 5px;
    display: flex;
    align-items: center;
    .text {
      flex: 1;
    }
    .arrow {
      color: @brand-primary;
    }
  }
}

.signed-text {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
}
svg.patient-register-info-btn {
  width: 34px;
  opacity: 0.7;
  cursor: pointer;
  fill: #ff0000;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
  }
}
.patient-info-sidebar-wrapper {
  width: 450px !important;
}
.patient-info-appointment-history-sidebar {
  .modal-dialog {
    width: 90%;
  }
}
