.chart-post-op-form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;

  .post-ops-textarea {
    flex: 1 1;
    resize: none;
    margin-top: 10px;
  }
}

.chart-post-op-name {
  margin: 10px 0;
}

textarea.form-control.chart-post-op-content {
  height: 300px;
}

.chart-post-op-buttons {
  text-align: right;
  margin-top: 10px;

  button + button {
    margin-left: 10px;
  }
}
